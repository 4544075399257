import ErrorPage from '../Pages/ErrorPage';

// Pages
import Home from '../Pages/Home';


export const routesList = [
    {
        path: "/",
        element: <Home />,
        breadcrumb: "Dashboard",
        protected: true,
        metaData: {
            title: "Website Accessibility Checker - Scan Accessibility Compliance",
            description: "Free website accessibility checker to quickly scan your website and ensure an inclusive digital experience for all visitors. For a quick check, enter the URL."
        }
    }, {
        path: "/404",
        element: <ErrorPage />,
        breadcrumb: "404 Error",
        protected: false,
        metaData: {
            title: "404 Error | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }
]