import MetaDataProivder from '../../Components/MetaDataProvider';
import MainTemplate from '../../Template/MainTemplate';
import { Alert, Button, Card, Col, Container, Form, Modal, ProgressBar, Row } from 'react-bootstrap';

import heroIcn from '../../Assets/img/hero-ico.svg';
//import pdfIcon01 from '../../Assets/img/pdf-placeholder-1.svg';
import pdfIcon01 from '../../Assets/img/with-logo.svg';
//import pdfIcon02 from '../../Assets/img/pdf-placeholder-2.svg';
import pdfIcon02 from '../../Assets/img/without-logo.svg';
import pdfReportSuccess from '../../Assets/img/report-success.svg';
import noDomain from '../../Assets/img/no-domian.svg';
import ScanURLFormComponents from '../../Components/ScanURLForm';
import WebsiteScreen from '../../Components/WebsiteScreen';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreProptypes } from '../../PropTypes/StoreProptypes';
import { ScanReportService, ScanningProcessService, getCurrentWindowService, quoteFormService } from '../../Services/ScanningProcessService';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import WebsiteURLSection from '../../Components/ScanDetails/WebsiteURLSecrion';
import ResultsOverviewSection from '../../Components/ScanDetails/ResultsOverview';
import AccessibilityViolationsList from '../../Components/ScanDetails/AccessibilityViolationsList';
import RecommendedNextStepsSection from '../../Components/ScanDetails/RecommendedNextSteps';
import { groupDataMainPropTypes, requestDataProptype } from '../../PropTypes/ScanningScreenPropType';
import CTSBoxComponent from '../../Components/CTSBox';
import PageHeaderMain from '../../Components/Header';
import ViolationsGroupListPlaceholder from '../../Components/Placeholder/ViolationsGroupList';
import ReactGA from "react-ga4";

import planIcon01 from '../../Assets/img/plan/small-site-icon.svg';
import planIcon02 from '../../Assets/img/plan/medium-site-icon.svg';
import planIcon03 from '../../Assets/img/plan/large-site-icon.svg';
import planIcon04 from '../../Assets/img/plan/extra-large-site-icon.svg';
import UnderMaintenanceTemplate from '../../Template/UnderMaintenanceTemplate';

const scannerPlan = process.env.REACT_APP_ADD_PLAN;
const scannerMaintenance = process.env.REACT_APP_UNDER_MAINTENANCE;

interface tempGropScorePropType {
  [keyname: string]: number[];
}
interface finalGropTotalScorePropType {
  [keyname: string]: number;
}




const Home = () => {

  ReactGA.initialize("G-85C9EMDGMJ", {
    gaOptions: {
      debug_mode: true,
    },
    gtagOptions: {
      debug_mode: true,
    },
  });

  const { scaningProcess, scaningProcessPer, websiteURL, reScaningProcess, isLoading } = useSelector((store: StoreProptypes) => store);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const websiteURLParam = searchParams.get("website");

  useEffect(() => {
    if (websiteURLParam) {
      ScanningProcessService((websiteURLParam.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')), true, dispatch, setViolationsGroupList, setErrorViolationsTotal, setSuccessViolationsTotal, setFixedViolationsTotal, setAccessibilitySystemDetected, setRequestDataObj, setScreenError, setCriticalIssues, setPassedAudits, setRequiredManualAudits, setNotApplicable, setApiRecall, apiRecall ? apiRecall : false);
      //console.log("API Call websiteURLParam ==>", websiteURLParam)
    }
  }, [websiteURLParam]);

  // Scan Result
  const [loading, setLoading] = useState(false);
  const [apiRecall, setApiRecall] = useState(false);
  const [violationsResult, setViolationsResult] = useState<boolean>(false);

  const [violationsGroupList, setViolationsGroupList] = useState<groupDataMainPropTypes>();
  const [filteredviolationsGroupList, setFilteredViolationsGroupList] = useState<groupDataMainPropTypes>();
  const [requestDataObj, setRequestDataObj] = useState<requestDataProptype>();

  const [errorViolationsTotal, setErrorViolationsTotal] = useState<number>(0);
  const [successViolationsTotal, setSuccessViolationsTotal] = useState<number>(0);
  const [fixedViolationsTotal, setFixedViolationsTotal] = useState<number>(0);

  const [accessibilitySystemDetected, setAccessibilitySystemDetected] = useState<string>('');

  const [screenError, setScreenError] = useState<boolean>(false);

  const [criticalIssues, setCriticalIssues] = useState<number>(0);
  const [passedAudits, setPassedAudits] = useState<number>(0);
  const [requiredManualAudits, setRequiredManualAudits] = useState<number>(0);
  const [notApplicable, setNotApplicable] = useState<number>(0);

  const [filterType, setFilterType] = useState<number>(0);

  useEffect(() => {
    if (violationsGroupList) {
      setFilteredViolationsGroupList(violationsGroupList);
      setFilterType(0);
    }
  }, [violationsGroupList])


  const demoFunction = () => {
    if (scaningProcess && websiteURL && scaningProcessPer !== 100) {
      ScanningProcessService(websiteURL, false, dispatch, setViolationsGroupList, setErrorViolationsTotal, setSuccessViolationsTotal, setFixedViolationsTotal, setAccessibilitySystemDetected, setRequestDataObj, setScreenError, setCriticalIssues, setPassedAudits, setRequiredManualAudits, setNotApplicable, setApiRecall, apiRecall);
    }
  }

  useEffect(() => {
    if (reScaningProcess && !scaningProcess) {
      setApiRecall(false);
    }
  }, [reScaningProcess, scaningProcess])

  useEffect(() => {
    if (apiRecall && scaningProcess && websiteURL !== null && scaningProcessPer !== 100) {
      setApiRecall(false);
      setTimeout(demoFunction, 2000);
    }
  }, [apiRecall])

  // PDF Download
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [showReportButton, setShowReportButton] = useState(false);
  const [showCTCButton, setShowCTCButton] = useState(false);

  const [showPDFReportForm, setShowPDFReportForm] = useState(false);
  const [scannerUserFullName, setScannerUserFullName] = useState('');
  const [scannerUserEmail, setScannerUserEmail] = useState('');
  const [customizeReport, setCustomizeReport] = useState(0);
  const [reportLogoURL, setReportLogoURL] = useState('');
  const [brandColor, setBrandColor] = useState('#420083');
  const [scannerUserFormError, setScannerUserFormError] = useState(false);
  const [successResponse, setSuccessResponse] = useState(false);
  const [scannerUserFormErrorMsg, setScannerUserFormErrorMsg] = useState('');

  const handleShowPDFReportFormPopup = () => {
    setShowPDFReportForm(true);
  }

  const handleHidePDFReportFormPopup = () => {
    setShowPDFReportForm(false);
    setSuccessResponse(false);
    setScannerUserFullName('');
    setScannerUserEmail('');
    setCustomizeReport(0);
    setReportLogoURL('');
    setBrandColor(brandColor ? brandColor : `#420083`);
    setScannerUserFormError(false);
    setScannerUserFormErrorMsg('');
  }

  const handleCustomizeReport = (value: boolean) => {
    if (value) {
      setCustomizeReport(1);
    } else {
      setCustomizeReport(0);
    }
  }


  // On Scroll Show Button 
  document.addEventListener("scroll", () => {
    const CTCElement = document.querySelector(".cts-box-list");
    let elementTop = CTCElement?.getBoundingClientRect();

    if (elementTop && (elementTop.top === 0 || elementTop.top < 0)) {
      setShowCTCButton(true);
    } else {
      setShowCTCButton(false);
    }

    const websiteScreenElement = document.querySelector(".website-screen-details-wrap .download-pdf-button");
    let websiteScreenElementTop = websiteScreenElement?.getBoundingClientRect();

    if (websiteScreenElementTop && (websiteScreenElementTop.top === 0 || websiteScreenElementTop.top < 0)) {
      setShowReportButton(true);
    } else {
      setShowReportButton(false);
    }
  });


  // Accessibility Score

  const [accessibilityScore, setAccessibilityScore] = useState<number>(0);
  const [groupAccessibilityScore, setGroupAccessibilityScore] = useState<tempGropScorePropType>();
  const [finalGroupAccessibilityScore, setFinalGroupAccessibilityScore] = useState<finalGropTotalScorePropType>();

  useEffect(() => {
    if (violationsGroupList) {
      let keyObjScore: tempGropScorePropType = {};
      const tempObj = Object.keys(violationsGroupList).map((keyname, gIndex) => {
        violationsGroupList[keyname].map((violation, index) => {
          let tempScore;
          if (violation.total_error > 0 || violation.total_success > 0) {
            if (violation.total_success > 0) {
              tempScore = parseInt(((violation.total_success / (violation.total_success + violation.total_error)) * 100).toFixed(2));
            } else {
              tempScore = 0;
            }
            const tempObjValue = keyObjScore[keyname] ? { [keyname]: [...keyObjScore[keyname], tempScore] } : { [keyname]: [tempScore] }
            keyObjScore = { ...keyObjScore, ...tempObjValue }
          }
        })
      })
      setGroupAccessibilityScore(keyObjScore);
    }
  }, [violationsGroupList])


  // Grop Score
  useEffect(() => {
    if (groupAccessibilityScore) {
      let tempObj: finalGropTotalScorePropType = {}
      Object.keys(groupAccessibilityScore).map((keyname, gIndex) => {
        const sum = groupAccessibilityScore[keyname].reduce((accumulator, currentValue) => {
          return accumulator + currentValue
        }, 0);
        const persentage = sum / groupAccessibilityScore[keyname].length;
        tempObj = { ...tempObj, [keyname]: persentage }
      })
      setFinalGroupAccessibilityScore(tempObj);
    }
  }, [groupAccessibilityScore])


  useEffect(() => {
    if (finalGroupAccessibilityScore) {
      let sum = 0;
      Object.keys(finalGroupAccessibilityScore).map((keyname, gIndex) => {
        sum = sum + finalGroupAccessibilityScore[keyname];
      })
      const persentage = sum / Object.keys(finalGroupAccessibilityScore).length;
      setAccessibilityScore(persentage);
    }
  }, [finalGroupAccessibilityScore]);

  const URLValidationEvent = (e: any) => {
    const inputValue = e.target.value;
    let httpRegex = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;

    if (inputValue === '') {
      setScannerUserFormError(true);
      setScannerUserFormErrorMsg("Please enter a valid logo image URL.");
      setReportLogoURL('');
    } else if (!(httpRegex.test(inputValue))) {
      setScannerUserFormError(true);
      setScannerUserFormErrorMsg("Please enter a valid logo image URL.");
      setReportLogoURL('');
    } else {
      setScannerUserFormError(false);
      setScannerUserFormErrorMsg('')
      setReportLogoURL(inputValue);
    }
  }

  const handleScanReport = () => {
    if (scannerUserFullName === '') {
      setScannerUserFormError(true);
      setScannerUserFormErrorMsg('Please add full name.');
    } else if (scannerUserEmail === '') {
      setScannerUserFormError(true);
      setScannerUserFormErrorMsg('Please enter your email address')
    } else if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(scannerUserEmail) === false) {
      setScannerUserFormError(true);
      setScannerUserFormErrorMsg('Please enter valid email address.');
    } else {
      setScannerUserFormError(false);
      setScannerUserFormErrorMsg('')
      if (requestDataObj) {
        ScanReportService(requestDataObj.id, accessibilityScore, scannerUserFullName, scannerUserEmail, customizeReport, reportLogoURL, brandColor, setLoadingPDF, setSuccessResponse, setCustomizeReport, setReportLogoURL, setBrandColor);
      }
    }
  }

  const [quoteUserFullName, setQuoteUserFullName] = useState('');
  const [quoteProjectType, setQuoteProjectType] = useState('1');
  const [quoteUserEmail, setQuoteUserEmail] = useState('');
  const [quoteUserMsg, setQuoteUserMsg] = useState('');
  const [quoteLoading, setQuoteLoading] = useState(false);
  const [quoteFormSuccess, setQuoteFormSuccess] = useState(false);
  const [quoteFormError, setQuoteFormError] = useState(false);
  const [quoteFormErrorMsg, setQuoteFormErrorMsg] = useState('');

  // Multiple Links
  interface htmlInputProptype {
    id: string,
    value: string
  }

  const [inputs, setInputs] = useState<htmlInputProptype[]>([]);
  const [inputValueArray, setInputValueArray] = useState<string[]>([]);

  useEffect(() => {
    //console.log("useEffect Call ==>", quoteProjectType, (quoteProjectType === '1'), websiteURL !== null, websiteURL === '')
    if (quoteProjectType === '1' && websiteURL !== null && websiteURL !== '') {
      setInputs([{ id: '1', value: websiteURL.split('/')[0] }])
    } else {
      setInputs([{ id: '1', value: '' }])
    }
  }, [quoteProjectType])

  const addInput = () => {
    const newInput = {
      id: Math.random().toString(36).substring(7),
      value: "",
    };

    setInputs([...inputs, newInput]);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setInputs(
      inputs.map((input) => {
        if (input.id === id) {
          return { ...input, value };
        }

        return input;
      })
    );
  };

  useEffect(() => {
    setInputValueArray([]);
    inputs.map((inputValue) => { setInputValueArray([...inputValueArray, inputValue.value]) })
  }, [inputs]);


  // Multiple Links
  interface htmlFileInputProptype {
    id: string,
    file: any
  }

  const [fileInputs, setFileInputs] = useState<htmlFileInputProptype[]>([]);

  const addFileInput = () => {
    const newFileInput = {
      id: Math.random().toString(36).substring(7),
      file: "",
    };

    setFileInputs([...fileInputs, newFileInput]);
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    //console.log("e ==>", e.target.files);

    const id = Math.random().toString(36).substring(7);
    const file = e.target.files;

    file && setFileInputs([...fileInputs, { id: id, file: file }]);
  };

  const handleFileInputRemove = (id: string) => {
    //console.log("Remove item id ==>", id);

    const tempItem = fileInputs.filter((filterFileInput) => filterFileInput.id !== id);
    //console.log("Remove item ==>", tempItem);
    setFileInputs(tempItem);
  }

  const quoteFormSubmit = () => {
    if (quoteUserFullName === '') {
      setQuoteFormError(true);
      setQuoteFormErrorMsg('Please add full name.');
    } else if (quoteUserEmail === '') {
      setQuoteFormError(true);
      setQuoteFormErrorMsg('Please enter your email address.');
    } else if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(quoteUserEmail) === false) {
      setQuoteFormError(true);
      setQuoteFormErrorMsg('Please enter valid email address.');
    } else if (quoteUserMsg === '') {
      setQuoteFormError(true);
      setQuoteFormErrorMsg('Please requirement details.');
    } else if (inputValueArray.length > 0 || fileInputs.length > 0) {
      setQuoteFormError(true);
      setQuoteFormErrorMsg('Please add the requirement links or files.');
    } else {
      quoteFormService(quoteUserFullName, quoteUserEmail, quoteUserMsg, 1, 1, quoteProjectType, inputValueArray.join(), fileInputs, setQuoteLoading, setQuoteFormSuccess);
    }
  }

  const [showManualAuditForm, setShowManualAuditForm] = useState(false);
  const handleShowManualAuditFormPopup = () => {
    setShowManualAuditForm(true);
    setInputs([{ id: '1', value: websiteURL ? websiteURL.split('/')[0] : '' }]);
  }
  const handleHideManualAuditFormPopup = () => {
    setShowManualAuditForm(false);
    setFileInputs([]);
    setInputs([{ id: '1', value: websiteURL ? websiteURL.split('/')[0] : '' }]);
    setQuoteFormSuccess(false);
    setQuoteUserFullName('');
    setQuoteProjectType('1');
    setQuoteUserEmail('');
    setQuoteUserMsg('')
  }


  // Filter


  const violationsGroupListFilter = (catType: number) => {
    setFilterType(catType);

    let tempObj: groupDataMainPropTypes = {};

    if (violationsGroupList) {
      Object.keys(violationsGroupList).map((keyName) => {
        const tempFilterValue = violationsGroupList[keyName].filter((item) => {
          let flag = true;

          switch (catType) {

            case 1:
              flag = item.is_critical_issues === 1 ? true : false;
              break;

            case 2:
              flag = item.is_passed_audits === 1 ? true : false;
              break;

            case 3:
              flag = item.is_required_manual_audits === 1 ? true : false;
              break;

            case 4:
              flag = item.is_not_applicable === 1 ? true : false;
              break;

            default:

          }

          return flag;

        })

        tempObj = { ...tempObj, [keyName]: tempFilterValue };

        //console.info("tempFilterValue ==>", tempFilterValue);
      })
    }
    //console.log("tempObj", tempObj);
    setFilteredViolationsGroupList(tempObj);
  }


  const [websiteURLPeram, setWebsiteURLPeram] = useState<string>('');

  useEffect(() => {
    if (websiteURL !== null) {
      setWebsiteURLPeram(`https://ada.skynettechnologies.us/trial-subscription?scanner=true&website=${websiteURL.split('/')[0]}`)
    }
  }, [websiteURL]);


  const [myIP, setMyIp] = useState("");
  useEffect(() => {
    //getCurrentWindowService(setMyIp);
  }, [])




  //console.info("scannerPlan ==>", scannerPlan);

  return (
    <>
      {scannerMaintenance === "true" ? (
        <>
          <UnderMaintenanceTemplate>
            Under
          </UnderMaintenanceTemplate>
        </>
      ) : (
        <>

          

              <MainTemplate>

                <MetaDataProivder />

                <header className="sticky-top">
                  <PageHeaderMain
                    setViolationsGroupList={setViolationsGroupList}
                    setErrorViolationsTotal={setErrorViolationsTotal}
                    setSuccessViolationsTotal={setSuccessViolationsTotal}
                    setFixedViolationsTotal={setFixedViolationsTotal}
                    setAccessibilitySystemDetected={setAccessibilitySystemDetected}
                    accessibilitySystemDetected={accessibilitySystemDetected}
                    setApiRecall={setApiRecall}
                    apiRecall={apiRecall}
                    setRequestDataObj={setRequestDataObj}
                    setScreenError={setScreenError}
                    showCTCButton={showCTCButton}
                    showReportButton={showReportButton}
                    handleShowPDFReportFormPopup={handleShowPDFReportFormPopup}
                    setCriticalIssues={setCriticalIssues}
                    setPassedAudits={setPassedAudits}
                    setRequiredManualAudits={setRequiredManualAudits}
                    setNotApplicable={setNotApplicable}
                    accessibilityScore={accessibilityScore}
                    handleShowManualAuditFormPopup={handleShowManualAuditFormPopup}

                  />
                </header>

                <div className="page-content">

                  {screenError ? (
                    <>
                      <div className="page-content-inner">
                        <div className='home-page-main'>
                          <Card>
                            <Card.Body>
                              <Container>
                                <div className='home-page-inner'>
                                  <div className='init-screen-text'>
                                    <div className='init-screen-icon mx-auto mb-4'>
                                      <img src={noDomain} alt='' />
                                    </div>
                                    <div className='init-screen-text text-center'>
                                      {requestDataObj && requestDataObj !== null && requestDataObj.time_out > 14 ? (
                                        <>
                                          <h1>Website Connection Timed Out!</h1>
                                        </>
                                      ) : (
                                        <>
                                          <h1>Website Connection Error!</h1>
                                        </>
                                      )}
                                      <p className='mb-0'>We couldn't evaluate the website you entered. This may happen if your server blocks our request or the page is trying to redirect. Please ensure your website can be accessed by web crawlers!</p>
                                    </div>
                                  </div>
                                  <ScanURLFormComponents
                                    setViolationsGroupList={setViolationsGroupList}
                                    setErrorViolationsTotal={setErrorViolationsTotal}
                                    setSuccessViolationsTotal={setSuccessViolationsTotal}
                                    setFixedViolationsTotal={setFixedViolationsTotal}
                                    setAccessibilitySystemDetected={setAccessibilitySystemDetected}
                                    setApiRecall={setApiRecall}
                                    apiRecall={apiRecall}
                                    setRequestDataObj={setRequestDataObj}
                                    setScreenError={setScreenError}
                                    setCriticalIssues={setCriticalIssues}
                                    setPassedAudits={setPassedAudits}
                                    setRequiredManualAudits={setRequiredManualAudits}
                                    setNotApplicable={setNotApplicable}
                                  />
                                </div>
                              </Container>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {!scaningProcess && !reScaningProcess ? (
                        <>
                          <div className="page-content-inner">
                            <div className='home-page-main'>
                              <Card>
                                <Card.Body>
                                  <Container>

                                    <div className={`home-page-inner ${scannerPlan === 'true' ? `plan-top border` : ``}`}>
                                      <div className='init-screen-text'>
                                        <div className='init-screen-icon mx-auto mb-4'>
                                          <img src={heroIcn} alt='' />
                                        </div>
                                        <div className='init-screen-text text-center'>
                                          <h1>Find out if your Website is <span>Accessible</span> and <span>Compliant</span></h1>
                                          <p className='mb-0'>Our free ADA and WCAG compliance checker identifies <br />web accessibility issues</p>
                                        </div>
                                      </div>
                                      <ScanURLFormComponents
                                        setViolationsGroupList={setViolationsGroupList}
                                        setErrorViolationsTotal={setErrorViolationsTotal}
                                        setSuccessViolationsTotal={setSuccessViolationsTotal}
                                        setFixedViolationsTotal={setFixedViolationsTotal}
                                        setAccessibilitySystemDetected={setAccessibilitySystemDetected}
                                        setApiRecall={setApiRecall}
                                        apiRecall={apiRecall}
                                        setRequestDataObj={setRequestDataObj}
                                        setScreenError={setScreenError}
                                        setCriticalIssues={setCriticalIssues}
                                        setPassedAudits={setPassedAudits}
                                        setRequiredManualAudits={setRequiredManualAudits}
                                        setNotApplicable={setNotApplicable}
                                      />
                                    </div>

                                  </Container>
                                </Card.Body>

                                {scannerPlan === 'true' ? (
                                  <>
                                    <div className='plan-list'>
                                      <div className='text-center section-title'>
                                        <h2 className='fs-1'>Accessibility Scanning and Monitoring Plan</h2>
                                      </div>
                                      <Row lg={6} className='g-0'>

                                        <Col>
                                          <div className='plan-box border'>
                                            <div className='box-image-wrapper border-bottom'>
                                              <div className='box-title'>
                                                <span className='w-100'>1 Pages</span>
                                              </div>
                                            </div>

                                            <div className='plan-details'>
                                              <div className='box-price'>FREE</div>
                                              <div className='box-button'>
                                                <Button>Select Plan</Button>
                                              </div>
                                            </div>
                                            <div className='box-text'>
                                              <ul>
                                                <li><span>Title</span> : Option 01</li>
                                                <li>Option 02</li>
                                                <li>Option 03</li>
                                              </ul>
                                            </div>
                                          </div>
                                        </Col>

                                        <Col>
                                          <div className='plan-box border'>
                                            <div className='box-image-wrapper border-bottom'>
                                              <div className='box-title'>
                                                <span className='w-100'>100 Pages</span>
                                              </div>
                                            </div>

                                            <div className='plan-details'>
                                              <div className='box-price'>$899<span>/year</span></div>
                                              <div className='box-button'>
                                                <Button>Select Plan</Button>
                                              </div>
                                            </div>
                                            <div className='box-text'>
                                              <ul>
                                                <li><span>Title</span> : Option 01</li>
                                                <li>Option 02</li>
                                                <li>Option 03</li>
                                              </ul>
                                            </div>
                                          </div>
                                        </Col>

                                        <Col>
                                          <div className='plan-box border'>
                                            <div className='box-image-wrapper border-bottom'>
                                              <div className='box-title'>
                                                <span className='w-100'>250 Pages</span>
                                              </div>
                                            </div>

                                            <div className='plan-details'>
                                              <div className='box-price'>$2199<span>/year</span></div>
                                              <div className='box-button'>
                                                <Button>Select Plan</Button>
                                              </div>
                                            </div>
                                            <div className='box-text'>
                                              <ul>
                                                <li><span>Title</span> : Option 01</li>
                                                <li>Option 02</li>
                                                <li>Option 03</li>
                                              </ul>
                                            </div>
                                          </div>
                                        </Col>

                                        <Col>
                                          <div className='plan-box border'>
                                            <div className='box-image-wrapper border-bottom'>
                                              <div className='box-title'>
                                                <span className='w-100'>500 Pages</span>
                                              </div>
                                            </div>

                                            <div className='plan-details'>
                                              <div className='box-price'>$4299<span>/year</span></div>
                                              <div className='box-button'>
                                                <Button>Select Plan</Button>
                                              </div>
                                            </div>
                                            <div className='box-text'>
                                              <ul>
                                                <li><span>Title</span> : Option 01</li>
                                                <li>Option 02</li>
                                                <li>Option 03</li>
                                              </ul>
                                            </div>
                                          </div>
                                        </Col>

                                        <Col>
                                          <div className='plan-box border'>
                                            <div className='box-image-wrapper border-bottom'>
                                              <div className='box-title'>
                                                <span className='w-100'>1500 Pages</span>
                                              </div>
                                            </div>

                                            <div className='plan-details'>
                                              <div className='box-price'>$12299<span>/year</span></div>
                                              <div className='box-button'>
                                                <Button>Select Plan</Button>
                                              </div>
                                            </div>
                                            <div className='box-text'>
                                              <ul>
                                                <li><span>Title</span> : Option 01</li>
                                                <li>Option 02</li>
                                                <li>Option 03</li>
                                              </ul>
                                            </div>
                                          </div>
                                        </Col>

                                        <Col>
                                          <div className='plan-box border'>
                                            <div className='box-image-wrapper border-bottom'>
                                              <div className='box-title'>
                                                <span className='w-100'>Enterprice</span>
                                              </div>
                                            </div>

                                            <div className='plan-details'>
                                              <div className='box-price'>&nbsp;</div>
                                              <div className='box-button'>
                                                <Button>Contact Us</Button>
                                              </div>
                                            </div>
                                            <div className='box-text'>
                                              <ul>
                                                <li><span>Title</span> : Option 01</li>
                                                <li>Option 02</li>
                                                <li>Option 03</li>
                                              </ul>
                                            </div>
                                          </div>
                                        </Col>

                                      </Row>
                                    </div>
                                  </>
                                ) : (
                                  <>

                                  </>
                                )}
                              </Card>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <Container>
                            <div className="page-content-inner">
                              <div className='scan-details-page-main'>

                                <Card className='custom-box-shadow border'>
                                  <Card.Body>
                                    <WebsiteURLSection
                                      accessibilitySystemDetected={accessibilitySystemDetected}
                                      accessibilityScore={accessibilityScore}
                                      handleShowPDFReportFormPopup={handleShowPDFReportFormPopup}
                                      loadingPDF={loadingPDF}
                                      handleShowManualAuditFormPopup={handleShowManualAuditFormPopup}
                                      criticalIssues={criticalIssues}
                                      passedAudits={passedAudits}
                                      requiredManualAudits={requiredManualAudits}
                                      notApplicable={notApplicable}
                                      violationsGroupListFilter={violationsGroupListFilter}
                                    />
                                  </Card.Body>
                                </Card>

                                {filteredviolationsGroupList && finalGroupAccessibilityScore ? (
                                  <>
                                    <AccessibilityViolationsList
                                      violationsResult={violationsResult}
                                      violationsGroupList={filteredviolationsGroupList}
                                      handleShowPDFReportFormPopup={handleShowPDFReportFormPopup}
                                      finalGroupAccessibilityScore={finalGroupAccessibilityScore}
                                      violationsGroupListFilter={violationsGroupListFilter}
                                      filterType={filterType}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <ViolationsGroupListPlaceholder />
                                  </>
                                )}
                                {/* {myIP === '103.21.160.77' && (
                          <div className='scanner-strip'>
                            <div className='scanner-strip-text'>Get Accessibility Scanning & Monitoring!</div>
                            <div className='scanner-strip-btn'>
                              <Link to={websiteURLPeram} className='btn btn-light mb-0'>Start Scanning</Link>
                            </div>
                          </div>
                        )} */}

                                <div className='scanner-strip'>
                                  <div className='scanner-strip-text'>Get All in One Accessibility Monitor!</div>
                                  <div className='scanner-strip-btn'>
                                    <Link to={websiteURLPeram} className='btn btn-light mb-0'>Start Scanning</Link>
                                  </div>
                                </div>


                                <footer className='site-footer pb-4'>
                                  <div className="cts-box">
                                    <div className="cts-box-text">
                                      <h2 className="h3 mb-3">Bridge the accessibility gaps with manual accessibility audit!</h2>
                                      <p>Automated scans can identify many common accessibility violations, but some of WCAG 2.1, and 2.2 criteria can’t be reliably tested with automation alone. That’s the reason why we offer expert human or manual accessibility audit to fill those gaps & provide website accessibility and remediation insights.</p>
                                    </div>
                                    <div className="cts-box-btn">
                                      <a href="https://www.skynettechnologies.com/ada-web-accessibility-quote#accessibility_audit" className="btn btn-light">
                                        <span>Request Manual Accessibility Audit</span>
                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 9.5 16">
                                          <path d="M1.5,0L0,1.5L6.5,8L0,14.5L1.5,16l8-8L1.5,0z"></path>
                                        </svg>
                                      </a>
                                    </div>
                                  </div>

                                </footer>


                                <Modal show={showPDFReportForm} size='xl' onHide={handleHidePDFReportFormPopup} centered>
                                  <Modal.Header className='pdf-report-popup-title' closeButton></Modal.Header>
                                  <Modal.Body className='pdf-report-popup-body'>
                                    <div className='pdf-report-form-main'>
                                      <div className='pdf-report-form popup-box'>
                                        {successResponse ? (
                                          <>
                                            <h2 className='h3'>Your compliance audit report is on its way!</h2>
                                            <p>We’ve sent an email to {scannerUserEmail} Please check your inbox and view the report.</p>
                                          </>
                                        ) : (
                                          <>
                                            <h2 className='h3'>Get the PDF report to your email</h2>
                                            {scannerUserFormError && (
                                              <Alert onClose={() => setScannerUserFormError(false)} variant='danger' dismissible>{scannerUserFormErrorMsg}</Alert>
                                            )}
                                            <Form>

                                              <Form.Group className="mb-3" controlId="scannerUserFullName">
                                                <Form.Label>Full name</Form.Label>
                                                <Form.Control type="text" placeholder="Full name" defaultValue={scannerUserFullName} onChange={(e) => setScannerUserFullName(e.target.value)} autoFocus />
                                              </Form.Group>

                                              <Form.Group controlId="scannerUserEmail">
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control type="email" placeholder="Email address" defaultValue={scannerUserEmail} onChange={(e) => setScannerUserEmail(e.target.value)} />
                                              </Form.Group>

                                              <Form.Check
                                                type="switch"
                                                id="customize-switch"
                                                label="Customize your report"
                                                className='mb-4 mt-4'
                                                defaultChecked={customizeReport === 1 ? true : false}
                                                onChange={(e) => handleCustomizeReport(e.target.checked)}
                                              />
                                              {customizeReport === 1 && (
                                                <>
                                                  <div className='custom-logo-color'>
                                                    <Form.Group className='custom-logo-url' controlId="customLogoURL">
                                                      <Form.Label>Your logo image URL</Form.Label>
                                                      <Form.Control type="text" placeholder="Enter image file URL" defaultValue={reportLogoURL} onChange={(e) => URLValidationEvent(e)} />
                                                    </Form.Group>
                                                    <Form.Group className='brand-color' controlId="brandColor">
                                                      <Form.Label>Brand color</Form.Label>
                                                      <Form.Control type="color" placeholder="Email address" defaultValue={brandColor} onChange={(e) => setBrandColor(e.target.value)} />
                                                    </Form.Group>
                                                  </div>
                                                </>
                                              )}

                                              <Button className='mb-0 mt-4' type='button' variant="primary" onClick={handleScanReport} disabled={loadingPDF}>Get Free Report</Button>
                                            </Form>
                                          </>
                                        )}
                                      </div>
                                      <div className='pdf-report-img popup-box text-center'>
                                        <div className={`${(reportLogoURL && customizeReport === 1) ? `scale` : `no-scale`} ${successResponse ? `no-bg` : ``}`}>
                                          {(reportLogoURL && customizeReport === 1) ? (
                                            <>
                                              <div className='custom-logo-wrap'>
                                                <img src={reportLogoURL} alt='' />
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {successResponse ? (
                                            <>
                                              <img src={pdfReportSuccess} height={300} alt='' />
                                            </>
                                          ) : customizeReport === 1 ? (
                                            <>
                                              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                                viewBox="0 0 595.28003 522.32167">
                                                <defs>
                                                  <clipPath id="a">
                                                    <polyline points="0 115.849 0 226.25 595.28 226.25 595.28 115.849 0 115.849"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="c">
                                                    <polyline points="0 51.049 0 115.849 595.28 115.849 595.28 51.049 0 51.049"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="d">
                                                    <polyline points="0 0 0 51.049 595.28 51.049 595.28 0 0 0" style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="e">
                                                    <path
                                                      d="M42,426.96a5.99971,5.99971,0,0,0-6,6v63.701a5.99792,5.99792,0,0,0,6,6H553.28a6.00106,6.00106,0,0,0,6-6V432.96a5.998,5.998,0,0,0-6-6H42"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="f">
                                                    <path
                                                      d="M250.462,464.573a2.99893,2.99893,0,0,0-3,3v13.338a2.999,2.999,0,0,0,3,3h21a3.002,3.002,0,0,0,3-3V467.573a2.999,2.999,0,0,0-3-3h-21"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="g">
                                                    <path
                                                      d="M150.356,464.573a2.999,2.999,0,0,0-3,3v13.338a2.999,2.999,0,0,0,3,3h21a2.99893,2.99893,0,0,0,3-3V467.573a2.999,2.999,0,0,0-3-3h-21"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="h">
                                                    <path
                                                      d="M50.25,464.573a2.999,2.999,0,0,0-3,3v13.338a2.999,2.999,0,0,0,3,3h21a2.999,2.999,0,0,0,3-3V467.573a2.999,2.999,0,0,0-3-3h-21"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="i">
                                                    <path
                                                      d="M42,262.25a6.001,6.001,0,0,0-6,6V413.459a5.99792,5.99792,0,0,0,6,6H553.28a6.00106,6.00106,0,0,0,6-6V268.25a6.0011,6.0011,0,0,0-6-6H42"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="j">
                                                    <path
                                                      d="M449.775,378.845a2.999,2.999,0,0,0-3,3v13.338a3.002,3.002,0,0,0,3,3h21a2.99893,2.99893,0,0,0,3-3V381.845a2.99905,2.99905,0,0,0-3-3h-21"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="k">
                                                    <path
                                                      d="M350.269,378.845a2.999,2.999,0,0,0-3,3v13.338a3.002,3.002,0,0,0,3,3h21a3.002,3.002,0,0,0,3-3V381.845a2.99909,2.99909,0,0,0-3-3h-21"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="l">
                                                    <path
                                                      d="M250.762,378.845a3.002,3.002,0,0,0-3,3v13.338a3.002,3.002,0,0,0,3,3h21a2.99893,2.99893,0,0,0,3-3V381.845a2.99905,2.99905,0,0,0-3-3h-21"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="m">
                                                    <path
                                                      d="M151.256,378.845a3.002,3.002,0,0,0-3,3v13.338a3.002,3.002,0,0,0,3,3h21a2.999,2.999,0,0,0,3-3V381.845a2.99905,2.99905,0,0,0-3-3h-21"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="n">
                                                    <path
                                                      d="M51.75,378.845a2.999,2.999,0,0,0-3,3v13.338a3.002,3.002,0,0,0,3,3h21a2.999,2.999,0,0,0,3-3V381.845a2.99906,2.99906,0,0,0-3-3h-21"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="o">
                                                    <path
                                                      d="M449.775,350.98a2.999,2.999,0,0,0-3,3v13.338a2.999,2.999,0,0,0,3,3h21a2.999,2.999,0,0,0,3-3V353.98a3.002,3.002,0,0,0-3-3h-21"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="p">
                                                    <path
                                                      d="M350.269,350.98a2.999,2.999,0,0,0-3,3v13.338a2.99893,2.99893,0,0,0,3,3h21a3.002,3.002,0,0,0,3-3V353.98a3.002,3.002,0,0,0-3-3h-21"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="q">
                                                    <path
                                                      d="M250.762,350.98a3.002,3.002,0,0,0-3,3v13.338a2.999,2.999,0,0,0,3,3h21a2.999,2.999,0,0,0,3-3V353.98a3.002,3.002,0,0,0-3-3h-21"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="r">
                                                    <path
                                                      d="M151.256,350.98a3.002,3.002,0,0,0-3,3v13.338a2.99894,2.99894,0,0,0,3,3h21a2.999,2.999,0,0,0,3-3V353.98a3.002,3.002,0,0,0-3-3h-21"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="s">
                                                    <path
                                                      d="M51.75,350.98a2.999,2.999,0,0,0-3,3v13.338a2.99894,2.99894,0,0,0,3,3h21a2.999,2.999,0,0,0,3-3V353.98a3.002,3.002,0,0,0-3-3h-21"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="t">
                                                    <path
                                                      d="M54.75,275a6.001,6.001,0,0,0-6,6v14.364a6.00087,6.00087,0,0,0,6,6h19.5a6.00094,6.00094,0,0,0,6-6V281a6.00105,6.00105,0,0,0-6-6H54.75"
                                                      style={{ fill: 'none' }} />
                                                  </clipPath>
                                                  <clipPath id="u">
                                                    <rect x="48.75" y="311.86499" width="497.78003" height="0.75" style={{ fill: 'none' }} />
                                                  </clipPath>
                                                </defs>
                                                <g style={{ clipPath: `url(#a)` }}>
                                                  <rect y="115.849" width="595.28003" height="110.401" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <g style={{ clipPath: `url(#a)` }} >
                                                  <rect y="115.849" width="595.28003" height="110.401"
                                                    style={{ fill: '#fff', opacity: 0.949999988079071 }} />
                                                </g>
                                                <g style={{ clipPath: `url(#c)` }} >
                                                  <rect y="51.04901" width="595.28003" height="64.79999" style={{ fill: '#ffffff' }} />
                                                </g>
                                                <g style={{ clipPath: `url(#d)` }} >
                                                  <rect width="595.28003" height="51.04901" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <text transform="translate(36 29.3739)"
                                                  style={{ fontSize: '11px', fontFamily: 'Roboto-Regular, Roboto' }}> </text>
                                                <path
                                                  d="M473.10742,24.7074a1.64676,1.64676,0,0,1,.21485-.8418,1.58985,1.58985,0,0,1,.583-.583,1.643,1.643,0,0,1,.8418-.21435h6.2041a1.63937,1.63937,0,0,1,.84082.21435,1.58258,1.58258,0,0,1,.583.583,1.63722,1.63722,0,0,1,.21484.8418v.022a1.63668,1.63668,0,0,1-.21484.84131,1.58258,1.58258,0,0,1-.583.583,1.63937,1.63937,0,0,1-.84082.21435h-6.2041a1.643,1.643,0,0,1-.8418-.21435,1.58985,1.58985,0,0,1-.583-.583,1.64622,1.64622,0,0,1-.21485-.84131Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M479.28906,24.7074a1.64676,1.64676,0,0,1,.21485-.8418,1.58985,1.58985,0,0,1,.583-.583,1.643,1.643,0,0,1,.8418-.21435h6.2041a1.63934,1.63934,0,0,1,.84082.21435,1.58258,1.58258,0,0,1,.583.583,1.63712,1.63712,0,0,1,.21484.8418v.022a1.63658,1.63658,0,0,1-.21484.84131,1.58258,1.58258,0,0,1-.583.583,1.63934,1.63934,0,0,1-.84082.21435h-6.2041a1.643,1.643,0,0,1-.8418-.21435,1.58985,1.58985,0,0,1-.583-.583,1.64622,1.64622,0,0,1-.21485-.84131Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M485.47168,24.7074a1.64685,1.64685,0,0,1,.21484-.8418,1.58988,1.58988,0,0,1,.583-.583,1.643,1.643,0,0,1,.8418-.21435h6.2041a1.63939,1.63939,0,0,1,.84082.21435,1.58265,1.58265,0,0,1,.583.583,1.63722,1.63722,0,0,1,.21484.8418v.022a1.63668,1.63668,0,0,1-.21484.84131,1.58265,1.58265,0,0,1-.583.583,1.63939,1.63939,0,0,1-.84082.21435h-6.2041a1.643,1.643,0,0,1-.8418-.21435,1.58988,1.58988,0,0,1-.583-.583,1.64631,1.64631,0,0,1-.21484-.84131Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M498.25293,24.7074a1.64685,1.64685,0,0,1,.21484-.8418,1.58988,1.58988,0,0,1,.583-.583,1.643,1.643,0,0,1,.8418-.21435h3.168a1.63687,1.63687,0,0,1,.84082.21435,1.58265,1.58265,0,0,1,.583.583,1.63722,1.63722,0,0,1,.21484.8418v.022a1.63668,1.63668,0,0,1-.21484.84131,1.58265,1.58265,0,0,1-.583.583,1.63687,1.63687,0,0,1-.84082.21435h-3.168a1.643,1.643,0,0,1-.8418-.21435,1.58988,1.58988,0,0,1-.583-.583,1.64631,1.64631,0,0,1-.21484-.84131Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M501.39941,24.7074a1.64676,1.64676,0,0,1,.21485-.8418,1.58988,1.58988,0,0,1,.583-.583,1.643,1.643,0,0,1,.84179-.21435h6.2041a1.63934,1.63934,0,0,1,.84082.21435,1.58258,1.58258,0,0,1,.583.583,1.63713,1.63713,0,0,1,.21485.8418v.022a1.63659,1.63659,0,0,1-.21485.84131,1.58258,1.58258,0,0,1-.583.583,1.63934,1.63934,0,0,1-.84082.21435h-6.2041a1.643,1.643,0,0,1-.84179-.21435,1.58988,1.58988,0,0,1-.583-.583,1.64622,1.64622,0,0,1-.21485-.84131Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M507.58105,24.7074a1.64676,1.64676,0,0,1,.21485-.8418,1.58988,1.58988,0,0,1,.583-.583,1.643,1.643,0,0,1,.84179-.21435h6.2041a1.63934,1.63934,0,0,1,.84082.21435,1.58258,1.58258,0,0,1,.583.583,1.63713,1.63713,0,0,1,.21485.8418v.022a1.63659,1.63659,0,0,1-.21485.84131,1.58258,1.58258,0,0,1-.583.583,1.63934,1.63934,0,0,1-.84082.21435h-6.2041a1.643,1.643,0,0,1-.84179-.21435,1.58988,1.58988,0,0,1-.583-.583,1.64622,1.64622,0,0,1-.21485-.84131Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M520.36328,24.7074a1.64685,1.64685,0,0,1,.21484-.8418,1.59,1.59,0,0,1,.583-.583,1.643,1.643,0,0,1,.8418-.21435h6.2041a1.63939,1.63939,0,0,1,.84082.21435,1.58258,1.58258,0,0,1,.583.583,1.63722,1.63722,0,0,1,.21484.8418v.022a1.63668,1.63668,0,0,1-.21484.84131,1.58258,1.58258,0,0,1-.583.583,1.63939,1.63939,0,0,1-.84082.21435h-6.2041a1.643,1.643,0,0,1-.8418-.21435,1.59,1.59,0,0,1-.583-.583,1.64631,1.64631,0,0,1-.21484-.84131Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M526.54492,24.7074a1.64676,1.64676,0,0,1,.21485-.8418,1.58985,1.58985,0,0,1,.583-.583,1.643,1.643,0,0,1,.8418-.21435h6.2041a1.63937,1.63937,0,0,1,.84082.21435,1.58258,1.58258,0,0,1,.583.583,1.63722,1.63722,0,0,1,.21484.8418v.022a1.63668,1.63668,0,0,1-.21484.84131,1.58258,1.58258,0,0,1-.583.583,1.63937,1.63937,0,0,1-.84082.21435h-6.2041a1.643,1.643,0,0,1-.8418-.21435,1.58985,1.58985,0,0,1-.583-.583,1.64622,1.64622,0,0,1-.21485-.84131Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M532.72656,24.7074a1.64676,1.64676,0,0,1,.21485-.8418,1.58985,1.58985,0,0,1,.583-.583,1.643,1.643,0,0,1,.8418-.21435h3.168a1.63687,1.63687,0,0,1,.84082.21435,1.58265,1.58265,0,0,1,.583.583,1.63722,1.63722,0,0,1,.21484.8418v.022a1.63668,1.63668,0,0,1-.21484.84131,1.58265,1.58265,0,0,1-.583.583,1.63687,1.63687,0,0,1-.84082.21435h-3.168a1.643,1.643,0,0,1-.8418-.21435,1.58985,1.58985,0,0,1-.583-.583,1.64622,1.64622,0,0,1-.21485-.84131Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M542.47266,24.7074a1.64675,1.64675,0,0,1,.21484-.8418,1.58988,1.58988,0,0,1,.583-.583,1.643,1.643,0,0,1,.84179-.21435h6.20411a1.63939,1.63939,0,0,1,.84082.21435,1.58255,1.58255,0,0,1,.583.583,1.63713,1.63713,0,0,1,.21485.8418v.022a1.63659,1.63659,0,0,1-.21485.84131,1.58255,1.58255,0,0,1-.583.583,1.63939,1.63939,0,0,1-.84082.21435H544.1123a1.643,1.643,0,0,1-.84179-.21435,1.58988,1.58988,0,0,1-.583-.583,1.64621,1.64621,0,0,1-.21484-.84131Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M548.6543,24.7074a1.64675,1.64675,0,0,1,.21484-.8418,1.58988,1.58988,0,0,1,.583-.583,1.643,1.643,0,0,1,.8418-.21435h6.2041a1.63939,1.63939,0,0,1,.84082.21435,1.58265,1.58265,0,0,1,.583.583,1.63722,1.63722,0,0,1,.21484.8418v.022a1.63668,1.63668,0,0,1-.21484.84131,1.58265,1.58265,0,0,1-.583.583,1.63939,1.63939,0,0,1-.84082.21435H550.294a1.643,1.643,0,0,1-.8418-.21435,1.58988,1.58988,0,0,1-.583-.583,1.64621,1.64621,0,0,1-.21484-.84131Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M554.83691,24.7074a1.64676,1.64676,0,0,1,.21485-.8418,1.58988,1.58988,0,0,1,.583-.583,1.643,1.643,0,0,1,.84179-.21435h6.2041a1.63934,1.63934,0,0,1,.84082.21435,1.58258,1.58258,0,0,1,.583.583,1.63713,1.63713,0,0,1,.21485.8418v.022a1.63659,1.63659,0,0,1-.21485.84131,1.58258,1.58258,0,0,1-.583.583,1.63934,1.63934,0,0,1-.84082.21435h-6.2041a1.643,1.643,0,0,1-.84179-.21435,1.58988,1.58988,0,0,1-.583-.583,1.64622,1.64622,0,0,1-.21485-.84131Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M561.01855,24.7074a1.64676,1.64676,0,0,1,.21485-.8418,1.58988,1.58988,0,0,1,.583-.583,1.643,1.643,0,0,1,.84179-.21435h6.2041a1.63934,1.63934,0,0,1,.84082.21435,1.58258,1.58258,0,0,1,.583.583,1.63713,1.63713,0,0,1,.21485.8418v.022a1.63659,1.63659,0,0,1-.21485.84131,1.58258,1.58258,0,0,1-.583.583,1.63934,1.63934,0,0,1-.84082.21435h-6.2041a1.643,1.643,0,0,1-.84179-.21435,1.58988,1.58988,0,0,1-.583-.583,1.64622,1.64622,0,0,1-.21485-.84131Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <text transform="translate(198.26514 87.6488)" style={{ fontSize: `15px`, fontFamily: `SegoeUI-Bold, Segoe UI`, fontWeight: 700 }}>Accessibility
                                                  Report Preview</text>
                                                <text transform="translate(454.51855 80.42322)" style={{ fontSize: '11px', fontFamily: 'Roboto-Regular, Roboto' }}> </text>
                                                <path
                                                  d="M98.665,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92237a1.57107,1.57107,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56624,1.56624,0,0,1-.80322.20459h-5.92237a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M104.56592,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h3.02442a1.57107,1.57107,0,0,1,.80322.20459,1.51661,1.51661,0,0,1,.55615.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.513,1.513,0,0,1-.55615.55664,1.56624,1.56624,0,0,1-.80322.20459h-3.02442a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M107.56885,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57108,1.57108,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56625,1.56625,0,0,1-.80323.20459H109.1333a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M113.46973,192.50427a1.56659,1.56659,0,0,1,.20507-.80322,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57111,1.57111,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56628,1.56628,0,0,1-.80323.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.51306,1.51306,0,0,1-.55616-.55664,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M119.37109,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459H123.96a1.571,1.571,0,0,1,.80322.20459,1.51657,1.51657,0,0,1,.55616.55664,1.56659,1.56659,0,0,1,.20507.80322v.021a1.56406,1.56406,0,0,1-.20507.80322,1.513,1.513,0,0,1-.55616.55664,1.56622,1.56622,0,0,1-.80322.20459h-3.02441a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M128.67383,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57108,1.57108,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56625,1.56625,0,0,1-.80323.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M134.57471,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57111,1.57111,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56628,1.56628,0,0,1-.80323.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M140.47559,192.50427a1.56659,1.56659,0,0,1,.20507-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h3.02441a1.57111,1.57111,0,0,1,.80323.20459,1.51661,1.51661,0,0,1,.55615.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.513,1.513,0,0,1-.55615.55664,1.56628,1.56628,0,0,1-.80323.20459H142.04a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M143.47852,192.50427a1.56659,1.56659,0,0,1,.20507-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52156,1.52156,0,0,1,.55665.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55665.55664,1.56622,1.56622,0,0,1-.80322.20459H145.043a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M149.37939,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h3.02441a1.571,1.571,0,0,1,.80322.20459,1.51657,1.51657,0,0,1,.55616.55664,1.56649,1.56649,0,0,1,.20507.80322v.021a1.564,1.564,0,0,1-.20507.80322,1.513,1.513,0,0,1-.55616.55664,1.56622,1.56622,0,0,1-.80322.20459h-3.02441a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M158.68213,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459H166.169a1.57107,1.57107,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56624,1.56624,0,0,1-.80322.20459h-5.92237a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M164.5835,192.50427a1.56659,1.56659,0,0,1,.20507-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57111,1.57111,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56628,1.56628,0,0,1-.80323.20459H166.148a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M170.48438,192.50427a1.56649,1.56649,0,0,1,.20507-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52146,1.52146,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56841,1.56841,0,0,1-.20459.80322,1.51785,1.51785,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.564,1.564,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M176.38525,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M182.28613,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459H189.773a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56625,1.56625,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M188.187,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57107,1.57107,0,0,1,.80322-.20459h3.02442a1.571,1.571,0,0,1,.80322.20459,1.51654,1.51654,0,0,1,.55615.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.513,1.513,0,0,1-.55615.55664,1.56622,1.56622,0,0,1-.80322.20459h-3.02442a1.56624,1.56624,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M191.18994,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57107,1.57107,0,0,1,.80322-.20459h3.02442a1.571,1.571,0,0,1,.80322.20459,1.51654,1.51654,0,0,1,.55615.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.513,1.513,0,0,1-.55615.55664,1.56622,1.56622,0,0,1-.80322.20459h-3.02442a1.56624,1.56624,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M194.19287,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92237a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92237a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M200.09375,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92237a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459H201.6582a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M205.99512,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57111,1.57111,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56628,1.56628,0,0,1-.80323.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M211.896,192.50427a1.56659,1.56659,0,0,1,.20507-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57111,1.57111,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56628,1.56628,0,0,1-.80323.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M217.79688,192.50427a1.56649,1.56649,0,0,1,.20507-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52146,1.52146,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56841,1.56841,0,0,1-.20459.80322,1.51785,1.51785,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.564,1.564,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M223.69775,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h3.02441a1.571,1.571,0,0,1,.80322.20459,1.51657,1.51657,0,0,1,.55616.55664,1.56659,1.56659,0,0,1,.20507.80322v.021a1.56406,1.56406,0,0,1-.20507.80322,1.513,1.513,0,0,1-.55616.55664,1.56622,1.56622,0,0,1-.80322.20459h-3.02441a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M226.70068,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M232.60156,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459H234.166a1.56625,1.56625,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M238.50244,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57107,1.57107,0,0,1,.80322-.20459h3.02442a1.571,1.571,0,0,1,.80322.20459,1.51654,1.51654,0,0,1,.55615.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.513,1.513,0,0,1-.55615.55664,1.56622,1.56622,0,0,1-.80322.20459h-3.02442a1.56624,1.56624,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M241.50537,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92237a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92237a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M247.40674,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57108,1.57108,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56625,1.56625,0,0,1-.80323.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M253.30762,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h3.02441a1.57108,1.57108,0,0,1,.80323.20459,1.51661,1.51661,0,0,1,.55615.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.513,1.513,0,0,1-.55615.55664,1.56625,1.56625,0,0,1-.80323.20459h-3.02441a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M256.31055,192.50427a1.56659,1.56659,0,0,1,.20507-.80322,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57111,1.57111,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56628,1.56628,0,0,1-.80323.20459H257.875a1.56622,1.56622,0,0,1-.80322-.20459,1.51306,1.51306,0,0,1-.55616-.55664,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M262.21143,192.50427a1.56659,1.56659,0,0,1,.20507-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52156,1.52156,0,0,1,.55665.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55665.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M268.1123,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52146,1.52146,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51785,1.51785,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M280.313,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57108,1.57108,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56625,1.56625,0,0,1-.80323.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M286.21436,192.50427a1.56649,1.56649,0,0,1,.20507-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52156,1.52156,0,0,1,.55665.55664,1.571,1.571,0,0,1,.20458.80322v.021a1.5685,1.5685,0,0,1-.20458.80322,1.518,1.518,0,0,1-.55665.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.564,1.564,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M292.11523,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52146,1.52146,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.20411.80322v.021a1.56343,1.56343,0,0,1-.20411.80322,1.51785,1.51785,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M298.01562,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h3.02441a1.568,1.568,0,0,1,.80274.20459,1.52161,1.52161,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.518,1.518,0,0,1-.55664.55664,1.56321,1.56321,0,0,1-.80274.20459h-3.02441a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M301.01855,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52146,1.52146,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.20411.80322v.021a1.56343,1.56343,0,0,1-.20411.80322,1.51785,1.51785,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459H302.583a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M313.21973,192.50427a1.56659,1.56659,0,0,1,.20507-.80322,1.52156,1.52156,0,0,1,.55665-.55664,1.568,1.568,0,0,1,.80273-.20459h3.02441a1.568,1.568,0,0,1,.80274.20459,1.52161,1.52161,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.518,1.518,0,0,1-.55664.55664,1.56319,1.56319,0,0,1-.80274.20459h-3.02441a1.56315,1.56315,0,0,1-.80273-.20459,1.518,1.518,0,0,1-.55665-.55664,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M316.22266,192.50427a1.56659,1.56659,0,0,1,.20507-.80322,1.52156,1.52156,0,0,1,.55665-.55664,1.568,1.568,0,0,1,.80273-.20459H323.709a1.57162,1.57162,0,0,1,.80372.20459,1.52161,1.52161,0,0,1,.55664.55664,1.566,1.566,0,0,1,.2041.80322v.021a1.56352,1.56352,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.5668,1.5668,0,0,1-.80372.20459h-5.92187a1.56315,1.56315,0,0,1-.80273-.20459,1.518,1.518,0,0,1-.55665-.55664,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M322.124,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52153,1.52153,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M334.32422,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459h-5.92188a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M340.22559,192.50427a1.56659,1.56659,0,0,1,.20507-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57156,1.57156,0,0,1,.80371.20459,1.52156,1.52156,0,0,1,.55665.55664,1.566,1.566,0,0,1,.2041.80322v.021a1.56352,1.56352,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55665.55664,1.56674,1.56674,0,0,1-.80371.20459H341.79a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M346.127,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52153,1.52153,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56319,1.56319,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M358.32715,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459H359.8916a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M364.22852,192.50427a1.56659,1.56659,0,0,1,.20507-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52156,1.52156,0,0,1,.55665.55664,1.566,1.566,0,0,1,.2041.80322v.021a1.56352,1.56352,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55665.55664,1.56676,1.56676,0,0,1-.80371.20459H365.793a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M370.12891,192.50427a1.56659,1.56659,0,0,1,.20507-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57162,1.57162,0,0,1,.80372.20459,1.52161,1.52161,0,0,1,.55664.55664,1.566,1.566,0,0,1,.2041.80322v.021a1.56352,1.56352,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.5668,1.5668,0,0,1-.80372.20459h-5.92187a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M376.03027,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h3.02441a1.568,1.568,0,0,1,.80274.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56659,1.56659,0,0,1,.20507.80322v.021a1.56406,1.56406,0,0,1-.20507.80322,1.518,1.518,0,0,1-.55664.55664,1.56321,1.56321,0,0,1-.80274.20459h-3.02441a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M379.0332,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52153,1.52153,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M384.93457,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459H386.499a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M390.835,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h3.02442a1.568,1.568,0,0,1,.80273.20459,1.52153,1.52153,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56315,1.56315,0,0,1-.80273.20459h-3.02442a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M400.1377,192.50427a1.56649,1.56649,0,0,1,.20507-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459H407.624a1.57159,1.57159,0,0,1,.80371.20459,1.52156,1.52156,0,0,1,.55665.55664,1.566,1.566,0,0,1,.2041.80322v.021a1.56352,1.56352,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55665.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M406.03906,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52153,1.52153,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56319,1.56319,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M411.93945,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52153,1.52153,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56319,1.56319,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M417.84082,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459h-5.92188a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M423.74121,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57159,1.57159,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92188a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M429.64258,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459H431.207a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M435.544,192.50427a1.56649,1.56649,0,0,1,.20507-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h3.02441a1.568,1.568,0,0,1,.80274.20459,1.52161,1.52161,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.518,1.518,0,0,1-.55664.55664,1.56321,1.56321,0,0,1-.80274.20459H437.1084a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M438.54688,192.50427a1.56649,1.56649,0,0,1,.20507-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52146,1.52146,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.20411.80322v.021a1.56343,1.56343,0,0,1-.20411.80322,1.51785,1.51785,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M444.44727,192.50427a1.56649,1.56649,0,0,1,.20507-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h3.02441a1.568,1.568,0,0,1,.80274.20459,1.52161,1.52161,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.518,1.518,0,0,1-.55664.55664,1.56321,1.56321,0,0,1-.80274.20459h-3.02441a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M447.4502,192.50427a1.56649,1.56649,0,0,1,.20507-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h3.02441a1.568,1.568,0,0,1,.80274.20459,1.52161,1.52161,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.518,1.518,0,0,1-.55664.55664,1.56321,1.56321,0,0,1-.80274.20459h-3.02441a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M450.45312,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459H455.042a1.568,1.568,0,0,1,.80274.20459,1.52161,1.52161,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.518,1.518,0,0,1-.55664.55664,1.56321,1.56321,0,0,1-.80274.20459h-3.02441a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M453.45605,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h3.02441a1.568,1.568,0,0,1,.80274.20459,1.52161,1.52161,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.518,1.518,0,0,1-.55664.55664,1.56321,1.56321,0,0,1-.80274.20459h-3.02441a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M456.459,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52146,1.52146,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.20411.80322v.021a1.56343,1.56343,0,0,1-.20411.80322,1.51785,1.51785,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M468.66016,192.50427a1.56659,1.56659,0,0,1,.20507-.80322,1.52156,1.52156,0,0,1,.55665-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92187a1.57162,1.57162,0,0,1,.80372.20459,1.52161,1.52161,0,0,1,.55664.55664,1.566,1.566,0,0,1,.2041.80322v.021a1.56352,1.56352,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.5668,1.5668,0,0,1-.80372.20459h-5.92187a1.56315,1.56315,0,0,1-.80273-.20459,1.518,1.518,0,0,1-.55665-.55664,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M474.56055,192.50427a1.56659,1.56659,0,0,1,.20507-.80322,1.52156,1.52156,0,0,1,.55665-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459H476.125a1.56315,1.56315,0,0,1-.80273-.20459,1.518,1.518,0,0,1-.55665-.55664,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M480.46191,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h3.02441a1.568,1.568,0,0,1,.80274.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56649,1.56649,0,0,1,.20507.80322v.021a1.564,1.564,0,0,1-.20507.80322,1.518,1.518,0,0,1-.55664.55664,1.56321,1.56321,0,0,1-.80274.20459h-3.02441a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M483.46484,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52153,1.52153,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459H485.0293a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M489.36621,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57159,1.57159,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92188a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M495.2666,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h3.02442a1.568,1.568,0,0,1,.80273.20459,1.52153,1.52153,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56315,1.56315,0,0,1-.80273.20459h-3.02442a1.56317,1.56317,0,0,1-.80273-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M498.26953,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h3.02442a1.568,1.568,0,0,1,.80273.20459,1.52153,1.52153,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56315,1.56315,0,0,1-.80273.20459H499.834a1.56315,1.56315,0,0,1-.80273-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M501.27246,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52153,1.52153,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459h-5.92188a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M507.17383,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52146,1.52146,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459h-5.92188a1.56315,1.56315,0,0,1-.80273-.20459,1.51785,1.51785,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M513.07422,192.50427a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56342,1.56342,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459h-5.92188a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M225.71973,207.20447a1.56659,1.56659,0,0,1,.20507-.80322,1.51667,1.51667,0,0,1,.55616-.55665,1.571,1.571,0,0,1,.80322-.20459h3.02441a1.57108,1.57108,0,0,1,.80323.20459,1.51664,1.51664,0,0,1,.55615.55665,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80323,1.513,1.513,0,0,1-.55615.55664,1.56625,1.56625,0,0,1-.80323.20459h-3.02441a1.56622,1.56622,0,0,1-.80322-.20459,1.51306,1.51306,0,0,1-.55616-.55664,1.56412,1.56412,0,0,1-.20507-.80323Z" />
                                                <path
                                                  d="M228.72266,207.20447a1.56659,1.56659,0,0,1,.20507-.80322,1.51659,1.51659,0,0,1,.55616-.55665,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57111,1.57111,0,0,1,.80323.20459,1.52163,1.52163,0,0,1,.55664.55665,1.571,1.571,0,0,1,.20459.80322v.021a1.56857,1.56857,0,0,1-.20459.80323,1.518,1.518,0,0,1-.55664.55664,1.56628,1.56628,0,0,1-.80323.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.56412,1.56412,0,0,1-.20507-.80323Z" />
                                                <path
                                                  d="M234.62354,207.20447a1.56649,1.56649,0,0,1,.20507-.80322,1.51659,1.51659,0,0,1,.55616-.55665,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52148,1.52148,0,0,1,.55664.55665,1.571,1.571,0,0,1,.20459.80322v.021a1.56847,1.56847,0,0,1-.20459.80323,1.51785,1.51785,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459H236.188a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.564,1.564,0,0,1-.20507-.80323Z" />
                                                <path
                                                  d="M240.52441,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.51664,1.51664,0,0,1,.55615-.55665,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52148,1.52148,0,0,1,.55664.55665,1.571,1.571,0,0,1,.20459.80322v.021a1.56857,1.56857,0,0,1-.20459.80323,1.51785,1.51785,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M246.42578,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.51664,1.51664,0,0,1,.55615-.55665,1.57107,1.57107,0,0,1,.80322-.20459h5.92237a1.571,1.571,0,0,1,.80322.20459,1.52156,1.52156,0,0,1,.55664.55665,1.571,1.571,0,0,1,.20459.80322v.021a1.56857,1.56857,0,0,1-.20459.80323,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92237a1.56624,1.56624,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M252.32666,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.51664,1.51664,0,0,1,.55615-.55665,1.571,1.571,0,0,1,.80322-.20459h3.02442a1.571,1.571,0,0,1,.80322.20459,1.51664,1.51664,0,0,1,.55615.55665,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80323,1.513,1.513,0,0,1-.55615.55664,1.56622,1.56622,0,0,1-.80322.20459h-3.02442a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M261.62939,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.51664,1.51664,0,0,1,.55615-.55665,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52148,1.52148,0,0,1,.55664.55665,1.571,1.571,0,0,1,.20459.80322v.021a1.56857,1.56857,0,0,1-.20459.80323,1.51785,1.51785,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M267.53027,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.51664,1.51664,0,0,1,.55615-.55665,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52156,1.52156,0,0,1,.55664.55665,1.571,1.571,0,0,1,.20459.80322v.021a1.56857,1.56857,0,0,1-.20459.80323,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M273.43115,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.51664,1.51664,0,0,1,.55615-.55665,1.57108,1.57108,0,0,1,.80323-.20459H280.918a1.571,1.571,0,0,1,.80322.20459,1.52156,1.52156,0,0,1,.55664.55665,1.571,1.571,0,0,1,.20459.80322v.021a1.56857,1.56857,0,0,1-.20459.80323,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56625,1.56625,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M285.63232,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.51664,1.51664,0,0,1,.55615-.55665,1.57111,1.57111,0,0,1,.80323-.20459h3.02441a1.571,1.571,0,0,1,.80322.20459,1.51659,1.51659,0,0,1,.55616.55665,1.56649,1.56649,0,0,1,.20507.80322v.021a1.564,1.564,0,0,1-.20507.80323,1.513,1.513,0,0,1-.55616.55664,1.56622,1.56622,0,0,1-.80322.20459h-3.02441a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M288.63525,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.51664,1.51664,0,0,1,.55615-.55665,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.5192,1.5192,0,0,1,.55713.55665,1.56539,1.56539,0,0,1,.20313.80322v.021a1.56294,1.56294,0,0,1-.20313.80323,1.51558,1.51558,0,0,1-.55713.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M300.83594,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.52148,1.52148,0,0,1,.55664-.55665,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52163,1.52163,0,0,1,.55664.55665,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56348,1.56348,0,0,1-.2041.80323,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459h-5.92188a1.56315,1.56315,0,0,1-.80273-.20459,1.51785,1.51785,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M306.7373,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.52163,1.52163,0,0,1,.55664-.55665,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52148,1.52148,0,0,1,.55664.55665,1.56594,1.56594,0,0,1,.20411.80322v.021a1.56349,1.56349,0,0,1-.20411.80323,1.51785,1.51785,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M312.6377,207.20447a1.56649,1.56649,0,0,1,.20507-.80322,1.52163,1.52163,0,0,1,.55664-.55665,1.568,1.568,0,0,1,.80274-.20459H320.124a1.57159,1.57159,0,0,1,.80371.20459,1.52159,1.52159,0,0,1,.55665.55665,1.566,1.566,0,0,1,.2041.80322v.021a1.56358,1.56358,0,0,1-.2041.80323,1.518,1.518,0,0,1-.55665.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20507-.80323Z" />
                                                <path
                                                  d="M318.53906,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.52163,1.52163,0,0,1,.55664-.55665,1.568,1.568,0,0,1,.80274-.20459h3.02441a1.568,1.568,0,0,1,.80273.20459,1.52148,1.52148,0,0,1,.55664.55665,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80323,1.51785,1.51785,0,0,1-.55664.55664,1.56315,1.56315,0,0,1-.80273.20459h-3.02441a1.56319,1.56319,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M321.542,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.52163,1.52163,0,0,1,.55664-.55665,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52156,1.52156,0,0,1,.55664.55665,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56348,1.56348,0,0,1-.2041.80323,1.51793,1.51793,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56319,1.56319,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M327.44238,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.52163,1.52163,0,0,1,.55664-.55665,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52156,1.52156,0,0,1,.55664.55665,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56348,1.56348,0,0,1-.2041.80323,1.51793,1.51793,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56319,1.56319,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M333.34375,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.52156,1.52156,0,0,1,.55664-.55665,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52163,1.52163,0,0,1,.55664.55665,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56348,1.56348,0,0,1-.2041.80323,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459H334.9082a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M339.24414,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.52156,1.52156,0,0,1,.55664-.55665,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52163,1.52163,0,0,1,.55664.55665,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56348,1.56348,0,0,1-.2041.80323,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459h-5.92188a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M345.14551,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.52148,1.52148,0,0,1,.55664-.55665A1.568,1.568,0,0,1,346.71,205.64h3.02442a1.568,1.568,0,0,1,.80273.20459,1.52163,1.52163,0,0,1,.55664.55665,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80323,1.518,1.518,0,0,1-.55664.55664,1.56317,1.56317,0,0,1-.80273.20459H346.71a1.56315,1.56315,0,0,1-.80273-.20459,1.51785,1.51785,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M348.14844,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.52148,1.52148,0,0,1,.55664-.55665,1.568,1.568,0,0,1,.80273-.20459h3.02441a1.568,1.568,0,0,1,.80274.20459,1.52163,1.52163,0,0,1,.55664.55665,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80323,1.518,1.518,0,0,1-.55664.55664,1.56319,1.56319,0,0,1-.80274.20459h-3.02441a1.56315,1.56315,0,0,1-.80273-.20459,1.51785,1.51785,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M351.15137,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.52148,1.52148,0,0,1,.55664-.55665,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52163,1.52163,0,0,1,.55664.55665,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56348,1.56348,0,0,1-.2041.80323,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459h-5.92188a1.56315,1.56315,0,0,1-.80273-.20459,1.51785,1.51785,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M357.05273,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.52163,1.52163,0,0,1,.55664-.55665,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52148,1.52148,0,0,1,.55664.55665,1.56594,1.56594,0,0,1,.20411.80322v.021a1.56349,1.56349,0,0,1-.20411.80323,1.51785,1.51785,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M362.95312,207.20447a1.5665,1.5665,0,0,1,.20508-.80322,1.52163,1.52163,0,0,1,.55664-.55665,1.568,1.568,0,0,1,.80274-.20459H367.542a1.568,1.568,0,0,1,.80274.20459,1.52163,1.52163,0,0,1,.55664.55665,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80323,1.518,1.518,0,0,1-.55664.55664,1.56321,1.56321,0,0,1-.80274.20459h-3.02441a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <g style={{ clipPath: `url(#e)` }}>
                                                  <rect x="36" y="426.96002" width="523.28003" height="75.702" style={{ fill: '#f8f8f8' }} />
                                                </g>
                                                <g style={{ clipPath: `url(#f)` }}>
                                                  <rect x="247.46201" y="464.57303" width="27" height="19.33801" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <g style={{ clipPath: `url(#g)` }}>
                                                  <rect x="147.356" y="464.57303" width="27" height="19.33801" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <g style={{ clipPath: `url(#h)` }}>
                                                  <rect x="47.25" y="464.57303" width="27" height="19.33801" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <g style={{ clipPath: `url(#i)` }}>
                                                  <rect x="36" y="262.25" width="523.28003" height="157.20898" style={{ fill: '#f8f8f8' }} />
                                                </g>
                                                <g style={{ clipPath: `url(#j)` }}>
                                                  <rect x="446.77499" y="378.845" width="27" height="19.33801" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <g style={{ clipPath: `url(#k)` }} >
                                                  <rect x="347.26901" y="378.845" width="27" height="19.33801" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <g style={{ clipPath: `url(#l)` }}>
                                                  <rect x="247.76199" y="378.845" width="27" height="19.33801" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <g style={{ clipPath: `url(#m)` }}>
                                                  <rect x="148.256" y="378.845" width="27" height="19.33801" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <g style={{ clipPath: `url(#n)` }}>
                                                  <rect x="48.75" y="378.845" width="27" height="19.33801" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <g style={{ clipPath: `url(#o)` }}>
                                                  <rect x="446.77499" y="350.98001" width="27" height="19.33801" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <g style={{ clipPath: `url(#p)` }}>
                                                  <rect x="347.26901" y="350.98001" width="27" height="19.33801" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <g style={{ clipPath: `url(#q)` }}>
                                                  <rect x="247.76199" y="350.98001" width="27" height="19.33801" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <g style={{ clipPath: `url(#r)` }}>
                                                  <rect x="148.256" y="350.98001" width="27" height="19.33801" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <g style={{ clipPath: `url(#s)` }}>
                                                  <rect x="48.75" y="350.98001" width="27" height="19.33801" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <g style={{ clipPath: `url(#t)` }}>
                                                  <rect x="48.75" y="275" width="31.5" height="26.36401" style={{ fill: brandColor ? brandColor : `#420083` }} />
                                                </g>
                                                <path
                                                  d="M59.91162,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92237a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51788,1.51788,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459H61.47607a1.56631,1.56631,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M84.6748,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51781,1.51781,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459H86.23926a1.56631,1.56631,0,0,1-.80322-.20459,1.51294,1.51294,0,0,1-.55616-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M90.57568,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459H98.0625a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51788,1.51788,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459H92.14014a1.56638,1.56638,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M102.77637,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h3.02441a1.57108,1.57108,0,0,1,.80323.20459,1.51669,1.51669,0,0,1,.55615.55664,1.56653,1.56653,0,0,1,.20508.80323v.021a1.564,1.564,0,0,1-.20508.80322,1.513,1.513,0,0,1-.55615.55665,1.56635,1.56635,0,0,1-.80323.20459h-3.02441a1.56631,1.56631,0,0,1-.80322-.20459,1.51291,1.51291,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M105.77979,288.48621a1.56653,1.56653,0,0,1,.20507-.80323,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80323v.021a1.56841,1.56841,0,0,1-.20459.80322,1.51781,1.51781,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56631,1.56631,0,0,1-.80322-.20459,1.51294,1.51294,0,0,1-.55616-.55665,1.564,1.564,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M111.68066,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51788,1.51788,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56638,1.56638,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M117.58154,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51788,1.51788,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459H119.146a1.56635,1.56635,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M123.48242,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51788,1.51788,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56635,1.56635,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M135.68311,288.48621a1.56653,1.56653,0,0,1,.20507-.80323,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52163,1.52163,0,0,1,.55665.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51791,1.51791,0,0,1-.55665.55665,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56631,1.56631,0,0,1-.80322-.20459,1.51294,1.51294,0,0,1-.55616-.55665,1.564,1.564,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M147.88428,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h3.02441a1.57108,1.57108,0,0,1,.80323.20459,1.51669,1.51669,0,0,1,.55615.55664,1.56653,1.56653,0,0,1,.20508.80323v.021a1.564,1.564,0,0,1-.20508.80322,1.513,1.513,0,0,1-.55615.55665,1.56635,1.56635,0,0,1-.80323.20459h-3.02441a1.56631,1.56631,0,0,1-.80322-.20459,1.51291,1.51291,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M150.88721,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459H158.374a1.57111,1.57111,0,0,1,.80323.20459,1.52168,1.52168,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55665,1.56638,1.56638,0,0,1-.80323.20459h-5.92236a1.56631,1.56631,0,0,1-.80322-.20459,1.51291,1.51291,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M156.78809,288.48621a1.56663,1.56663,0,0,1,.20507-.80323,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52163,1.52163,0,0,1,.55665.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51791,1.51791,0,0,1-.55665.55665,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56631,1.56631,0,0,1-.80322-.20459,1.51294,1.51294,0,0,1-.55616-.55665,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M162.689,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80323v.021a1.56841,1.56841,0,0,1-.20459.80322,1.51781,1.51781,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56631,1.56631,0,0,1-.80322-.20459,1.51294,1.51294,0,0,1-.55616-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M168.58984,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51788,1.51788,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459H170.1543a1.56638,1.56638,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M174.49072,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51788,1.51788,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56635,1.56635,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M186.69189,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51781,1.51781,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56638,1.56638,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M192.59277,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51788,1.51788,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56638,1.56638,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M204.79346,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57111,1.57111,0,0,1,.80323.20459,1.52168,1.52168,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55665,1.56638,1.56638,0,0,1-.80323.20459h-5.92236a1.56631,1.56631,0,0,1-.80322-.20459,1.51291,1.51291,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M210.69434,288.48621a1.56663,1.56663,0,0,1,.20507-.80323,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57111,1.57111,0,0,1,.80323.20459,1.52168,1.52168,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55665,1.56638,1.56638,0,0,1-.80323.20459h-5.92236a1.56631,1.56631,0,0,1-.80322-.20459,1.51294,1.51294,0,0,1-.55616-.55665,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M216.5957,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51788,1.51788,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56638,1.56638,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M222.49658,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h3.02441a1.571,1.571,0,0,1,.80322.20459,1.51661,1.51661,0,0,1,.55615.55664,1.56653,1.56653,0,0,1,.20508.80323v.021a1.564,1.564,0,0,1-.20508.80322,1.51291,1.51291,0,0,1-.55615.55665,1.56631,1.56631,0,0,1-.80322.20459H224.061a1.56635,1.56635,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M231.79932,288.48621a1.56663,1.56663,0,0,1,.20507-.80323,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57111,1.57111,0,0,1,.80323.20459,1.52168,1.52168,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55665,1.56638,1.56638,0,0,1-.80323.20459h-5.92236a1.56631,1.56631,0,0,1-.80322-.20459,1.51294,1.51294,0,0,1-.55616-.55665,1.56406,1.56406,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M237.7002,288.48621a1.56653,1.56653,0,0,1,.20507-.80323,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459H245.187a1.571,1.571,0,0,1,.80322.20459,1.52163,1.52163,0,0,1,.55665.55664,1.57108,1.57108,0,0,1,.20458.80323v.021a1.5685,1.5685,0,0,1-.20458.80322,1.51791,1.51791,0,0,1-.55665.55665,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56631,1.56631,0,0,1-.80322-.20459,1.51294,1.51294,0,0,1-.55616-.55665,1.564,1.564,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M243.60107,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51781,1.51781,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56638,1.56638,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M249.502,288.48621a1.56653,1.56653,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57108,1.57108,0,0,1,.20459.80323v.021a1.56851,1.56851,0,0,1-.20459.80322,1.51788,1.51788,0,0,1-.55664.55665,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56635,1.56635,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55665,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M47.1748,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h3.02441a1.571,1.571,0,0,1,.80322.20459,1.51657,1.51657,0,0,1,.55616.55664,1.56649,1.56649,0,0,1,.20507.80322v.021a1.564,1.564,0,0,1-.20507.80322,1.513,1.513,0,0,1-.55616.55664,1.56622,1.56622,0,0,1-.80322.20459H48.73926a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M50.17773,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52146,1.52146,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.51785,1.51785,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459H51.74219a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M56.07861,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459H57.64307a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M61.97949,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459H63.544a1.56625,1.56625,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M67.88086,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92237a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459H69.44531a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M73.78174,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57108,1.57108,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56625,1.56625,0,0,1-.80323.20459H75.34619a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M85.98242,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459H87.54688a1.56625,1.56625,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M91.8833,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92237a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459H93.44775a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M97.78418,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h3.02442a1.571,1.571,0,0,1,.80322.20459,1.51661,1.51661,0,0,1,.55615.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.513,1.513,0,0,1-.55615.55664,1.56622,1.56622,0,0,1-.80322.20459H99.34863a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M100.78711,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92237a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92237a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M106.68848,338.10144a1.56659,1.56659,0,0,1,.20507-.80322,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57111,1.57111,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56628,1.56628,0,0,1-.80323.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.51306,1.51306,0,0,1-.55616-.55664,1.56409,1.56409,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M112.58936,338.10144a1.56649,1.56649,0,0,1,.20507-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h3.02441a1.57111,1.57111,0,0,1,.80323.20459,1.51661,1.51661,0,0,1,.55615.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.513,1.513,0,0,1-.55615.55664,1.56628,1.56628,0,0,1-.80323.20459h-3.02441a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.564,1.564,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M115.59229,338.10144a1.56649,1.56649,0,0,1,.20507-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52146,1.52146,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56843,1.56843,0,0,1-.20459.80322,1.51785,1.51785,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.564,1.564,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M121.49316,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459H128.98a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M133.69385,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57108,1.57108,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56625,1.56625,0,0,1-.80323.20459H135.2583a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M139.59473,338.10144a1.56659,1.56659,0,0,1,.20507-.80322,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57111,1.57111,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56628,1.56628,0,0,1-.80323.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.51306,1.51306,0,0,1-.55616-.55664,1.56409,1.56409,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M151.7959,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57108,1.57108,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56625,1.56625,0,0,1-.80323.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M157.69678,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57108,1.57108,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56625,1.56625,0,0,1-.80323.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M163.59766,338.10144a1.56659,1.56659,0,0,1,.20507-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h3.02441a1.57111,1.57111,0,0,1,.80323.20459,1.51661,1.51661,0,0,1,.55615.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.513,1.513,0,0,1-.55615.55664,1.56628,1.56628,0,0,1-.80323.20459h-3.02441a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.56409,1.56409,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M166.60059,338.10144a1.56659,1.56659,0,0,1,.20507-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52156,1.52156,0,0,1,.55665.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55665.55664,1.56622,1.56622,0,0,1-.80322.20459H168.165a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.56409,1.56409,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M172.50146,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52146,1.52146,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56843,1.56843,0,0,1-.20459.80322,1.51785,1.51785,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M178.40234,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459H179.9668a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M184.30371,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h3.02442a1.571,1.571,0,0,1,.80322.20459,1.51654,1.51654,0,0,1,.55615.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.513,1.513,0,0,1-.55615.55664,1.56622,1.56622,0,0,1-.80322.20459h-3.02442a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M187.30664,338.10144a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92237a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92237a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M57.98975,360.932a1.45739,1.45739,0,0,1,.18994-.74561,1.41424,1.41424,0,0,1,.5166-.51709,1.46056,1.46056,0,0,1,.74609-.18994h5.499a1.46056,1.46056,0,0,1,.74609.18994,1.41424,1.41424,0,0,1,.5166.51709,1.45739,1.45739,0,0,1,.18994.74561v.01953a1.45792,1.45792,0,0,1-.18994.74609,1.41284,1.41284,0,0,1-.5166.5166,1.45563,1.45563,0,0,1-.74609.18994h-5.499a1.45563,1.45563,0,0,1-.74609-.18994,1.41284,1.41284,0,0,1-.5166-.5166,1.45792,1.45792,0,0,1-.18994-.74609Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M80.1748,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.57111,1.57111,0,0,1,.20459.80323v.021a1.56857,1.56857,0,0,1-.20459.80323,1.51785,1.51785,0,0,1-.55664.55664,1.56631,1.56631,0,0,1-.80322.20459H81.73926a1.56631,1.56631,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M86.07568,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h3.02441a1.571,1.571,0,0,1,.80322.20459,1.51664,1.51664,0,0,1,.55616.55664,1.56665,1.56665,0,0,1,.20507.80323v.021a1.56412,1.56412,0,0,1-.20507.80323,1.513,1.513,0,0,1-.55616.55664,1.56631,1.56631,0,0,1-.80322.20459H87.64014a1.56638,1.56638,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M89.07861,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h3.02441a1.571,1.571,0,0,1,.80322.20459,1.51672,1.51672,0,0,1,.55616.55664,1.56665,1.56665,0,0,1,.20507.80323v.021a1.56412,1.56412,0,0,1-.20507.80323,1.51306,1.51306,0,0,1-.55616.55664,1.56631,1.56631,0,0,1-.80322.20459H90.64307a1.56638,1.56638,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M92.08154,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57111,1.57111,0,0,1,.20459.80323v.021a1.56857,1.56857,0,0,1-.20459.80323,1.51793,1.51793,0,0,1-.55664.55664,1.56631,1.56631,0,0,1-.80322.20459H93.646a1.56635,1.56635,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M97.98242,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57111,1.57111,0,0,1,.20459.80323v.021a1.56857,1.56857,0,0,1-.20459.80323,1.51793,1.51793,0,0,1-.55664.55664,1.56631,1.56631,0,0,1-.80322.20459H99.54688a1.56635,1.56635,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M103.88379,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92237a1.57107,1.57107,0,0,1,.80322.20459,1.52168,1.52168,0,0,1,.55664.55664,1.57111,1.57111,0,0,1,.20459.80323v.021a1.56857,1.56857,0,0,1-.20459.80323,1.518,1.518,0,0,1-.55664.55664,1.56634,1.56634,0,0,1-.80322.20459h-5.92237a1.56631,1.56631,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M109.78467,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57108,1.57108,0,0,1,.80323.20459,1.52168,1.52168,0,0,1,.55664.55664,1.57111,1.57111,0,0,1,.20459.80323v.021a1.56857,1.56857,0,0,1-.20459.80323,1.518,1.518,0,0,1-.55664.55664,1.56635,1.56635,0,0,1-.80323.20459h-5.92236a1.56631,1.56631,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M115.68555,362.21619a1.56665,1.56665,0,0,1,.20507-.80323,1.51672,1.51672,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h3.02441a1.57108,1.57108,0,0,1,.80323.20459,1.51669,1.51669,0,0,1,.55615.55664,1.56656,1.56656,0,0,1,.20508.80323v.021a1.564,1.564,0,0,1-.20508.80323,1.513,1.513,0,0,1-.55615.55664,1.56635,1.56635,0,0,1-.80323.20459H117.25a1.56631,1.56631,0,0,1-.80322-.20459,1.51306,1.51306,0,0,1-.55616-.55664,1.56412,1.56412,0,0,1-.20507-.80323Z" />
                                                <path
                                                  d="M118.68848,362.21619a1.56665,1.56665,0,0,1,.20507-.80323,1.51672,1.51672,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57111,1.57111,0,0,1,.80323.20459,1.52168,1.52168,0,0,1,.55664.55664,1.57111,1.57111,0,0,1,.20459.80323v.021a1.56857,1.56857,0,0,1-.20459.80323,1.518,1.518,0,0,1-.55664.55664,1.56638,1.56638,0,0,1-.80323.20459h-5.92236a1.56631,1.56631,0,0,1-.80322-.20459,1.51306,1.51306,0,0,1-.55616-.55664,1.56412,1.56412,0,0,1-.20507-.80323Z" />
                                                <path
                                                  d="M124.58936,362.21619a1.56655,1.56655,0,0,1,.20507-.80323,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52163,1.52163,0,0,1,.55665.55664,1.5711,1.5711,0,0,1,.20458.80323v.021a1.56856,1.56856,0,0,1-.20458.80323,1.518,1.518,0,0,1-.55665.55664,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56631,1.56631,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.564,1.564,0,0,1-.20507-.80323Z" />
                                                <path
                                                  d="M157.49561,360.932a1.45739,1.45739,0,0,1,.18994-.74561,1.41424,1.41424,0,0,1,.5166-.51709,1.46056,1.46056,0,0,1,.74609-.18994h5.499a1.46056,1.46056,0,0,1,.74609.18994,1.41431,1.41431,0,0,1,.5166.51709,1.45739,1.45739,0,0,1,.18994.74561v.01953a1.45792,1.45792,0,0,1-.18994.74609,1.41292,1.41292,0,0,1-.5166.5166,1.45563,1.45563,0,0,1-.74609.18994h-5.499a1.45563,1.45563,0,0,1-.74609-.18994,1.41284,1.41284,0,0,1-.5166-.5166,1.45792,1.45792,0,0,1-.18994-.74609Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M179.68066,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h3.02441a1.571,1.571,0,0,1,.80322.20459,1.51664,1.51664,0,0,1,.55616.55664,1.56665,1.56665,0,0,1,.20507.80323v.021a1.56412,1.56412,0,0,1-.20507.80323,1.513,1.513,0,0,1-.55616.55664,1.56631,1.56631,0,0,1-.80322.20459h-3.02441a1.56638,1.56638,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M182.68359,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h3.02441a1.571,1.571,0,0,1,.80322.20459,1.51664,1.51664,0,0,1,.55616.55664,1.56665,1.56665,0,0,1,.20507.80323v.021a1.56412,1.56412,0,0,1-.20507.80323,1.513,1.513,0,0,1-.55616.55664,1.56631,1.56631,0,0,1-.80322.20459h-3.02441a1.56638,1.56638,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M185.68652,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h3.02441a1.571,1.571,0,0,1,.80322.20459,1.51664,1.51664,0,0,1,.55616.55664,1.56665,1.56665,0,0,1,.20507.80323v.021a1.56412,1.56412,0,0,1-.20507.80323,1.513,1.513,0,0,1-.55616.55664,1.56631,1.56631,0,0,1-.80322.20459H187.251a1.56638,1.56638,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M188.68945,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h3.02441a1.571,1.571,0,0,1,.80322.20459,1.51672,1.51672,0,0,1,.55616.55664,1.56665,1.56665,0,0,1,.20507.80323v.021a1.56412,1.56412,0,0,1-.20507.80323,1.51306,1.51306,0,0,1-.55616.55664,1.56631,1.56631,0,0,1-.80322.20459h-3.02441a1.56635,1.56635,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M191.69238,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57111,1.57111,0,0,1,.20459.80323v.021a1.56857,1.56857,0,0,1-.20459.80323,1.51793,1.51793,0,0,1-.55664.55664,1.56631,1.56631,0,0,1-.80322.20459h-5.92236a1.56635,1.56635,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M197.59326,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57107,1.57107,0,0,1,.80322-.20459h5.92237a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57111,1.57111,0,0,1,.20459.80323v.021a1.56857,1.56857,0,0,1-.20459.80323,1.51793,1.51793,0,0,1-.55664.55664,1.56631,1.56631,0,0,1-.80322.20459h-5.92237a1.56634,1.56634,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M257.00146,360.932a1.4574,1.4574,0,0,1,.19-.74561,1.41424,1.41424,0,0,1,.5166-.51709,1.46054,1.46054,0,0,1,.74609-.18994h5.499a1.46058,1.46058,0,0,1,.7461.18994,1.41431,1.41431,0,0,1,.5166.51709,1.45739,1.45739,0,0,1,.18994.74561v.01953a1.45792,1.45792,0,0,1-.18994.74609,1.41292,1.41292,0,0,1-.5166.5166,1.45564,1.45564,0,0,1-.7461.18994h-5.499a1.4556,1.4556,0,0,1-.74609-.18994,1.41284,1.41284,0,0,1-.5166-.5166,1.45793,1.45793,0,0,1-.19-.74609Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M279.187,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57107,1.57107,0,0,1,.80322-.20459h3.02442a1.571,1.571,0,0,1,.80322.20459,1.51661,1.51661,0,0,1,.55615.55664,1.56656,1.56656,0,0,1,.20508.80323v.021a1.564,1.564,0,0,1-.20508.80323,1.513,1.513,0,0,1-.55615.55664,1.56631,1.56631,0,0,1-.80322.20459h-3.02442a1.56634,1.56634,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M282.18994,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.57107,1.57107,0,0,1,.80322-.20459h3.02442a1.571,1.571,0,0,1,.80322.20459,1.51661,1.51661,0,0,1,.55615.55664,1.56656,1.56656,0,0,1,.20508.80323v.021a1.564,1.564,0,0,1-.20508.80323,1.513,1.513,0,0,1-.55615.55664,1.56631,1.56631,0,0,1-.80322.20459h-3.02442a1.56634,1.56634,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M285.19287,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92237a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.57111,1.57111,0,0,1,.20459.80323v.021a1.56857,1.56857,0,0,1-.20459.80323,1.51793,1.51793,0,0,1-.55664.55664,1.56631,1.56631,0,0,1-.80322.20459h-5.92237a1.56631,1.56631,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M291.09375,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h3.02442a1.571,1.571,0,0,1,.80322.20459,1.51669,1.51669,0,0,1,.55615.55664,1.56656,1.56656,0,0,1,.20508.80323v.021a1.564,1.564,0,0,1-.20508.80323,1.513,1.513,0,0,1-.55615.55664,1.56631,1.56631,0,0,1-.80322.20459H292.6582a1.56631,1.56631,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M294.09668,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.51669,1.51669,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459H301.583a1.57161,1.57161,0,0,1,.80371.20459,1.52168,1.52168,0,0,1,.55664.55664,1.566,1.566,0,0,1,.2041.80323v.021a1.56348,1.56348,0,0,1-.2041.80323,1.518,1.518,0,0,1-.55664.55664,1.56689,1.56689,0,0,1-.80371.20459h-5.92188a1.56631,1.56631,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M356.50684,360.932a1.45285,1.45285,0,0,1,.19043-.74561,1.41055,1.41055,0,0,1,.5166-.51709,1.45927,1.45927,0,0,1,.74609-.18994h5.499a1.45933,1.45933,0,0,1,.7461.18994,1.41062,1.41062,0,0,1,.5166.51709,1.453,1.453,0,0,1,.19043.74561v.01953a1.45348,1.45348,0,0,1-.19043.74609,1.40923,1.40923,0,0,1-.5166.5166,1.4544,1.4544,0,0,1-.7461.18994H357.96a1.45434,1.45434,0,0,1-.74609-.18994,1.40916,1.40916,0,0,1-.5166-.5166,1.45338,1.45338,0,0,1-.19043-.74609Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M378.69336,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52168,1.52168,0,0,1,.55664.55664,1.566,1.566,0,0,1,.2041.80323v.021a1.56348,1.56348,0,0,1-.2041.80323,1.518,1.518,0,0,1-.55664.55664,1.56689,1.56689,0,0,1-.80371.20459h-5.92188a1.56325,1.56325,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M384.59473,362.21619a1.56665,1.56665,0,0,1,.20507-.80323,1.52163,1.52163,0,0,1,.55665-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92187a1.57162,1.57162,0,0,1,.80372.20459,1.52168,1.52168,0,0,1,.55664.55664,1.5661,1.5661,0,0,1,.2041.80323v.021a1.56358,1.56358,0,0,1-.2041.80323,1.518,1.518,0,0,1-.55664.55664,1.5669,1.5669,0,0,1-.80372.20459h-5.92187a1.56325,1.56325,0,0,1-.80273-.20459,1.518,1.518,0,0,1-.55665-.55664,1.56412,1.56412,0,0,1-.20507-.80323Z" />
                                                <path
                                                  d="M390.49512,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52168,1.52168,0,0,1,.55664.55664,1.566,1.566,0,0,1,.2041.80323v.021a1.56348,1.56348,0,0,1-.2041.80323,1.518,1.518,0,0,1-.55664.55664,1.56689,1.56689,0,0,1-.80371.20459h-5.92188a1.56325,1.56325,0,0,1-.80273-.20459,1.51785,1.51785,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M396.39648,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.52168,1.52168,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52153,1.52153,0,0,1,.55664.55664,1.566,1.566,0,0,1,.20411.80323v.021a1.56349,1.56349,0,0,1-.20411.80323,1.51785,1.51785,0,0,1-.55664.55664,1.56686,1.56686,0,0,1-.80371.20459h-5.92187a1.56331,1.56331,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M402.29688,362.21619a1.56655,1.56655,0,0,1,.20507-.80323,1.52168,1.52168,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52153,1.52153,0,0,1,.55664.55664,1.566,1.566,0,0,1,.20411.80323v.021a1.56349,1.56349,0,0,1-.20411.80323,1.51785,1.51785,0,0,1-.55664.55664,1.56686,1.56686,0,0,1-.80371.20459h-5.92187a1.56331,1.56331,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20507-.80323Z" />
                                                <path
                                                  d="M456.01367,360.932a1.453,1.453,0,0,1,.19043-.74561,1.41062,1.41062,0,0,1,.5166-.51709,1.45933,1.45933,0,0,1,.7461-.18994h5.499a1.45927,1.45927,0,0,1,.74609.18994,1.41065,1.41065,0,0,1,.51661.51709,1.453,1.453,0,0,1,.19043.74561v.01953a1.45348,1.45348,0,0,1-.19043.74609,1.40926,1.40926,0,0,1-.51661.5166,1.45434,1.45434,0,0,1-.74609.18994h-5.499a1.4544,1.4544,0,0,1-.7461-.18994,1.40923,1.40923,0,0,1-.5166-.5166,1.45348,1.45348,0,0,1-.19043-.74609Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M478.2002,362.21619a1.56655,1.56655,0,0,1,.20507-.80323,1.52168,1.52168,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52163,1.52163,0,0,1,.55665.55664,1.5661,1.5661,0,0,1,.2041.80323v.021a1.56358,1.56358,0,0,1-.2041.80323,1.518,1.518,0,0,1-.55665.55664,1.56686,1.56686,0,0,1-.80371.20459h-5.92187a1.56331,1.56331,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20507-.80323Z" />
                                                <path
                                                  d="M484.10156,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.52168,1.52168,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h3.02441a1.568,1.568,0,0,1,.80273.20459,1.52153,1.52153,0,0,1,.55664.55664,1.56656,1.56656,0,0,1,.20508.80323v.021a1.564,1.564,0,0,1-.20508.80323,1.51785,1.51785,0,0,1-.55664.55664,1.56325,1.56325,0,0,1-.80273.20459H485.666a1.56328,1.56328,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M487.10449,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.52168,1.52168,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.566,1.566,0,0,1,.2041.80323v.021a1.56348,1.56348,0,0,1-.2041.80323,1.51793,1.51793,0,0,1-.55664.55664,1.56686,1.56686,0,0,1-.80371.20459H488.669a1.56328,1.56328,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M493.00488,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.52168,1.52168,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.566,1.566,0,0,1,.2041.80323v.021a1.56348,1.56348,0,0,1-.2041.80323,1.51793,1.51793,0,0,1-.55664.55664,1.56686,1.56686,0,0,1-.80371.20459h-5.92187a1.56328,1.56328,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M498.90625,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52168,1.52168,0,0,1,.55664.55664,1.566,1.566,0,0,1,.2041.80323v.021a1.56348,1.56348,0,0,1-.2041.80323,1.518,1.518,0,0,1-.55664.55664,1.56689,1.56689,0,0,1-.80371.20459H500.4707a1.56325,1.56325,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M504.80664,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h3.02442a1.568,1.568,0,0,1,.80273.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56656,1.56656,0,0,1,.20508.80323v.021a1.564,1.564,0,0,1-.20508.80323,1.51793,1.51793,0,0,1-.55664.55664,1.56325,1.56325,0,0,1-.80273.20459h-3.02442a1.56325,1.56325,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M507.80957,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52168,1.52168,0,0,1,.55664.55664,1.566,1.566,0,0,1,.2041.80323v.021a1.56348,1.56348,0,0,1-.2041.80323,1.518,1.518,0,0,1-.55664.55664,1.56689,1.56689,0,0,1-.80371.20459H509.374a1.56325,1.56325,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M513.71094,362.21619a1.56656,1.56656,0,0,1,.20508-.80323,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52168,1.52168,0,0,1,.55664.55664,1.566,1.566,0,0,1,.2041.80323v.021a1.56348,1.56348,0,0,1-.2041.80323,1.518,1.518,0,0,1-.55664.55664,1.56689,1.56689,0,0,1-.80371.20459h-5.92188a1.56325,1.56325,0,0,1-.80273-.20459,1.51785,1.51785,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80323Z" />
                                                <path
                                                  d="M57.98975,388.79626a1.45738,1.45738,0,0,1,.18994-.7456,1.41424,1.41424,0,0,1,.5166-.51709,1.46047,1.46047,0,0,1,.74609-.18994h5.499a1.46047,1.46047,0,0,1,.74609.18994,1.41424,1.41424,0,0,1,.5166.51709,1.45738,1.45738,0,0,1,.18994.7456v.01954a1.45792,1.45792,0,0,1-.18994.74609,1.41292,1.41292,0,0,1-.5166.5166,1.45573,1.45573,0,0,1-.74609.18994h-5.499a1.45573,1.45573,0,0,1-.74609-.18994,1.41292,1.41292,0,0,1-.5166-.5166,1.45792,1.45792,0,0,1-.18994-.74609Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M80.1748,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h3.02441a1.571,1.571,0,0,1,.80322.20459,1.51657,1.51657,0,0,1,.55616.55664,1.56649,1.56649,0,0,1,.20507.80322v.021a1.564,1.564,0,0,1-.20507.80322,1.513,1.513,0,0,1-.55616.55664,1.56622,1.56622,0,0,1-.80322.20459H81.73926a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M83.17773,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52146,1.52146,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.51785,1.51785,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459H84.74219a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M89.07861,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h3.02441a1.571,1.571,0,0,1,.80322.20459,1.51664,1.51664,0,0,1,.55616.55664,1.56659,1.56659,0,0,1,.20507.80322v.021a1.56409,1.56409,0,0,1-.20507.80322,1.51306,1.51306,0,0,1-.55616.55664,1.56622,1.56622,0,0,1-.80322.20459H90.64307a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M92.08154,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459H93.646a1.56625,1.56625,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M97.98242,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459H99.54688a1.56625,1.56625,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M157.49561,388.79626a1.45738,1.45738,0,0,1,.18994-.7456,1.41424,1.41424,0,0,1,.5166-.51709,1.46047,1.46047,0,0,1,.74609-.18994h5.499a1.46047,1.46047,0,0,1,.74609.18994,1.41431,1.41431,0,0,1,.5166.51709,1.45738,1.45738,0,0,1,.18994.7456v.01954a1.45792,1.45792,0,0,1-.18994.74609,1.413,1.413,0,0,1-.5166.5166,1.45573,1.45573,0,0,1-.74609.18994h-5.499a1.45573,1.45573,0,0,1-.74609-.18994,1.41292,1.41292,0,0,1-.5166-.5166,1.45792,1.45792,0,0,1-.18994-.74609Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M179.68066,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57111,1.57111,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56628,1.56628,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M185.58154,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459H187.146a1.56625,1.56625,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M191.48242,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57108,1.57108,0,0,1,.80323-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56625,1.56625,0,0,1-.80323-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M197.3833,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92237a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92237a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M203.28467,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57108,1.57108,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56625,1.56625,0,0,1-.80323.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M209.18555,390.08142a1.56659,1.56659,0,0,1,.20507-.80322,1.51664,1.51664,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.57111,1.57111,0,0,1,.80323.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56628,1.56628,0,0,1-.80323.20459H210.75a1.56622,1.56622,0,0,1-.80322-.20459,1.51306,1.51306,0,0,1-.55616-.55664,1.56409,1.56409,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M215.08643,390.08142a1.56659,1.56659,0,0,1,.20507-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92236a1.571,1.571,0,0,1,.80322.20459,1.52156,1.52156,0,0,1,.55665.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55665.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92236a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.56409,1.56409,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M220.9873,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.51657,1.51657,0,0,1,.55616-.55664,1.571,1.571,0,0,1,.80322-.20459h3.02441a1.571,1.571,0,0,1,.80322.20459,1.51657,1.51657,0,0,1,.55616.55664,1.56649,1.56649,0,0,1,.20507.80322v.021a1.564,1.564,0,0,1-.20507.80322,1.513,1.513,0,0,1-.55616.55664,1.56622,1.56622,0,0,1-.80322.20459h-3.02441a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55616-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M257.00146,388.79626a1.45739,1.45739,0,0,1,.19-.7456,1.41424,1.41424,0,0,1,.5166-.51709,1.46044,1.46044,0,0,1,.74609-.18994h5.499a1.46048,1.46048,0,0,1,.7461.18994,1.41431,1.41431,0,0,1,.5166.51709,1.45738,1.45738,0,0,1,.18994.7456v.01954a1.45792,1.45792,0,0,1-.18994.74609,1.413,1.413,0,0,1-.5166.5166,1.45574,1.45574,0,0,1-.7461.18994h-5.499a1.4557,1.4557,0,0,1-.74609-.18994,1.41292,1.41292,0,0,1-.5166-.5166,1.45793,1.45793,0,0,1-.19-.74609Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M279.187,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.57107,1.57107,0,0,1,.80322-.20459h5.92237a1.571,1.571,0,0,1,.80322.20459,1.52153,1.52153,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92237a1.56624,1.56624,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M285.08789,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.51661,1.51661,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92237a1.571,1.571,0,0,1,.80322.20459,1.52161,1.52161,0,0,1,.55664.55664,1.571,1.571,0,0,1,.20459.80322v.021a1.56853,1.56853,0,0,1-.20459.80322,1.518,1.518,0,0,1-.55664.55664,1.56622,1.56622,0,0,1-.80322.20459h-5.92237a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M290.98877,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.51654,1.51654,0,0,1,.55615-.55664,1.571,1.571,0,0,1,.80322-.20459h5.92139a1.57524,1.57524,0,0,1,.80469.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56538,1.56538,0,0,1,.20312.80322v.021a1.5629,1.5629,0,0,1-.20312.80322,1.518,1.518,0,0,1-.55664.55664,1.5704,1.5704,0,0,1-.80469.20459h-5.92139a1.56622,1.56622,0,0,1-.80322-.20459,1.513,1.513,0,0,1-.55615-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M296.88965,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.507,1.507,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459H304.376a1.57161,1.57161,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56345,1.56345,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459H298.4541a1.56315,1.56315,0,0,1-.80273-.20459,1.50347,1.50347,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M302.791,390.08142a1.56659,1.56659,0,0,1,.20507-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52156,1.52156,0,0,1,.55665.55664,1.566,1.566,0,0,1,.2041.80322v.021a1.56354,1.56354,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55665.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.56409,1.56409,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M308.69141,390.08142a1.56659,1.56659,0,0,1,.20507-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57162,1.57162,0,0,1,.80372.20459,1.52161,1.52161,0,0,1,.55664.55664,1.566,1.566,0,0,1,.2041.80322v.021a1.56354,1.56354,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.5668,1.5668,0,0,1-.80372.20459h-5.92187a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.56409,1.56409,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M314.59277,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h3.02441a1.568,1.568,0,0,1,.80274.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56659,1.56659,0,0,1,.20507.80322v.021a1.56409,1.56409,0,0,1-.20507.80322,1.518,1.518,0,0,1-.55664.55664,1.56321,1.56321,0,0,1-.80274.20459h-3.02441a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M317.5957,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h3.02441a1.568,1.568,0,0,1,.80273.20459,1.52156,1.52156,0,0,1,.55665.55664,1.56659,1.56659,0,0,1,.20507.80322v.021a1.56409,1.56409,0,0,1-.20507.80322,1.518,1.518,0,0,1-.55665.55664,1.56315,1.56315,0,0,1-.80273.20459h-3.02441a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M320.59863,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h3.02441a1.568,1.568,0,0,1,.80273.20459,1.52156,1.52156,0,0,1,.55665.55664,1.56659,1.56659,0,0,1,.20507.80322v.021a1.56409,1.56409,0,0,1-.20507.80322,1.518,1.518,0,0,1-.55665.55664,1.56315,1.56315,0,0,1-.80273.20459h-3.02441a1.56319,1.56319,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M323.60156,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h3.02441a1.568,1.568,0,0,1,.80273.20459,1.52146,1.52146,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.51785,1.51785,0,0,1-.55664.55664,1.56315,1.56315,0,0,1-.80273.20459H325.166a1.56319,1.56319,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M326.60449,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52153,1.52153,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56345,1.56345,0,0,1-.2041.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459H328.169a1.56319,1.56319,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M356.50684,388.79626a1.45284,1.45284,0,0,1,.19043-.7456,1.41055,1.41055,0,0,1,.5166-.51709,1.45917,1.45917,0,0,1,.74609-.18994h5.499a1.45923,1.45923,0,0,1,.7461.18994,1.41062,1.41062,0,0,1,.5166.51709,1.45294,1.45294,0,0,1,.19043.7456v.01954a1.45348,1.45348,0,0,1-.19043.74609,1.40931,1.40931,0,0,1-.5166.5166,1.4545,1.4545,0,0,1-.7461.18994H357.96a1.45444,1.45444,0,0,1-.74609-.18994,1.40923,1.40923,0,0,1-.5166-.5166,1.45338,1.45338,0,0,1-.19043-.74609Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M378.69336,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h3.02442a1.568,1.568,0,0,1,.80273.20459,1.52153,1.52153,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56315,1.56315,0,0,1-.80273.20459h-3.02442a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M381.69629,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56345,1.56345,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459h-5.92188a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M387.59766,390.08142a1.56659,1.56659,0,0,1,.20507-.80322,1.52156,1.52156,0,0,1,.55665-.55664,1.568,1.568,0,0,1,.80273-.20459H395.084a1.57162,1.57162,0,0,1,.80372.20459,1.52161,1.52161,0,0,1,.55664.55664,1.566,1.566,0,0,1,.2041.80322v.021a1.56354,1.56354,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.5668,1.5668,0,0,1-.80372.20459h-5.92187a1.56315,1.56315,0,0,1-.80273-.20459,1.518,1.518,0,0,1-.55665-.55664,1.56409,1.56409,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M393.49805,390.08142a1.56659,1.56659,0,0,1,.20507-.80322,1.52156,1.52156,0,0,1,.55665-.55664,1.568,1.568,0,0,1,.80273-.20459h3.02441a1.568,1.568,0,0,1,.80274.20459,1.52161,1.52161,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.518,1.518,0,0,1-.55664.55664,1.56319,1.56319,0,0,1-.80274.20459H395.0625a1.56315,1.56315,0,0,1-.80273-.20459,1.518,1.518,0,0,1-.55665-.55664,1.56409,1.56409,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M396.501,390.08142a1.56659,1.56659,0,0,1,.20507-.80322,1.52156,1.52156,0,0,1,.55665-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92187a1.57162,1.57162,0,0,1,.80372.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56345,1.56345,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.5668,1.5668,0,0,1-.80372.20459h-5.92187a1.56315,1.56315,0,0,1-.80273-.20459,1.518,1.518,0,0,1-.55665-.55664,1.56409,1.56409,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M402.40234,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52153,1.52153,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56345,1.56345,0,0,1-.2041.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459H403.9668a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M456.01367,388.79626a1.45294,1.45294,0,0,1,.19043-.7456,1.41062,1.41062,0,0,1,.5166-.51709,1.45923,1.45923,0,0,1,.7461-.18994h5.499a1.45917,1.45917,0,0,1,.74609.18994,1.41065,1.41065,0,0,1,.51661.51709,1.45294,1.45294,0,0,1,.19043.7456v.01954a1.45348,1.45348,0,0,1-.19043.74609,1.40934,1.40934,0,0,1-.51661.5166,1.45444,1.45444,0,0,1-.74609.18994h-5.499a1.4545,1.4545,0,0,1-.7461-.18994,1.40931,1.40931,0,0,1-.5166-.5166,1.45348,1.45348,0,0,1-.19043-.74609Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M478.2002,390.08142a1.56649,1.56649,0,0,1,.20507-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52156,1.52156,0,0,1,.55665.55664,1.566,1.566,0,0,1,.2041.80322v.021a1.56354,1.56354,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55665.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56321,1.56321,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20507-.80322Z" />
                                                <path
                                                  d="M484.10156,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52153,1.52153,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56345,1.56345,0,0,1-.2041.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459H485.666a1.56319,1.56319,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M490.002,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.52161,1.52161,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80274-.20459h5.92187a1.57159,1.57159,0,0,1,.80371.20459,1.52153,1.52153,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56345,1.56345,0,0,1-.2041.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56676,1.56676,0,0,1-.80371.20459h-5.92187a1.56319,1.56319,0,0,1-.80274-.20459,1.518,1.518,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M495.90332,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h5.92188a1.57161,1.57161,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56345,1.56345,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459h-5.92188a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M501.80371,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h3.02442a1.568,1.568,0,0,1,.80273.20459,1.52153,1.52153,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.51793,1.51793,0,0,1-.55664.55664,1.56315,1.56315,0,0,1-.80273.20459h-3.02442a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M504.80664,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.52153,1.52153,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459H512.293a1.57161,1.57161,0,0,1,.80371.20459,1.52161,1.52161,0,0,1,.55664.55664,1.56594,1.56594,0,0,1,.2041.80322v.021a1.56345,1.56345,0,0,1-.2041.80322,1.518,1.518,0,0,1-.55664.55664,1.56679,1.56679,0,0,1-.80371.20459h-5.92188a1.56315,1.56315,0,0,1-.80273-.20459,1.51793,1.51793,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <path
                                                  d="M510.708,390.08142a1.5665,1.5665,0,0,1,.20508-.80322,1.52146,1.52146,0,0,1,.55664-.55664,1.568,1.568,0,0,1,.80273-.20459h3.02442a1.568,1.568,0,0,1,.80273.20459,1.52161,1.52161,0,0,1,.55664.55664,1.5665,1.5665,0,0,1,.20508.80322v.021a1.564,1.564,0,0,1-.20508.80322,1.518,1.518,0,0,1-.55664.55664,1.56317,1.56317,0,0,1-.80273.20459h-3.02442a1.56315,1.56315,0,0,1-.80273-.20459,1.51785,1.51785,0,0,1-.55664-.55664,1.564,1.564,0,0,1-.20508-.80322Z" />
                                                <g style={{ clipPath: `url(#u)` }}>
                                                  <line x1="546.53003" y1="312.23999" x2="48.75" y2="312.23999"
                                                    style={{ fill: 'none', stroke: '#e5e5e5', strokeMiterlimit: 10, strokeWidth: '0.75px' }} />
                                                </g>
                                                <path d="M557.257,264.273a5.6173,5.6173,0,0,0-3.977-1.648H42a5.61712,5.61712,0,0,0-3.977,1.648"
                                                  style={{ fill: 'none', stroke: '#e5e5e5', strokeMiterlimit: 10, strokeWidth: '0.75px' }} />
                                                <path
                                                  d="M38.023,417.437A5.62135,5.62135,0,0,0,42,419.084H552.905a6.25475,6.25475,0,0,0,2.528-.428,5.64836,5.64836,0,0,0,1.824-1.219"
                                                  style={{ fill: 'none', stroke: '#e5e5e5', strokeMiterlimit: 10, strokeWidth: '0.75px' }} />
                                                <path
                                                  d="M38.023,264.273a5.61658,5.61658,0,0,0-1.648,3.977V413.084a6.24845,6.24845,0,0,0,.428,2.528,5.64945,5.64945,0,0,0,1.22,1.825"
                                                  style={{ fill: 'none', stroke: '#e5e5e5', strokeMiterlimit: 10, strokeWidth: '0.75px' }} />
                                                <path
                                                  d="M557.257,417.437a5.6218,5.6218,0,0,0,1.648-3.978V268.625a6.2558,6.2558,0,0,0-.428-2.528,5.63458,5.63458,0,0,0-1.22-1.824"
                                                  style={{ fill: 'none', stroke: '#e5e5e5', strokeMiterlimit: 10, strokeWidth: '0.75px' }} />
                                                <path
                                                  d="M45.6748,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51664,1.51664,0,0,1,.55616-.55664,1.56594,1.56594,0,0,1,.80322-.2041h3.02441a1.56594,1.56594,0,0,1,.80322.2041,1.51664,1.51664,0,0,1,.55616.55664,1.56457,1.56457,0,0,1,.20507.80372v.0205a1.567,1.567,0,0,1-.20507.80371,1.50669,1.50669,0,0,1-.55616.55567,1.5665,1.5665,0,0,1-.80322.20508H47.23926a1.5665,1.5665,0,0,1-.80322-.20508,1.50669,1.50669,0,0,1-.55616-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M48.67773,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51669,1.51669,0,0,1,.55615-.55664,1.566,1.566,0,0,1,.80323-.2041h5.92236a1.56594,1.56594,0,0,1,.80322.2041,1.52153,1.52153,0,0,1,.55664.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.51152,1.51152,0,0,1-.55664.55567,1.5665,1.5665,0,0,1-.80322.20508H50.24219a1.56656,1.56656,0,0,1-.80323-.20508,1.50674,1.50674,0,0,1-.55615-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M54.57861,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51669,1.51669,0,0,1,.55615-.55664,1.566,1.566,0,0,1,.80323-.2041h5.92236a1.56594,1.56594,0,0,1,.80322.2041,1.52161,1.52161,0,0,1,.55664.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.5116,1.5116,0,0,1-.55664.55567,1.5665,1.5665,0,0,1-.80322.20508H56.14307a1.56656,1.56656,0,0,1-.80323-.20508,1.50674,1.50674,0,0,1-.55615-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M60.47949,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51669,1.51669,0,0,1,.55615-.55664,1.566,1.566,0,0,1,.80323-.2041h5.92236a1.56594,1.56594,0,0,1,.80322.2041,1.52161,1.52161,0,0,1,.55664.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.5116,1.5116,0,0,1-.55664.55567,1.5665,1.5665,0,0,1-.80322.20508H62.044a1.56653,1.56653,0,0,1-.80323-.20508,1.50674,1.50674,0,0,1-.55615-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M66.38086,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51661,1.51661,0,0,1,.55615-.55664,1.56594,1.56594,0,0,1,.80322-.2041h5.92237a1.56594,1.56594,0,0,1,.80322.2041,1.52168,1.52168,0,0,1,.55664.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.51167,1.51167,0,0,1-.55664.55567,1.5665,1.5665,0,0,1-.80322.20508H67.94531a1.5665,1.5665,0,0,1-.80322-.20508,1.50666,1.50666,0,0,1-.55615-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M72.28174,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51661,1.51661,0,0,1,.55615-.55664,1.56594,1.56594,0,0,1,.80322-.2041h5.92236a1.566,1.566,0,0,1,.80323.2041,1.52168,1.52168,0,0,1,.55664.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.51167,1.51167,0,0,1-.55664.55567,1.56653,1.56653,0,0,1-.80323.20508H73.84619a1.5665,1.5665,0,0,1-.80322-.20508,1.50666,1.50666,0,0,1-.55615-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M84.48242,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51669,1.51669,0,0,1,.55615-.55664,1.566,1.566,0,0,1,.80323-.2041h5.92236a1.56594,1.56594,0,0,1,.80322.2041,1.52161,1.52161,0,0,1,.55664.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.5116,1.5116,0,0,1-.55664.55567,1.5665,1.5665,0,0,1-.80322.20508H86.04688a1.56653,1.56653,0,0,1-.80323-.20508,1.50674,1.50674,0,0,1-.55615-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M90.3833,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51669,1.51669,0,0,1,.55615-.55664,1.56594,1.56594,0,0,1,.80322-.2041h5.92237a1.56594,1.56594,0,0,1,.80322.2041,1.52168,1.52168,0,0,1,.55664.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.51167,1.51167,0,0,1-.55664.55567,1.5665,1.5665,0,0,1-.80322.20508H91.94775a1.5665,1.5665,0,0,1-.80322-.20508,1.50674,1.50674,0,0,1-.55615-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M96.28418,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51669,1.51669,0,0,1,.55615-.55664,1.56594,1.56594,0,0,1,.80322-.2041h3.02442a1.56594,1.56594,0,0,1,.80322.2041,1.51669,1.51669,0,0,1,.55615.55664,1.56458,1.56458,0,0,1,.20508.80372v.0205a1.567,1.567,0,0,1-.20508.80371,1.50674,1.50674,0,0,1-.55615.55567,1.5665,1.5665,0,0,1-.80322.20508H97.84863a1.5665,1.5665,0,0,1-.80322-.20508,1.50674,1.50674,0,0,1-.55615-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M99.28711,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51661,1.51661,0,0,1,.55615-.55664,1.56594,1.56594,0,0,1,.80322-.2041h5.92237a1.56594,1.56594,0,0,1,.80322.2041,1.52168,1.52168,0,0,1,.55664.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.51167,1.51167,0,0,1-.55664.55567,1.5665,1.5665,0,0,1-.80322.20508h-5.92237a1.5665,1.5665,0,0,1-.80322-.20508,1.50666,1.50666,0,0,1-.55615-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M105.18848,451.69666a1.56467,1.56467,0,0,1,.20507-.80372,1.51672,1.51672,0,0,1,.55616-.55664,1.56594,1.56594,0,0,1,.80322-.2041h5.92236a1.566,1.566,0,0,1,.80323.2041,1.52168,1.52168,0,0,1,.55664.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.51167,1.51167,0,0,1-.55664.55567,1.56656,1.56656,0,0,1-.80323.20508h-5.92236a1.5665,1.5665,0,0,1-.80322-.20508,1.50676,1.50676,0,0,1-.55616-.55567,1.56713,1.56713,0,0,1-.20507-.80371Z" />
                                                <path
                                                  d="M111.08936,451.69666a1.56457,1.56457,0,0,1,.20507-.80372,1.51664,1.51664,0,0,1,.55616-.55664,1.56594,1.56594,0,0,1,.80322-.2041h3.02441a1.566,1.566,0,0,1,.80323.2041,1.51669,1.51669,0,0,1,.55615.55664,1.56458,1.56458,0,0,1,.20508.80372v.0205a1.567,1.567,0,0,1-.20508.80371,1.50674,1.50674,0,0,1-.55615.55567,1.56656,1.56656,0,0,1-.80323.20508h-3.02441a1.5665,1.5665,0,0,1-.80322-.20508,1.50669,1.50669,0,0,1-.55616-.55567,1.567,1.567,0,0,1-.20507-.80371Z" />
                                                <path
                                                  d="M114.09229,451.69666a1.56457,1.56457,0,0,1,.20507-.80372,1.51664,1.51664,0,0,1,.55616-.55664,1.56594,1.56594,0,0,1,.80322-.2041h5.92236a1.56594,1.56594,0,0,1,.80322.2041,1.52153,1.52153,0,0,1,.55664.55664,1.569,1.569,0,0,1,.20459.80372v.0205a1.57149,1.57149,0,0,1-.20459.80371,1.51152,1.51152,0,0,1-.55664.55567,1.5665,1.5665,0,0,1-.80322.20508h-5.92236a1.5665,1.5665,0,0,1-.80322-.20508,1.50669,1.50669,0,0,1-.55616-.55567,1.567,1.567,0,0,1-.20507-.80371Z" />
                                                <path
                                                  d="M119.99316,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51669,1.51669,0,0,1,.55615-.55664,1.566,1.566,0,0,1,.80323-.2041H127.48a1.56594,1.56594,0,0,1,.80322.2041,1.52161,1.52161,0,0,1,.55664.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.5116,1.5116,0,0,1-.55664.55567,1.5665,1.5665,0,0,1-.80322.20508h-5.92236a1.56656,1.56656,0,0,1-.80323-.20508,1.50674,1.50674,0,0,1-.55615-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M132.19385,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51661,1.51661,0,0,1,.55615-.55664,1.56594,1.56594,0,0,1,.80322-.2041h5.92236a1.566,1.566,0,0,1,.80323.2041,1.52168,1.52168,0,0,1,.55664.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.51167,1.51167,0,0,1-.55664.55567,1.56653,1.56653,0,0,1-.80323.20508H133.7583a1.5665,1.5665,0,0,1-.80322-.20508,1.50666,1.50666,0,0,1-.55615-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M138.09473,451.69666a1.56467,1.56467,0,0,1,.20507-.80372,1.51672,1.51672,0,0,1,.55616-.55664,1.56594,1.56594,0,0,1,.80322-.2041h5.92236a1.566,1.566,0,0,1,.80323.2041,1.52168,1.52168,0,0,1,.55664.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.51167,1.51167,0,0,1-.55664.55567,1.56656,1.56656,0,0,1-.80323.20508h-5.92236a1.5665,1.5665,0,0,1-.80322-.20508,1.50676,1.50676,0,0,1-.55616-.55567,1.56713,1.56713,0,0,1-.20507-.80371Z" />
                                                <path
                                                  d="M150.2959,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51661,1.51661,0,0,1,.55615-.55664,1.56594,1.56594,0,0,1,.80322-.2041h3.02442a1.566,1.566,0,0,1,.80322.2041,1.51669,1.51669,0,0,1,.55615.55664,1.56458,1.56458,0,0,1,.20508.80372v.0205a1.567,1.567,0,0,1-.20508.80371,1.50674,1.50674,0,0,1-.55615.55567,1.56652,1.56652,0,0,1-.80322.20508h-3.02442a1.5665,1.5665,0,0,1-.80322-.20508,1.50666,1.50666,0,0,1-.55615-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M153.29883,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51661,1.51661,0,0,1,.55615-.55664,1.56594,1.56594,0,0,1,.80322-.2041h5.92236a1.566,1.566,0,0,1,.80323.2041,1.52168,1.52168,0,0,1,.55664.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.51167,1.51167,0,0,1-.55664.55567,1.56653,1.56653,0,0,1-.80323.20508h-5.92236a1.5665,1.5665,0,0,1-.80322-.20508,1.50666,1.50666,0,0,1-.55615-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M159.19971,451.69666a1.56458,1.56458,0,0,1,.20508-.80372,1.51661,1.51661,0,0,1,.55615-.55664,1.56594,1.56594,0,0,1,.80322-.2041h5.92236a1.566,1.566,0,0,1,.80323.2041,1.52168,1.52168,0,0,1,.55664.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.51167,1.51167,0,0,1-.55664.55567,1.56656,1.56656,0,0,1-.80323.20508h-5.92236a1.5665,1.5665,0,0,1-.80322-.20508,1.50666,1.50666,0,0,1-.55615-.55567,1.567,1.567,0,0,1-.20508-.80371Z" />
                                                <path
                                                  d="M165.10059,451.69666a1.56467,1.56467,0,0,1,.20507-.80372,1.51664,1.51664,0,0,1,.55616-.55664,1.56594,1.56594,0,0,1,.80322-.2041h5.92236a1.56594,1.56594,0,0,1,.80322.2041,1.52163,1.52163,0,0,1,.55665.55664,1.56911,1.56911,0,0,1,.20459.80372v.0205a1.57159,1.57159,0,0,1-.20459.80371,1.51162,1.51162,0,0,1-.55665.55567,1.5665,1.5665,0,0,1-.80322.20508H166.665a1.5665,1.5665,0,0,1-.80322-.20508,1.50669,1.50669,0,0,1-.55616-.55567,1.56713,1.56713,0,0,1-.20507-.80371Z" />
                                                <path
                                                  d="M56.48975,474.5238a1.45919,1.45919,0,0,1,.18994-.74609,1.41292,1.41292,0,0,1,.5166-.5166,1.45545,1.45545,0,0,1,.74609-.18945h5.499a1.45545,1.45545,0,0,1,.74609.18945,1.41292,1.41292,0,0,1,.5166.5166,1.45919,1.45919,0,0,1,.18994.74609v.01953a1.45921,1.45921,0,0,1-.18994.7461,1.41292,1.41292,0,0,1-.5166.5166,1.456,1.456,0,0,1-.74609.19043h-5.499a1.456,1.456,0,0,1-.74609-.19043,1.41292,1.41292,0,0,1-.5166-.5166,1.45921,1.45921,0,0,1-.18994-.7461Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M78.7876,475.2738a1.45919,1.45919,0,0,1,.18994-.74609,1.413,1.413,0,0,1,.5166-.5166,1.45545,1.45545,0,0,1,.74609-.18945h5.499a1.45545,1.45545,0,0,1,.74609.18945,1.41292,1.41292,0,0,1,.5166.5166,1.45919,1.45919,0,0,1,.18994.74609v.01953a1.45921,1.45921,0,0,1-.18994.7461,1.41292,1.41292,0,0,1-.5166.5166,1.456,1.456,0,0,1-.74609.19043h-5.499a1.456,1.456,0,0,1-.74609-.19043,1.413,1.413,0,0,1-.5166-.5166,1.45921,1.45921,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M84.26709,475.2738a1.45929,1.45929,0,0,1,.18994-.74609,1.413,1.413,0,0,1,.5166-.5166,1.45546,1.45546,0,0,1,.7461-.18945h2.8081a1.45242,1.45242,0,0,1,.74561.18945,1.4107,1.4107,0,0,1,.51709.5166,1.45929,1.45929,0,0,1,.18994.74609v.01953a1.4593,1.4593,0,0,1-.18994.7461,1.4107,1.4107,0,0,1-.51709.5166,1.453,1.453,0,0,1-.74561.19043h-2.8081a1.456,1.456,0,0,1-.7461-.19043,1.413,1.413,0,0,1-.5166-.5166,1.4593,1.4593,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M87.05566,475.2738a1.4592,1.4592,0,0,1,.18995-.74609,1.41292,1.41292,0,0,1,.5166-.5166,1.45542,1.45542,0,0,1,.74609-.18945h5.499a1.45546,1.45546,0,0,1,.7461.18945,1.413,1.413,0,0,1,.5166.5166,1.45929,1.45929,0,0,1,.18994.74609v.01953a1.4593,1.4593,0,0,1-.18994.7461,1.413,1.413,0,0,1-.5166.5166,1.456,1.456,0,0,1-.7461.19043h-5.499a1.456,1.456,0,0,1-.74609-.19043,1.41292,1.41292,0,0,1-.5166-.5166,1.45921,1.45921,0,0,1-.18995-.7461Z" />
                                                <path
                                                  d="M92.53516,475.2738a1.45919,1.45919,0,0,1,.18994-.74609,1.41292,1.41292,0,0,1,.5166-.5166,1.45545,1.45545,0,0,1,.74609-.18945H96.7959a1.45236,1.45236,0,0,1,.7456.18945,1.41055,1.41055,0,0,1,.51709.5166,1.4592,1.4592,0,0,1,.19.74609v.01953a1.45921,1.45921,0,0,1-.19.7461,1.41055,1.41055,0,0,1-.51709.5166,1.45291,1.45291,0,0,1-.7456.19043H93.98779a1.456,1.456,0,0,1-.74609-.19043,1.41292,1.41292,0,0,1-.5166-.5166,1.45921,1.45921,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M95.32324,475.2738a1.45929,1.45929,0,0,1,.18994-.74609,1.413,1.413,0,0,1,.51661-.5166,1.45542,1.45542,0,0,1,.74609-.18945h5.499a1.45549,1.45549,0,0,1,.7461.18945,1.41307,1.41307,0,0,1,.5166.5166,1.45929,1.45929,0,0,1,.18994.74609v.01953a1.4593,1.4593,0,0,1-.18994.7461,1.41307,1.41307,0,0,1-.5166.5166,1.456,1.456,0,0,1-.7461.19043h-5.499a1.456,1.456,0,0,1-.74609-.19043,1.413,1.413,0,0,1-.51661-.5166,1.4593,1.4593,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M100.80273,475.2738a1.4592,1.4592,0,0,1,.18995-.74609,1.41292,1.41292,0,0,1,.5166-.5166,1.45542,1.45542,0,0,1,.74609-.18945h5.499a1.45546,1.45546,0,0,1,.7461.18945,1.413,1.413,0,0,1,.5166.5166,1.45929,1.45929,0,0,1,.18994.74609v.01953a1.4593,1.4593,0,0,1-.18994.7461,1.413,1.413,0,0,1-.5166.5166,1.456,1.456,0,0,1-.7461.19043h-5.499a1.456,1.456,0,0,1-.74609-.19043,1.41292,1.41292,0,0,1-.5166-.5166,1.45921,1.45921,0,0,1-.18995-.7461Z" />
                                                <path
                                                  d="M156.5957,474.5238a1.45929,1.45929,0,0,1,.18994-.74609,1.413,1.413,0,0,1,.51661-.5166,1.45542,1.45542,0,0,1,.74609-.18945h5.499a1.45546,1.45546,0,0,1,.7461.18945,1.413,1.413,0,0,1,.5166.5166A1.45929,1.45929,0,0,1,165,474.5238v.01953a1.4593,1.4593,0,0,1-.18994.7461,1.413,1.413,0,0,1-.5166.5166,1.456,1.456,0,0,1-.7461.19043h-5.499a1.456,1.456,0,0,1-.74609-.19043,1.413,1.413,0,0,1-.51661-.5166,1.4593,1.4593,0,0,1-.18994-.7461Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M178.89355,475.2738a1.4592,1.4592,0,0,1,.18995-.74609,1.41292,1.41292,0,0,1,.5166-.5166,1.45542,1.45542,0,0,1,.74609-.18945h5.499a1.45546,1.45546,0,0,1,.7461.18945,1.413,1.413,0,0,1,.5166.5166,1.45919,1.45919,0,0,1,.18994.74609v.01953a1.45921,1.45921,0,0,1-.18994.7461,1.413,1.413,0,0,1-.5166.5166,1.456,1.456,0,0,1-.7461.19043h-5.499a1.456,1.456,0,0,1-.74609-.19043,1.41292,1.41292,0,0,1-.5166-.5166,1.45921,1.45921,0,0,1-.18995-.7461Z" />
                                                <path
                                                  d="M184.37305,475.2738a1.45919,1.45919,0,0,1,.18994-.74609,1.41292,1.41292,0,0,1,.5166-.5166,1.45545,1.45545,0,0,1,.74609-.18945h5.499a1.45545,1.45545,0,0,1,.74609.18945,1.41292,1.41292,0,0,1,.5166.5166,1.45919,1.45919,0,0,1,.18994.74609v.01953a1.45921,1.45921,0,0,1-.18994.7461,1.41292,1.41292,0,0,1-.5166.5166,1.456,1.456,0,0,1-.74609.19043h-5.499a1.456,1.456,0,0,1-.74609-.19043,1.41292,1.41292,0,0,1-.5166-.5166,1.45921,1.45921,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M189.85254,475.2738a1.45929,1.45929,0,0,1,.18994-.74609,1.413,1.413,0,0,1,.5166-.5166,1.45546,1.45546,0,0,1,.7461-.18945h5.499a1.45542,1.45542,0,0,1,.74609.18945,1.41292,1.41292,0,0,1,.5166.5166,1.4592,1.4592,0,0,1,.19.74609v.01953a1.45921,1.45921,0,0,1-.19.7461,1.41292,1.41292,0,0,1-.5166.5166,1.456,1.456,0,0,1-.74609.19043h-5.499a1.456,1.456,0,0,1-.7461-.19043,1.413,1.413,0,0,1-.5166-.5166,1.4593,1.4593,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M195.332,475.2738a1.45929,1.45929,0,0,1,.18994-.74609,1.41307,1.41307,0,0,1,.5166-.5166,1.45549,1.45549,0,0,1,.7461-.18945h5.499a1.45549,1.45549,0,0,1,.7461.18945,1.41307,1.41307,0,0,1,.5166.5166,1.45929,1.45929,0,0,1,.18994.74609v.01953a1.4593,1.4593,0,0,1-.18994.7461,1.41307,1.41307,0,0,1-.5166.5166,1.456,1.456,0,0,1-.7461.19043h-5.499a1.456,1.456,0,0,1-.7461-.19043,1.41307,1.41307,0,0,1-.5166-.5166,1.4593,1.4593,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M200.81152,475.2738a1.45929,1.45929,0,0,1,.18994-.74609,1.413,1.413,0,0,1,.51661-.5166,1.45542,1.45542,0,0,1,.74609-.18945h2.80811a1.45238,1.45238,0,0,1,.7456.18945,1.41062,1.41062,0,0,1,.51709.5166,1.45919,1.45919,0,0,1,.18994.74609v.01953a1.45921,1.45921,0,0,1-.18994.7461,1.41062,1.41062,0,0,1-.51709.5166,1.45294,1.45294,0,0,1-.7456.19043h-2.80811a1.456,1.456,0,0,1-.74609-.19043,1.413,1.413,0,0,1-.51661-.5166,1.4593,1.4593,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M203.6001,475.2738a1.45919,1.45919,0,0,1,.18994-.74609,1.413,1.413,0,0,1,.5166-.5166,1.45545,1.45545,0,0,1,.74609-.18945h2.80811a1.45242,1.45242,0,0,1,.74561.18945,1.4107,1.4107,0,0,1,.51709.5166,1.45929,1.45929,0,0,1,.18994.74609v.01953a1.4593,1.4593,0,0,1-.18994.7461,1.4107,1.4107,0,0,1-.51709.5166,1.453,1.453,0,0,1-.74561.19043h-2.80811a1.456,1.456,0,0,1-.74609-.19043,1.413,1.413,0,0,1-.5166-.5166,1.45921,1.45921,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M206.38818,475.2738a1.45929,1.45929,0,0,1,.18994-.74609,1.413,1.413,0,0,1,.51661-.5166,1.45542,1.45542,0,0,1,.74609-.18945h2.80811a1.45238,1.45238,0,0,1,.7456.18945,1.41062,1.41062,0,0,1,.51709.5166,1.45919,1.45919,0,0,1,.18994.74609v.01953a1.45921,1.45921,0,0,1-.18994.7461,1.41062,1.41062,0,0,1-.51709.5166,1.45294,1.45294,0,0,1-.7456.19043h-2.80811a1.456,1.456,0,0,1-.74609-.19043,1.413,1.413,0,0,1-.51661-.5166,1.4593,1.4593,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M209.17676,475.2738a1.45919,1.45919,0,0,1,.18994-.74609,1.413,1.413,0,0,1,.5166-.5166,1.45545,1.45545,0,0,1,.74609-.18945h5.499a1.45545,1.45545,0,0,1,.74609.18945,1.41292,1.41292,0,0,1,.5166.5166,1.45919,1.45919,0,0,1,.18994.74609v.01953a1.45921,1.45921,0,0,1-.18994.7461,1.41292,1.41292,0,0,1-.5166.5166,1.456,1.456,0,0,1-.74609.19043h-5.499a1.456,1.456,0,0,1-.74609-.19043,1.413,1.413,0,0,1-.5166-.5166,1.45921,1.45921,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M214.65625,475.2738a1.45929,1.45929,0,0,1,.18994-.74609,1.413,1.413,0,0,1,.5166-.5166,1.45546,1.45546,0,0,1,.7461-.18945h5.499a1.45542,1.45542,0,0,1,.74609.18945,1.413,1.413,0,0,1,.51661.5166,1.45929,1.45929,0,0,1,.18994.74609v.01953a1.4593,1.4593,0,0,1-.18994.7461,1.413,1.413,0,0,1-.51661.5166,1.456,1.456,0,0,1-.74609.19043h-5.499a1.456,1.456,0,0,1-.7461-.19043,1.413,1.413,0,0,1-.5166-.5166,1.4593,1.4593,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M256.70166,474.5238a1.45929,1.45929,0,0,1,.18994-.74609,1.413,1.413,0,0,1,.5166-.5166,1.45546,1.45546,0,0,1,.7461-.18945h5.499a1.45542,1.45542,0,0,1,.74609.18945,1.413,1.413,0,0,1,.51661.5166,1.45929,1.45929,0,0,1,.18994.74609v.01953a1.4593,1.4593,0,0,1-.18994.7461,1.413,1.413,0,0,1-.51661.5166,1.456,1.456,0,0,1-.74609.19043h-5.499a1.456,1.456,0,0,1-.7461-.19043,1.413,1.413,0,0,1-.5166-.5166,1.4593,1.4593,0,0,1-.18994-.7461Z"
                                                  style={{ fill: '#ffffff' }} />
                                                <path
                                                  d="M278.99951,475.2738a1.45929,1.45929,0,0,1,.18994-.74609,1.41307,1.41307,0,0,1,.5166-.5166,1.45549,1.45549,0,0,1,.7461-.18945h5.499a1.45549,1.45549,0,0,1,.7461.18945,1.41307,1.41307,0,0,1,.5166.5166,1.45929,1.45929,0,0,1,.18994.74609v.01953a1.4593,1.4593,0,0,1-.18994.7461,1.41307,1.41307,0,0,1-.5166.5166,1.456,1.456,0,0,1-.7461.19043h-5.499a1.456,1.456,0,0,1-.7461-.19043,1.41307,1.41307,0,0,1-.5166-.5166,1.4593,1.4593,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M284.479,475.2738a1.4592,1.4592,0,0,1,.19-.74609,1.41292,1.41292,0,0,1,.5166-.5166,1.45542,1.45542,0,0,1,.74609-.18945h5.499a1.45546,1.45546,0,0,1,.7461.18945,1.413,1.413,0,0,1,.5166.5166,1.45929,1.45929,0,0,1,.18994.74609v.01953a1.4593,1.4593,0,0,1-.18994.7461,1.413,1.413,0,0,1-.5166.5166,1.456,1.456,0,0,1-.7461.19043h-5.499a1.456,1.456,0,0,1-.74609-.19043,1.41292,1.41292,0,0,1-.5166-.5166,1.45921,1.45921,0,0,1-.19-.7461Z" />
                                                <path
                                                  d="M289.9585,475.2738a1.45919,1.45919,0,0,1,.18994-.74609,1.41292,1.41292,0,0,1,.5166-.5166,1.45545,1.45545,0,0,1,.74609-.18945h2.80811a1.45236,1.45236,0,0,1,.7456.18945,1.41055,1.41055,0,0,1,.51709.5166,1.4592,1.4592,0,0,1,.19.74609v.01953a1.45921,1.45921,0,0,1-.19.7461,1.41055,1.41055,0,0,1-.51709.5166,1.45291,1.45291,0,0,1-.7456.19043h-2.80811a1.456,1.456,0,0,1-.74609-.19043,1.41292,1.41292,0,0,1-.5166-.5166,1.45921,1.45921,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M292.74707,475.2738a1.45929,1.45929,0,0,1,.18994-.74609,1.413,1.413,0,0,1,.5166-.5166,1.45549,1.45549,0,0,1,.7461-.18945h5.49951a1.45668,1.45668,0,0,1,.74609.18945,1.41409,1.41409,0,0,1,.51563.5166,1.4553,1.4553,0,0,1,.1914.74609v.01953a1.45531,1.45531,0,0,1-.1914.7461,1.41409,1.41409,0,0,1-.51563.5166,1.45725,1.45725,0,0,1-.74609.19043h-5.49951a1.456,1.456,0,0,1-.7461-.19043,1.413,1.413,0,0,1-.5166-.5166,1.4593,1.4593,0,0,1-.18994-.7461Z" />
                                                <path
                                                  d="M298.22559,475.2738a1.45465,1.45465,0,0,1,.19043-.74609,1.40923,1.40923,0,0,1,.5166-.5166,1.45416,1.45416,0,0,1,.74609-.18945h2.80762a1.45167,1.45167,0,0,1,.74609.18945,1.40193,1.40193,0,0,1,.5166.5166,1.45465,1.45465,0,0,1,.19043.74609v.01953a1.45466,1.45466,0,0,1-.19043.7461,1.40193,1.40193,0,0,1-.5166.5166,1.45222,1.45222,0,0,1-.74609.19043h-2.80762a1.45472,1.45472,0,0,1-.74609-.19043,1.40923,1.40923,0,0,1-.5166-.5166,1.45466,1.45466,0,0,1-.19043-.7461Z" />
                                                <path
                                                  d="M557.257,428.982a5.62127,5.62127,0,0,0-3.977-1.647H42a5.62108,5.62108,0,0,0-3.977,1.647"
                                                  style={{ fill: 'none', stroke: '#e5e5e5', strokeMiterlimit: 10, strokeWidth: '0.75px' }} />
                                                <path
                                                  d="M38.023,500.639A5.62146,5.62146,0,0,0,42,502.286H552.905a6.25474,6.25474,0,0,0,2.528-.428,5.64769,5.64769,0,0,0,1.824-1.219"
                                                  style={{ fill: 'none', stroke: '#e5e5e5', strokeMiterlimit: 10, strokeWidth: '0.75px' }} />
                                                <path
                                                  d="M38.023,428.982a5.62126,5.62126,0,0,0-1.648,3.978v63.326a6.24874,6.24874,0,0,0,.428,2.528,5.64975,5.64975,0,0,0,1.22,1.825"
                                                  style={{ fill: 'none', stroke: '#e5e5e5', strokeMiterlimit: 10, strokeWidth: '0.75px' }} />
                                                <path
                                                  d="M557.257,500.639a5.62166,5.62166,0,0,0,1.648-3.978V433.335a6.24883,6.24883,0,0,0-.428-2.528,5.64913,5.64913,0,0,0-1.22-1.825"
                                                  style={{ fill: 'none', stroke: '#e5e5e5', strokeMiterlimit: 10, strokeWidth: '0.75px' }} />
                                                <rect width="595.28003" height="522.32167" style={{ fill: 'none' }} />
                                                <path
                                                  d="M276.7627,150.80017a3.136,3.136,0,0,1,.40918-1.60644,3.03817,3.03817,0,0,1,1.11328-1.1128,3.13627,3.13627,0,0,1,1.60644-.40967h11.84424a3.13633,3.13633,0,0,1,1.60645.40967,3.03326,3.03326,0,0,1,1.11279,1.1128,3.13627,3.13627,0,0,1,.40967,1.60644v.042a3.1356,3.1356,0,0,1-.40967,1.60694,3.02965,3.02965,0,0,1-1.11279,1.11279,3.13633,3.13633,0,0,1-1.60645.40967H279.8916a3.13627,3.13627,0,0,1-1.60644-.40967,3.03454,3.03454,0,0,1-1.11328-1.11279,3.13533,3.13533,0,0,1-.40918-1.60694Z"
                                                  style={{ fill: '#0b380b' }} />
                                                <path
                                                  d="M288.56445,150.80017a3.136,3.136,0,0,1,.40918-1.60644,3.03824,3.03824,0,0,1,1.11328-1.1128,3.13633,3.13633,0,0,1,1.60645-.40967h11.84375a3.14369,3.14369,0,0,1,1.60742.40967,3.03817,3.03817,0,0,1,1.11328,1.1128,3.14988,3.14988,0,0,1,.40821,1.60644v.042a3.14921,3.14921,0,0,1-.40821,1.60694,3.03454,3.03454,0,0,1-1.11328,1.11279,3.14369,3.14369,0,0,1-1.60742.40967H291.69336a3.13633,3.13633,0,0,1-1.60645-.40967,3.03462,3.03462,0,0,1-1.11328-1.11279,3.13533,3.13533,0,0,1-.40918-1.60694Z"
                                                  style={{ fill: '#0b380b' }} />
                                                <path
                                                  d="M300.36719,150.80017a3.13117,3.13117,0,0,1,.40918-1.60644,3.03817,3.03817,0,0,1,1.11328-1.1128,3.13142,3.13142,0,0,1,1.60547-.40967h11.84472a3.13757,3.13757,0,0,1,1.60645.40967,3.03824,3.03824,0,0,1,1.11328,1.1128,3.14082,3.14082,0,0,1,.40918,1.60644v.042a3.14015,3.14015,0,0,1-.40918,1.60694,3.03462,3.03462,0,0,1-1.11328,1.11279,3.13757,3.13757,0,0,1-1.60645.40967H303.49512a3.13142,3.13142,0,0,1-1.60547-.40967,3.03454,3.03454,0,0,1-1.11328-1.11279,3.13052,3.13052,0,0,1-.40918-1.60694Z"
                                                  style={{ fill: '#0b380b' }} />
                                                <path
                                                  d="M312.168,150.80017a3.13182,3.13182,0,0,1,.41015-1.60644,3.02839,3.02839,0,0,1,1.11231-1.1128,3.13757,3.13757,0,0,1,1.60645-.40967H327.1416a3.1376,3.1376,0,0,1,1.60645.40967,3.03824,3.03824,0,0,1,1.11328,1.1128,3.14082,3.14082,0,0,1,.40918,1.60644v.042a3.14015,3.14015,0,0,1-.40918,1.60694,3.03462,3.03462,0,0,1-1.11328,1.11279,3.1376,3.1376,0,0,1-1.60645.40967H315.29688a3.13757,3.13757,0,0,1-1.60645-.40967,3.02479,3.02479,0,0,1-1.11231-1.11279,3.13116,3.13116,0,0,1-.41015-1.60694Z"
                                                  style={{ fill: '#0b380b' }} />
                                                <path
                                                  d="M323.96973,150.80017a3.13182,3.13182,0,0,1,.41015-1.60644,3.02831,3.02831,0,0,1,1.11231-1.1128,3.13753,3.13753,0,0,1,1.60644-.40967h6.04883a3.1376,3.1376,0,0,1,1.60645.40967,3.02829,3.02829,0,0,1,1.1123,1.1128,3.13173,3.13173,0,0,1,.41016,1.60644v.042a3.13107,3.13107,0,0,1-.41016,1.60694,3.02468,3.02468,0,0,1-1.1123,1.11279,3.1376,3.1376,0,0,1-1.60645.40967h-6.04883a3.13753,3.13753,0,0,1-1.60644-.40967,3.02471,3.02471,0,0,1-1.11231-1.11279,3.13116,3.13116,0,0,1-.41015-1.60694Z"
                                                  style={{ fill: '#0b380b' }} />
                                                <path
                                                  d="M329.97559,150.80017a3.13172,3.13172,0,0,1,.41015-1.60644,3.02831,3.02831,0,0,1,1.11231-1.1128,3.13753,3.13753,0,0,1,1.60644-.40967h6.04883a3.1376,3.1376,0,0,1,1.60645.40967,3.02829,3.02829,0,0,1,1.1123,1.1128,3.13173,3.13173,0,0,1,.41016,1.60644v.042a3.13107,3.13107,0,0,1-.41016,1.60694,3.02468,3.02468,0,0,1-1.1123,1.11279,3.1376,3.1376,0,0,1-1.60645.40967h-6.04883a3.13753,3.13753,0,0,1-1.60644-.40967,3.02471,3.02471,0,0,1-1.11231-1.11279,3.13106,3.13106,0,0,1-.41015-1.60694Z"
                                                  style={{ fill: '#0b380b' }} />
                                                <path
                                                  d="M335.98145,150.80017a3.13172,3.13172,0,0,1,.41015-1.60644,3.02831,3.02831,0,0,1,1.11231-1.1128,3.13753,3.13753,0,0,1,1.60644-.40967h11.84473a3.13753,3.13753,0,0,1,1.60644.40967,3.03817,3.03817,0,0,1,1.11328,1.1128,3.14072,3.14072,0,0,1,.40918,1.60644v.042a3.14006,3.14006,0,0,1-.40918,1.60694,3.03454,3.03454,0,0,1-1.11328,1.11279,3.13753,3.13753,0,0,1-1.60644.40967H339.11035a3.13753,3.13753,0,0,1-1.60644-.40967,3.02471,3.02471,0,0,1-1.11231-1.11279,3.13106,3.13106,0,0,1-.41015-1.60694Z"
                                                  style={{ fill: '#0b380b' }} />
                                                <path
                                                  d="M347.7832,150.80017a3.13173,3.13173,0,0,1,.41016-1.60644,3.02829,3.02829,0,0,1,1.1123-1.1128,3.1376,3.1376,0,0,1,1.60645-.40967h11.84473a3.13756,3.13756,0,0,1,1.60644.40967,3.03824,3.03824,0,0,1,1.11328,1.1128,3.14082,3.14082,0,0,1,.40918,1.60644v.042a3.14015,3.14015,0,0,1-.40918,1.60694,3.03462,3.03462,0,0,1-1.11328,1.11279,3.13756,3.13756,0,0,1-1.60644.40967H350.91211a3.1376,3.1376,0,0,1-1.60645-.40967,3.02468,3.02468,0,0,1-1.1123-1.11279,3.13107,3.13107,0,0,1-.41016-1.60694Z"
                                                  style={{ fill: '#0b380b' }} />
                                                <path
                                                  d="M359.58594,150.80017a3.13182,3.13182,0,0,1,.41015-1.60644,3.02831,3.02831,0,0,1,1.11231-1.1128,3.13753,3.13753,0,0,1,1.60644-.40967h6.04883a3.1376,3.1376,0,0,1,1.60645.40967,3.02829,3.02829,0,0,1,1.1123,1.1128,3.13173,3.13173,0,0,1,.41016,1.60644v.042a3.13107,3.13107,0,0,1-.41016,1.60694,3.02468,3.02468,0,0,1-1.1123,1.11279,3.1376,3.1376,0,0,1-1.60645.40967h-6.04883a3.13753,3.13753,0,0,1-1.60644-.40967,3.02471,3.02471,0,0,1-1.11231-1.11279,3.13116,3.13116,0,0,1-.41015-1.60694Z"
                                                  style={{ fill: '#0b380b' }} />
                                                <circle cx="244.74886" cy="151.03159" r="21.36151" style={{ fill: '#cee7cf' }} />
                                                <path
                                                  d="M244.7487,156.71618a2.77019,2.77019,0,1,0,2.77019,2.77014A2.77329,2.77329,0,0,0,244.7487,156.71618Z"
                                                  style={{ fill: '#0b380b' }} />
                                                <path
                                                  d="M247.51758,141.10393a3.616,3.616,0,0,0-6.33067,2.949c.5504,3.14474,1.32715,7.58313,1.67574,9.57644a1.9171,1.9171,0,0,0,3.77206.00009l1.67609-9.57652a3.60777,3.60777,0,0,0-.79322-2.949Z"
                                                  style={{ fill: '#0b380b' }} />
                                              </svg>

                                            </>
                                          ) : (
                                            <>
                                              <img src={pdfIcon02} alt='' />
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Modal.Body>
                                </Modal>

                                <Modal show={showManualAuditForm} onHide={handleHideManualAuditFormPopup} centered>
                                  {quoteFormSuccess ? (
                                    <>
                                      <Modal.Header className='pdf-report-popup-title' closeButton></Modal.Header>
                                    </>
                                  ) : (
                                    <>
                                      <Modal.Header className='p-4' closeButton>
                                        <h2 className='h4 mb-0'>Complete the form to get a quote</h2>
                                      </Modal.Header>
                                    </>
                                  )}
                                  <Modal.Body className='p-4'>
                                    {quoteFormSuccess ? (
                                      <>
                                        <div className='text-center'>
                                          <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_2689_663585)">
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M44 0C19.7313 0 0 19.7313 0 44C0 68.2688 19.7313 88 44 88C68.2688 88 88 68.2688 88 44C88 19.7313 68.2688 0 44 0Z" fill="#23825A"></path>
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M65.2773 29.1672C66.343 30.2328 66.343 31.986 65.2773 33.0516L39.4961 58.8328C38.9633 59.3656 38.2586 59.6407 37.5539 59.6407C36.8492 59.6407 36.1445 59.3656 35.6117 58.8328L22.7211 45.9422C21.6555 44.8766 21.6555 43.1235 22.7211 42.0578C23.7867 40.9922 25.5398 40.9922 26.6055 42.0578L37.5539 53.0063L61.393 29.1672C62.4586 28.0844 64.2117 28.0844 65.2773 29.1672Z" fill="white"></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2689_663585">
                                                <rect width="88" height="88" fill="white"></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                          <div className='mt-3'>
                                            <h2>Thank You!</h2>
                                            <p className='mb-0'>We’ll get back to you very soon with pricing or with questions what we’ll have and with a timeline</p>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {quoteFormError && (
                                          <Alert onClose={() => setQuoteFormError(false)} variant='danger' dismissible>{quoteFormErrorMsg}</Alert>
                                        )}
                                        <Form>
                                          <Form.Group className="mb-3" controlId="">
                                            <Form.Label><span>Your name</span><span className='text-danger fs-5 ms-1'>*</span></Form.Label>
                                            <Form.Control type="text" placeholder="eg. John Doe" autoFocus defaultValue={quoteUserFullName} onChange={(e) => setQuoteUserFullName(e.target.value)} />
                                          </Form.Group>
                                          <Form.Group className="mb-3" controlId="" >
                                            <Form.Label><span>Choose requirement type</span> <span className='text-danger fs-5 ms-1'>*</span></Form.Label>
                                            <Form.Select aria-label="Default select example" defaultValue={quoteProjectType} onChange={(e) => setQuoteProjectType(e.target.value)}>
                                              <option value="1">Expert Manual WCAG 2.1 &amp; 2.2 Website Audit</option>
                                              <option value="2">File Accessibility Audit and Remediation</option>
                                              <option value="3">Media Accessibility Audit and Remediation</option>
                                              <option value="4">VPAT &amp; ACR Testing and Certification</option>
                                            </Form.Select>
                                          </Form.Group>
                                          <Form.Group className="mb-3" controlId="">
                                            <Form.Label><span>Your email</span> <span className='text-danger fs-5 ms-1'>*</span></Form.Label>
                                            <Form.Control type="email" placeholder="example@mail.com" defaultValue={quoteUserEmail} onChange={(e) => setQuoteUserEmail(e.target.value)} />
                                          </Form.Group>
                                          <Form.Group className="mb-3" controlId="">
                                            <Form.Label><span>Tell us a bit more about requirement</span><span className='text-danger fs-5 ms-1'>*</span></Form.Label>
                                            <Form.Control as="textarea" rows={3} placeholder='Describe your requirement here' defaultValue={quoteUserMsg} onChange={(e) => setQuoteUserMsg(e.target.value)} />
                                          </Form.Group>
                                          <h3 className='h5'>Add the requirement links or files</h3>
                                          <div className='add-link-doc-wrap'>
                                            <div className='link-element-group'>
                                              {inputs.map((input) => (
                                                <Form.Control className='mb-1' key={input.id} id={input.id} value={input.value} onChange={handleChange} type="url" placeholder="example.com" />
                                              ))}
                                            </div>
                                            <div className='file-element-group'>
                                              {fileInputs && fileInputs.length > 0 && fileInputs.map((input) => (
                                                <div className='file-element-group-element'>
                                                  <div className='file-element-group-element-wrap'>
                                                    <span>{input.file[0].name}</span>
                                                    <span className='text-danger' onClick={() => handleFileInputRemove(input.id)}>X</span>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                            {quoteProjectType !== '1' && (
                                              <>
                                                <div className='add-link-doc-wrap-btn mt-2'>
                                                  <Button variant='secondary' size='sm' onClick={addInput}>Add one more link</Button>
                                                  <div className='ms-3 btn btn-secondary btn-sm file-btn'>
                                                    <Form.Control type="file" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileInputChange(e)} />
                                                    <span>Upload project files</span>
                                                  </div>
                                                </div>
                                              </>
                                            )}


                                          </div>
                                          <Button className='mb-0 mt-4 w-100 fs-5' size='lg' type='button' variant="primary" onClick={quoteFormSubmit}>Get a quote</Button>
                                        </Form>
                                      </>
                                    )}

                                  </Modal.Body>
                                </Modal>
                              </div>
                            </div>
                          </Container>
                        </>
                      )}
                    </>
                  )}

                </div>

              </MainTemplate >

            

        </>
      )}
    </>
  )

}

export default Home;