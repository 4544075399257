import { useSelector } from 'react-redux';
import tableListIcn from '../../Assets/img/icn-table-list.svg';
import { groupDataMainPropTypes } from '../../PropTypes/ScanningScreenPropType';
import { StoreProptypes } from '../../PropTypes/StoreProptypes';
import { Accordion, Button, Col, OverlayTrigger, Placeholder, ProgressBar, Row, Tooltip } from 'react-bootstrap';
import ViolationTagList from '../ViolationTag';
import ViolationsGroupListPlaceholder from '../Placeholder/ViolationsGroupList';
import { useEffect, useState } from 'react';
import ElementsListMain from './ElementsList';

interface finalGropTotalScorePropType {
    [keyname: string]: number;
}

interface propType {
    violationsResult: boolean,
    violationsGroupList: groupDataMainPropTypes,
    handleShowPDFReportFormPopup: Function,
    finalGroupAccessibilityScore: finalGropTotalScorePropType,
    violationsGroupListFilter: Function,
    filterType: number
}

const AccessibilityViolationsList = (prop: propType) => {

    const { violationsResult, violationsGroupList, handleShowPDFReportFormPopup, finalGroupAccessibilityScore, violationsGroupListFilter, filterType } = prop;

    const { isWidget, isLoading, websiteURL } = useSelector((store: StoreProptypes) => store);

    const [websiteURLPeram, setWebsiteURLPeram] = useState<string>('');

    useEffect(() => {
        if (websiteURL !== null) {
            setWebsiteURLPeram(`https://ada.skynettechnologies.us/trial-subscription?website=${websiteURL.split('/')[0]}`)
        } else {
            setWebsiteURLPeram(`https://ada.skynettechnologies.us/trial-subscription`)
        }
    }, [websiteURL]);


    return (
        <>
            <div className='violations-group-list'>
                {isLoading ? (
                    <>
                        <ViolationsGroupListPlaceholder />
                    </>
                ) : (
                    <>
                        {filterType > 0 && (
                            <>
                                <div className='filter-element'>
                                    <div className='filter-name h4 mb-0 text-danger'>
                                        {filterType === 1 ? `Critical Issues` : filterType === 2 ? `Passed audits` : filterType === 3 ? `Required Manual Audits` : `Not Applicable`}
                                    </div>
                                    <Button variant="danger" onClick={() => violationsGroupListFilter(0)}>Clear Filter</Button>
                                </div>
                            </>
                        )}


                        {violationsGroupList ? (
                            <>
                                {Object.keys(violationsGroupList).map((keyname, index) => {
                                    return (
                                        <>
                                            {violationsGroupList[keyname].length > 0 ? (
                                                <>
                                                    <div className='violations-group-box custom-box-shadow border violations-card' key={index}>
                                                        <div className='violations-group-title'>
                                                            <h2 className='h4 mb-0'>{keyname}</h2>
                                                            {finalGroupAccessibilityScore.hasOwnProperty(keyname) ? (
                                                                <>
                                                                    <div className={`group-score ${finalGroupAccessibilityScore[keyname] > 0 ? (finalGroupAccessibilityScore[keyname] > 85 ? `success` : finalGroupAccessibilityScore[keyname] > 49 ? `warning` : `error`) : `error`}`}>Score: {finalGroupAccessibilityScore[keyname].toFixed(0)}</div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={`group-score neutral`}>Neutral</div>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className='violations-list'>
                                                            <Accordion alwaysOpen>
                                                                {violationsGroupList[keyname].map((violation, index) => {

                                                                    let accessibilityScore = violation.total_success > 0 ? ((violation.total_success * 100) / (violation.total_success + violation.total_error)) : 0;

                                                                    return (
                                                                        <>
                                                                            <Accordion.Item
                                                                                eventKey={`${violation.id}`}
                                                                                className={`${violation.is_critical_issues === 1 ? `critical_issues` : ``} ${violation.is_passed_audits === 1 ? `passed_audits` : ``} ${violation.is_required_manual_audits === 1 ? `required_manual_audits` : ``} ${violation.is_not_applicable === 1 ? `not_applicable` : ``}`}
                                                                            >
                                                                                <Accordion.Header>
                                                                                    <div className='violations-result-icon'>
                                                                                        {(violation.total_success > 0 || violation.total_error > 0) ? (
                                                                                            <>
                                                                                                {accessibilityScore > 85 ? (
                                                                                                    <>
                                                                                                        <svg fill="none" viewBox="0 0 64 65">
                                                                                                            <circle cx="32" cy="32.5" r="32" fill="#cee7cf" transform="rotate(180 32 32.5)"></circle>
                                                                                                            <path stroke="#1f5c21" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="m20.788 34.41 6.017 6.018 16.41-16.41"></path>
                                                                                                        </svg>
                                                                                                    </>
                                                                                                ) : accessibilityScore > 49 ? (
                                                                                                    <>
                                                                                                        <svg fill="none" viewBox="0 0 64 64">
                                                                                                            <circle cx="32" cy="32" r="32" fill="#ffebd2" transform="rotate(180 32 32)"></circle>
                                                                                                            <path fill="#fb8c00" d="m37.043 14.4-1.638 25.242h-4.967L28.8 14.4h8.243Zm-8.14 32.666c0-2.253 1.791-4.096 3.993-4.096a4.108 4.108 0 0 1 4.096 4.096c0 2.253-1.843 4.045-4.096 4.045-2.202 0-3.994-1.792-3.994-4.045Z"></path>
                                                                                                        </svg>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <svg fill="none" viewBox="0 0 64 64">
                                                                                                            <circle cx="32" cy="32" r="32" fill="#fae2e2" transform="rotate(180 32 32)"></circle>
                                                                                                            <path stroke="#9f0000" strokeLinecap="round" strokeWidth="4" d="m22.404 22.402 19.2 19.2m-19.2.001 19.2-19.2"></path>
                                                                                                        </svg>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <circle cx="12" cy="12" r="12" fill="#E3E5E8"></circle>
                                                                                                    <path d="M12 17.25C14.8995 17.25 17.25 14.8995 17.25 12C17.25 9.10051 14.8995 6.75 12 6.75C9.10051 6.75 6.75 9.10051 6.75 12C6.75 14.8995 9.10051 17.25 12 17.25Z" stroke="#566578" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"></path>
                                                                                                </svg>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className='violations-title'>{violation.message_readable}</div>
                                                                                    <ViolationTagList
                                                                                        motor={violation.motor_impaired === 1 ? true : false}
                                                                                        vision={violation.visually_impaired === 1 ? true : false}
                                                                                        cognitive={violation.cognitive_disabilities === 1 ? true : false}
                                                                                    />
                                                                                </Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    <Row>
                                                                                        <Col lg={5} xl={4} className='mb-4 mb-lg-0'>
                                                                                            <div className='violation-details-sidebar custom-box-shadow border'>

                                                                                                <div className='violation-progressbar'>
                                                                                                    {(violation.total_success > 0 || violation.total_error > 0) ? (
                                                                                                        <>
                                                                                                            <div className='h6 mb-0'>Total Elements {violation.total_error + violation.total_success}</div>
                                                                                                            <div className='progressbar-list'>
                                                                                                                <div className='error-progressbar progressbar-box'>
                                                                                                                    <span>Failures</span>
                                                                                                                    <div className='progressbar-count'>
                                                                                                                        <ProgressBar now={violation.total_error > 0 ? ((violation.total_error * 100) / (violation.total_error + violation.total_success)) : 0} variant='danger' />
                                                                                                                        <span>{violation.total_error}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='success-progressbar progressbar-box'>
                                                                                                                    <span>Successes</span>
                                                                                                                    <div className='progressbar-count'>
                                                                                                                        <ProgressBar now={violation.total_success > 0 ? ((violation.total_success * 100) / (violation.total_error + violation.total_success)) : 0} variant='success' />
                                                                                                                        <span>{violation.total_success}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <div className='h6 mb-2'>Neutral Score</div>
                                                                                                            <span className='neutral-text'>These elements are either missing or irrelevant, making the requirement not applicable.</span>
                                                                                                        </>
                                                                                                    )}

                                                                                                </div>

                                                                                                <div className='sidebar-violation-tag-list border-top'>
                                                                                                    <div className='h6 mb-0'>Who is affected by these issues?</div>
                                                                                                    <ViolationTagList
                                                                                                        showList={true}
                                                                                                        motor={violation.motor_impaired === 1 ? true : false}
                                                                                                        vision={violation.visually_impaired === 1 ? true : false}
                                                                                                        cognitive={violation.cognitive_disabilities === 1 ? true : false}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col lg={7} xl={8}>
                                                                                            <div className='violation-details custom-box-shadow border'>
                                                                                                <div className='violation-requirement'>
                                                                                                    <h2 className='h6'>Requirement :</h2>
                                                                                                    <p>{violation.requirement}</p>
                                                                                                </div>
                                                                                                <div className='violation-element'>
                                                                                                    {violation.total_error > 0 ? (
                                                                                                        <>
                                                                                                            <div className='violation-element-list error-violation'>
                                                                                                                <h3 className='h6'>{violation.total_error} Failed Elements</h3>
                                                                                                                {violation.error_details && violation.error_details.length > 0 && (
                                                                                                                    <>

                                                                                                                        <ElementsListMain element={violation.error_details} />


                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>

                                                                                                        </>
                                                                                                    )}

                                                                                                    {violation.total_success > 0 ? (
                                                                                                        <>
                                                                                                            <div className='violation-element-list success-violation'>
                                                                                                                <h3 className='h6'>{violation.total_success} Successful Elements</h3>
                                                                                                                {violation.success_details && violation.success_details.length > 0 && (
                                                                                                                    <>

                                                                                                                        <ElementsListMain element={violation.success_details} />


                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>

                                                                                                        </>
                                                                                                    )}

                                                                                                </div>
                                                                                                <div className='violation-pdf-report'> Want to see all the elements and full details? <a href='javascript:void(0)' onClick={() => handleShowPDFReportFormPopup()} role='button' >Get the free PDF report to your email</a></div>
                                                                                                {((violation.total_success > 0 || violation.total_error > 0) && !isWidget) ? (
                                                                                                    <>
                                                                                                        {violation.total_error > 0 ? (
                                                                                                            <>
                                                                                                                <div className='violation-ctc'>
                                                                                                                    <div className="cts-box">
                                                                                                                        <div className="cts-box-text">
                                                                                                                            <h2 className="h5 mb-1">Fix your website</h2>
                                                                                                                            <p className="mb-0">Achieve accessibility and compliance within 2 minutes of installation!</p>
                                                                                                                        </div>
                                                                                                                        <div className="cts-box-btn">
                                                                                                                            <a href={websiteURLPeram} className="btn btn-primary">Fix issues</a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>

                                                                                                            </>
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>

                                                                                                    </>
                                                                                                )}

                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        </>
                                                                    )
                                                                })}
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>

                                                </>
                                            )}

                                        </>
                                    )
                                })}
                            </>
                        ) : (
                            <>

                            </>
                        )}

                    </>
                )}



            </div>
        </>
    )
}

export default AccessibilityViolationsList;