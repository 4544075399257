
const LOADING = "LOADING";
const SET_MESSAGE = "SET_MESSAGE";
const CLEAR_MESSAGE = "CLEAR_MESSAGE";
const WEBSITE_URL = "WEBSITE_URL";
const WEBSITE_SCANNING_PROCESS = "WEBSITE_SCANNING_PROCESS";
const WEBSITE_SCANNING_PROCESS_PER = "WEBSITE_SCANNING_PROCESS_PER";
const WEBSITE_RESCANNING_PROCESS = "WEBSITE_RESCANNING_PROCESS";
const WEBSITE_SCREENSHOT = "WEBSITE_SCREENSHOT";
const WEBSITE_WIDGET = "WEBSITE_WIDGET";
export {
    LOADING,
    SET_MESSAGE,
    CLEAR_MESSAGE,
    WEBSITE_URL,
    WEBSITE_SCANNING_PROCESS,
    WEBSITE_SCANNING_PROCESS_PER,
    WEBSITE_RESCANNING_PROCESS,
    WEBSITE_SCREENSHOT,
    WEBSITE_WIDGET
};