import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routesList } from './Routes';
import ErrorPage from './Pages/ErrorPage';
import ToastMessage from "./Components/ToastMessage";

function App() {

  const helmetContext = {};

  return (

    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <>
          <Routes>
            {routesList.map(
              (route) => {
                let index = (route.path === '/' ? true : false);
                return (
                  <Route
                    index={index}
                    path={route.path}
                    element={route.element}
                    key={route.path}
                  />
                )
              }
            )}

            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <ToastMessage />
        </>
      </BrowserRouter>
    </HelmetProvider >

  );
}

export default App;
