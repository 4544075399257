import PageHeaderMain from "../Components/Header"

import "../Assets/scss/base.scss";
import { Container } from "react-bootstrap";

interface PropTypes {
    children: any
}

const MainTemplate = (prop: PropTypes) => {

    const { children } = prop;

    return (
        <>
            <div className="page-main">
                {children}
            </div>
        </>
    )
}

export default MainTemplate;