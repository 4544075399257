import { useSelector } from 'react-redux';
import screenMockupPlaceholder from '../../Assets/img/placeholder.svg';
import { StoreProptypes } from '../../PropTypes/StoreProptypes';
import { addDefaultImgSrc } from '../../Helpers/addDefaultImgSrc';
import { useEffect, useState } from 'react';
import { Button, Placeholder } from 'react-bootstrap';

interface PropType {
    accessibilityScore: number,
    accessibilitySystemDetected: string,
    handleShowManualAuditFormPopup: Function
}

const WebsiteScreen = (prop: PropType) => {

    const { accessibilityScore, accessibilitySystemDetected, handleShowManualAuditFormPopup } = prop;

    const { screenShot, isLoading, scaningProcessPer, websiteURL, isWidget } = useSelector((store: StoreProptypes) => store);

    const [blurValue, setBlurValue] = useState(2.5);

    useEffect(() => {
        if (scaningProcessPer) {
            //console.info("Opt ==>", (2.5/scaningProcessPer))
            if (scaningProcessPer === 100) {
                setBlurValue(0);
            } else {
                setBlurValue(blurValue - 0.2);
            }

        }
    }, [scaningProcessPer])

    const [websiteURLPeram, setWebsiteURLPeram] = useState<string>('');

    useEffect(() => {
        if (websiteURL !== null) {
            setWebsiteURLPeram(`https://ada.skynettechnologies.us/trial-subscription?website=${websiteURL.split('/')[0]}`)
        } else {
            setWebsiteURLPeram(`https://ada.skynettechnologies.us/trial-subscription`)
        }
    }, [websiteURL]);

    return (
        <>
            <div className='screenshot-main-wrap'>
                <div className='screenshot-main border mb-3'>
                    <div className='screenshot-loader'>
                        <img onError={addDefaultImgSrc} src={screenShot ? screenShot : screenMockupPlaceholder} style={{ filter: `blur(${blurValue}px)` }} alt="" />
                        {isLoading ? <span></span> : ''}
                    </div>
                </div>
                {isLoading ? (
                    <>
                        <Placeholder animation="glow">
                            <Placeholder style={{ maxWidth: '100%' }} />
                            <Placeholder style={{ maxWidth: '100%' }} />
                            <Placeholder style={{ maxWidth: '100%' }} />
                            <Placeholder style={{ maxWidth: '70%' }} />
                        </Placeholder>
                    </>
                ) : (
                    <>
                        {accessibilityScore > 85 ? (
                            <>
                                <p className='mb-0'>Although your web page has passed automated accessibility audits, a manual audit is required to meet all WCAG 2.1 & 2.2 standards as some criteria need manual checks.</p>
                            </>
                        ) : (
                            <>
                                {accessibilitySystemDetected !== '' && accessibilitySystemDetected !== null && accessibilitySystemDetected.toLowerCase() === 'all in one accessibility™' ? (
                                    <>
                                        {isWidget ? (
                                            <>
                                                <p>Although your web page is at low risk, a manual audit is required to meet all WCAG 2.1 & 2.2 standards as some criteria need manual checks.</p>
                                                {/* <Button className="download-btn" variant="primary" onClick={() => handleShowManualAuditFormPopup()} >
                                                    <span>Get a Manual Audit</span>
                                                </Button> */}
                                            </>
                                        ) : (
                                            <>
                                                <p>You are currently at high risk, Upgrade to the paid version to improve accessibility and compliance within 2 minutes. Start 10 days free trial!</p>
                                                {/* <a href={websiteURLPeram} className="btn btn-primary icon-btn">
                                                    <span>Start Now</span>
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 9.5 16">
                                                        <path d="M1.5,0L0,1.5L6.5,8L0,14.5L1.5,16l8-8L1.5,0z"></path>
                                                    </svg>
                                                </a> */}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <p>You are currently at high risk, Improve accessibility and compliance within 2 minutes. Start 10 days free trial!</p>
                                        {/* <a href={websiteURLPeram} className="btn btn-primary icon-btn">
                                            <span>Start Now</span>
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 9.5 16">
                                                <path d="M1.5,0L0,1.5L6.5,8L0,14.5L1.5,16l8-8L1.5,0z"></path>
                                            </svg>
                                        </a> */}
                                    </>
                                )}
                            </>
                        )}

                    </>
                )}

            </div>
        </>
    )
}

export default WebsiteScreen;