import { useEffect, useState } from "react";
import { violationDetails } from "../../PropTypes/ScanningScreenPropType";
import { Button } from "react-bootstrap";

interface propType {
    element: violationDetails[]
}

const ElementsListMain = (prop: propType) => {
    const { element } = prop;
    const [limit, setLimit] = useState(5);
    return (
        <>
            <div className={`${(element.length > 5 && limit === element.length) ? `scroll` : ``}`}>
                <ul>
                    {element && element.length > 0 && (
                        <>
                            {element.slice(0, limit).map((item) => {
                                return (
                                    <>
                                        <li key={item.id}>
                                            <code>
                                                <pre>
                                                    {`${item.context}`}
                                                </pre>
                                            </code>
                                        </li>
                                    </>
                                )
                            })}
                        </>
                    )}
                </ul>


                {element.length > 5 && (
                    <>
                        <div className="show-link-wrap">
                            {limit === element.length ? (
                                <>
                                    <Button variant="link" onClick={() => setLimit(5)}>Show Less</Button>
                                </>
                            ) : (
                                <>
                                    <Button variant="link" onClick={() => setLimit(element.length)}>Show All</Button>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>

        </>
    )
}

export default ElementsListMain;