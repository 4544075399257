import { useEffect, useState } from 'react'
import useCurrentPath from '../../Helpers/useCurrentPath';
import metaDataApi from '../../Api/MetadataApi';
import { MetaDataPropTypes } from '../../PropTypes/MetaDataProptypes';
import MetaDetails from '../MetaData';



const MetaDataProivder = () => {
    const [metaData, setMetaData] = useState<MetaDataPropTypes>();

    const path = useCurrentPath();

    useEffect(() => {
        if (path) {
            const metaDataFromRoute = metaDataApi(path);
            metaDataFromRoute && setMetaData(metaDataFromRoute);
        }
    }, [path])

    return <>
        {
            metaData && <MetaDetails metaData={metaData} />
        }
    </>
}

export default MetaDataProivder