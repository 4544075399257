import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { MetaDataPropTypes } from '../../PropTypes/MetaDataProptypes';



interface PropTypes {
    metaData: MetaDataPropTypes;
}


const MetaDetails = (props: PropTypes) => {

    const { metaData } = props;

    const { t } = useTranslation();
    
    return (
        <Helmet>
            <title>{t(metaData.metaTitle)}</title>
            <meta name="description" content={t(metaData.metaDescription).toString()} />
            <link rel="canonical" href={metaData.canonicalLinkURL} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={metaData.ogTitle} />
            <meta property="og:description" content={metaData.ogDescription} />
            <meta property="og:url" content={metaData.canonicalLinkURL} />
            <meta property="og:site_name" content="Aimeos Theme" />
            <meta property="og:image" content={metaData.ogImage} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={metaData.twitterTitle} />
            <meta name="twitter:description" content={metaData.twitterDescription} />
            <meta name="twitter:image" content={metaData.twitterImage} />
        </Helmet>
    );

}

export default MetaDetails;