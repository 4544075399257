import { Alert, Button, Form } from "react-bootstrap"
import { ScanningProcessService } from "../../Services/ScanningProcessService"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { requestDataProptype } from "../../PropTypes/ScanningScreenPropType";

import EnterIcon from '../../Assets/img/icn-arrow.svg';

interface PropType {
    setViolationsGroupList: Function,
    setErrorViolationsTotal: Function,
    setSuccessViolationsTotal: Function,
    setFixedViolationsTotal: Function,
    setAccessibilitySystemDetected: Function,
    setRequestDataObj: Function,
    setScreenError: Function,
    setCriticalIssues: Function,
    setPassedAudits: Function, 
    setRequiredManualAudits: Function, 
    setNotApplicable: Function,
    setApiRecall?: Function,
    apiRecall?: boolean
}

const ScanURLFormComponents = (prop: PropType) => {

    const { setApiRecall, apiRecall, setViolationsGroupList, setErrorViolationsTotal, setSuccessViolationsTotal, setFixedViolationsTotal, setAccessibilitySystemDetected, setRequestDataObj, setScreenError, setCriticalIssues, setPassedAudits, setRequiredManualAudits, setNotApplicable } = prop
    const { websiteURL, scaningProcess } = useSelector((store: StoreProptypes) => store);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const websiteURLParam = searchParams.get("website");

    const [userWebsiteURL, setUserWebsiteURL] = useState('');
    const [websiteURLValidation, setWebsiteURLValidation] = useState(false);
    const [websiteURLValidationMsg, setWebsiteURLValidationMsg] = useState('');

    useEffect(() => {
        if (websiteURL) {
            setUserWebsiteURL(websiteURL);
        }
    }, [websiteURL])

    const URLValidationEvent = (e: any) => {
        const inputValue = e.target.value;
        let httpRegex = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;

        if (inputValue === '') {
            setWebsiteURLValidation(true);
            setWebsiteURLValidationMsg("Please enter website URL.");
            setUserWebsiteURL(inputValue);
        } else {
            setWebsiteURLValidation(false);
            setWebsiteURLValidationMsg('')
            setUserWebsiteURL((inputValue.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')).trim('/'));
        }
    }

    const enterInputValue = (e:any) => {
        const inputValue = e.target.value;

        if(inputValue ==='' && websiteURL && websiteURL !== null) {
            setUserWebsiteURL(websiteURL);
            setWebsiteURLValidation(false);
            setWebsiteURLValidationMsg('')
        }
    }

    const handleError = () => {
        setWebsiteURLValidation(false);
        setWebsiteURLValidationMsg('')
    }

    const HandleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        let httpRegex = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;

        console.log("userWebsiteURL ==>", httpRegex.test(userWebsiteURL))

        if (userWebsiteURL === '') {
            setWebsiteURLValidation(true);
            setWebsiteURLValidationMsg("Please enter website URL.");
        } else if(!(httpRegex.test(userWebsiteURL))) {
            setWebsiteURLValidation(true);
            setWebsiteURLValidationMsg("Please enter valid website URL.");
            //setUserWebsiteURL('');
        } else {
            if (websiteURLParam) {
                navigate('/');
            }
            ScanningProcessService(userWebsiteURL, true, dispatch, setViolationsGroupList, setErrorViolationsTotal, setSuccessViolationsTotal, setFixedViolationsTotal, setAccessibilitySystemDetected, setRequestDataObj, setScreenError, setCriticalIssues, setPassedAudits, setRequiredManualAudits, setNotApplicable, setApiRecall, apiRecall ? apiRecall : false);
        }
    }

    return (
        <>
            <div className='scan-url-form mx-auto'>
                <Form onSubmit={(e) => HandleSubmit(e)}>
                    <div className='inline-form'>
                        <Form.Control 
                            className={`${websiteURLValidation ? `border-danger` : ``}`} 
                            placeholder="Enter website URL" 
                            value={userWebsiteURL} 
                            onChange={(e) => URLValidationEvent(e)}
                            onBlur={(e) => enterInputValue(e)}
                            disabled={scaningProcess} 
                        />
                        <Button type="submit" variant='primary' disabled={websiteURLValidation || scaningProcess}>
                            <span>Start Scan</span>
                            <img src={EnterIcon} alt="" />
                         </Button>
                    </div>
                </Form>
                {websiteURLValidation && (
                    <>
                        <div className="text-danger">{websiteURLValidationMsg}</div>
                    </>
                )}
            </div>
        </>
    )
}

export default ScanURLFormComponents;