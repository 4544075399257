import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { StoreProptypes } from '../../PropTypes/StoreProptypes';
import { SET_MESSAGE } from '../../Actions/Types';

const ToastMessage = () => {

    const { t } = useTranslation();


    const dispatch = useDispatch();

    const [showToast, setToast] = useState(false);

    const { toastMessage } = useSelector((state: StoreProptypes) => state);

    useEffect(() => {
        if (toastMessage) {
            setToast(true)
        }
    }, [toastMessage])

    const ClearToast = () => {
        setToast(false);
        dispatch({
            type: SET_MESSAGE,
            payload: "",
        });
    }

    return <ToastContainer className="p-3 custom-toast-container" position="bottom-start" containerPosition="fixed">
        <Toast show={showToast} delay={4000} autohide onClose={ClearToast}>
            <Toast.Header closeButton={true} className="text-white bg-dark custom-toast-header">
                <strong className={'me-auto'}>{t("Alert")}</strong>
            </Toast.Header>
            <Toast.Body className="bg-dark text-white">
                {toastMessage}
            </Toast.Body>
        </Toast>
    </ToastContainer>

}
export default ToastMessage