import WebsiteScreen from "../WebsiteScreen";
//import iconDownload from "../../Assets/img/icn-download.svg";
import iconDownload from "../../Assets/img/icn-pdf.svg";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useSelector } from "react-redux";
import { Button, Placeholder, ProgressBar, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";

interface PropType {
    accessibilitySystemDetected: string,
    accessibilityScore: number,
    handleShowPDFReportFormPopup: Function,
    loadingPDF: boolean,
    handleShowManualAuditFormPopup: Function,
    criticalIssues: number,
    passedAudits: number,
    requiredManualAudits: number,
    notApplicable: number,
    violationsGroupListFilter: Function
}

const WebsiteURLSection = (prop: PropType) => {

    const { accessibilitySystemDetected, accessibilityScore, handleShowPDFReportFormPopup, loadingPDF, handleShowManualAuditFormPopup, notApplicable, requiredManualAudits, passedAudits, criticalIssues, violationsGroupListFilter } = prop;

    const { isLoading, isWidget, websiteURL } = useSelector((store: StoreProptypes) => store);

    const [websiteURLPeram, setWebsiteURLPeram] = useState<string>('');

    useEffect(() => {
        if (websiteURL !== null) {
            setWebsiteURLPeram(`https://ada.skynettechnologies.us/trial-subscription?website=${websiteURL.split('/')[0]}`)
        } else {
            setWebsiteURLPeram(`https://ada.skynettechnologies.us/trial-subscription`)
        }
    }, [websiteURL]);

    return (
        <>
            <div className="website-screen-details-wrap">
                <div className="website-screen-details">
                    <WebsiteScreen handleShowManualAuditFormPopup={handleShowManualAuditFormPopup} accessibilityScore={accessibilityScore} accessibilitySystemDetected={accessibilitySystemDetected} />
                    <div className="scan-result">

                        {isLoading ? (
                            <>
                                <div className="system-detected">
                                    <Placeholder animation="glow">
                                        <Placeholder style={{ maxWidth: '100%' }} />
                                    </Placeholder>
                                </div>
                                <div className="scan-result-box">

                                    <div className="scan-result-box-icon">
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ height: '100%', borderRadius: '100%' }} />
                                        </Placeholder>
                                    </div>
                                    <div className="scan-result-box-text">
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ maxWidth: '25%' }} />
                                            <Placeholder style={{ maxWidth: '75%' }} />
                                        </Placeholder>
                                    </div>
                                </div>
                                <div className="scan-Progressbar">
                                    <div className="scan-Progressbar-box">
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ maxWidth: '100%' }} />
                                        </Placeholder>
                                    </div>
                                    <div className="scan-Progressbar-box">
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ maxWidth: '100%' }} />
                                        </Placeholder>
                                    </div>
                                    <div className="scan-Progressbar-box">
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ maxWidth: '100%' }} />
                                        </Placeholder>
                                    </div>
                                    <div className="scan-Progressbar-box">
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ maxWidth: '100%' }} />
                                        </Placeholder>
                                    </div>
                                </div>
                                <div className="scan-result-box-text w-100 ps-0">
                                    <h1 className="h3">Scanning your website...</h1>
                                    <p>Results will show up in a few moments</p>
                                </div>
                            </>
                        ) : (
                            <>
                                {(accessibilitySystemDetected !== '' && accessibilitySystemDetected !== null) ? (
                                    <>

                                        <div className="system-detected"><strong>{accessibilitySystemDetected} {(accessibilitySystemDetected.toLowerCase() === 'all in one accessibility™' && !isWidget) ? `Free` : ``}</strong> is detected on the website.</div>
                                    </>
                                ) : (
                                    <>
                                        {isWidget ? (
                                            <>
                                                <div className="system-detected"><strong>All in One Accessibility<sup>TM</sup></strong> is detected on the website.</div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="system-detected">No accessibility system detected.</div>
                                            </>
                                        )}
                                    </>
                                )}

                                <div className="scan-result-box">
                                    <div className="scan-result-box-icon">

                                        <div className={`scan-score pie animate no-round ${accessibilityScore > 85 ? `text-success success` : accessibilityScore > 49 ? `text-warning warning` : `text-danger danger`}`} style={{ "--p": `${accessibilityScore.toFixed(0)}` } as React.CSSProperties}>{accessibilityScore.toFixed(0)}</div>

                                        {accessibilityScore > 85 ? (
                                            <>
                                                <svg fill="none" viewBox="0 0 64 65">
                                                    <circle cx="32" cy="32.5" r="32" fill="#cee7cf" transform="rotate(180 32 32.5)"></circle>
                                                    {/* <path stroke="#1f5c21" stroke-linecap="round" stroke-linejoin="round" strokeWidth="4" d="m20.788 34.41 6.017 6.018 16.41-16.41"></path> */}
                                                </svg>


                                            </>
                                        ) : accessibilityScore > 49 ? (
                                            <>

                                                <svg fill="none" viewBox="0 0 64 64">
                                                    <circle cx="32" cy="32" r="32" fill="#ffebd2" transform="rotate(180 32 32)"></circle>
                                                    {/* <path fill="#fb8c00" d="m37.043 14.4-1.638 25.242h-4.967L28.8 14.4h8.243Zm-8.14 32.666c0-2.253 1.791-4.096 3.993-4.096a4.108 4.108 0 0 1 4.096 4.096c0 2.253-1.843 4.045-4.096 4.045-2.202 0-3.994-1.792-3.994-4.045Z"></path> */}
                                                </svg>
                                            </>
                                        ) : (
                                            <>
                                                <svg fill="none" viewBox="0 0 64 64">
                                                    <circle cx="32" cy="32" r="32" fill="#fae2e2" transform="rotate(180 32 32)"></circle>
                                                    {/* <path stroke="#9f0000" stroke-linecap="round" strokeWidth="4" d="m22.404 22.402 19.2 19.2m-19.2.001 19.2-19.2"></path> */}
                                                </svg>
                                            </>
                                        )}

                                    </div>
                                    <div className="scan-result-box-text">
                                        {accessibilityScore > 85 ? (
                                            <>
                                                <h1 className="h2">Compliant</h1>
                                                <p>Although your web page passed our automated scan, a manual audit is still necessary.</p>
                                            </>
                                        ) : accessibilityScore > 49 ? (
                                            <>
                                                <h1 className="h2">Semi-compliant</h1>
                                                <p>You’re almost there, but still not compliant. Become accessible, do the right thing, and mitigate legal risks.</p>
                                            </>
                                        ) : (
                                            <>
                                                <h1 className="h2">Non-compliant</h1>
                                                <p>Oh no - it’s seem website isn't inclusive or accessible to people with disabilities. Let's change that!</p>
                                            </>
                                        )}
                                    </div>

                                </div>

                                <div className="scan-Progressbar">
                                    <div className="scan-Progressbar-box" onClick={() => violationsGroupListFilter(1)}>
                                        <div className="scan-Progressbar-box-title">
                                            <span>Critical Issues</span>
                                            <span>{criticalIssues} items</span>
                                        </div>
                                        <ProgressBar
                                            variant="danger"
                                            now={(criticalIssues + passedAudits + requiredManualAudits + notApplicable) > 0 ? ((criticalIssues / (criticalIssues + passedAudits + requiredManualAudits + notApplicable)) * 100) : 0}
                                        />
                                    </div>
                                    <div className="scan-Progressbar-box" onClick={() => violationsGroupListFilter(2)}>
                                        <div className="scan-Progressbar-box-title">
                                            <span>Passed Audits</span>
                                            <span>{passedAudits} items</span>
                                        </div>
                                        <ProgressBar
                                            variant="success"
                                            now={(criticalIssues + passedAudits + requiredManualAudits + notApplicable) > 0 ? ((passedAudits / (criticalIssues + passedAudits + requiredManualAudits + notApplicable)) * 100) : 0}
                                        />
                                    </div>
                                    <div className="scan-Progressbar-box" onClick={() => violationsGroupListFilter(3)}>
                                        <div className="scan-Progressbar-box-title">
                                            <span>Required Manual Audits</span>
                                            <span>{requiredManualAudits} items</span>
                                        </div>
                                        <ProgressBar
                                            variant="secondary"
                                            now={(criticalIssues + passedAudits + requiredManualAudits + notApplicable) > 0 ? ((requiredManualAudits / (criticalIssues + passedAudits + requiredManualAudits + notApplicable)) * 100) : 0}
                                        />
                                    </div>
                                    <div className="scan-Progressbar-box" onClick={() => violationsGroupListFilter(4)}>
                                        <div className="scan-Progressbar-box-title">
                                            <span>Not Applicable</span>
                                            <span>{notApplicable} items</span>
                                        </div>
                                        <ProgressBar
                                            variant="warning"
                                            now={(criticalIssues + passedAudits + requiredManualAudits + notApplicable) > 0 ? ((notApplicable / (criticalIssues + passedAudits + requiredManualAudits + notApplicable)) * 100) : 0}
                                        />
                                    </div>
                                </div>

                            </>
                        )}
                    </div>

                </div>
                <div className="download-pdf-button mt-2 pt-0">
                    {isLoading ? (
                        <>
                            <div className="btn btn-light" style={{ minWidth: '250px' }}>
                                <Placeholder animation="glow">
                                    <Placeholder style={{ maxWidth: '100%' }} />
                                </Placeholder>
                            </div>
                            <div className="btn btn-light" style={{ minWidth: '250px' }}>
                                <Placeholder animation="glow">
                                    <Placeholder style={{ maxWidth: '100%' }} />
                                </Placeholder>
                            </div>


                        </>
                    ) : (
                        <>
                            {accessibilityScore > 85 ? (
                                <>
                                    <Button className="download-btn" variant="primary" onClick={() => handleShowManualAuditFormPopup()} >
                                        <span>Get a Manual Audit Quote</span>
                                    </Button>
                                </>
                            ) : (
                                <>
                                    {accessibilitySystemDetected !== '' && accessibilitySystemDetected !== null && accessibilitySystemDetected.toLowerCase() === 'all in one accessibility™' ? (
                                        <>
                                            {isWidget ? (
                                                <>
                                                    <Button className="download-btn" variant="primary" onClick={() => handleShowManualAuditFormPopup()} >
                                                        <span>Get a Manual Audit Quote</span>
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <a href={websiteURLPeram} className="btn btn-primary icon-btn">
                                                        <span>Start Now</span>
                                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 9.5 16">
                                                            <path d="M1.5,0L0,1.5L6.5,8L0,14.5L1.5,16l8-8L1.5,0z"></path>
                                                        </svg>
                                                    </a>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {isWidget ? (
                                                <>
                                                    <Button className="download-btn" variant="primary" onClick={() => handleShowManualAuditFormPopup()} >
                                                        <span>Get a Manual Audit Quote</span>
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <a href={websiteURLPeram} className="btn btn-primary icon-btn">
                                                        <span>Start Now</span>
                                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 9.5 16">
                                                            <path d="M1.5,0L0,1.5L6.5,8L0,14.5L1.5,16l8-8L1.5,0z"></path>
                                                        </svg>
                                                    </a>
                                                </>
                                            )}

                                        </>
                                    )}
                                </>
                            )}
                            <Button className="download-btn" variant="primary" onClick={() => handleShowPDFReportFormPopup()} disabled={loadingPDF}>
                                {loadingPDF ? (
                                    <Spinner animation="border" role="status" className="me-2">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                ) : (
                                    <>

                                    </>
                                )}
                                <img src={iconDownload} />
                                <span>Get Free Accessibility Report</span>
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default WebsiteURLSection;