import { Button, Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import siteLogo from '../../Assets/img/all-in-one-accessibility-logo.svg';
//import iconDownload from "../../Assets/img/icn-download.svg";
import iconDownload from "../../Assets/img/icn-pdf.svg";
import adaLogo from '../../Assets/img/ada-audit-icon.svg';
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useEffect, useState } from "react";
import ScanURLFormComponents from "../ScanURLForm";

interface PropType {
    setViolationsGroupList: Function,
    setErrorViolationsTotal: Function,
    setSuccessViolationsTotal: Function,
    setFixedViolationsTotal: Function,
    setAccessibilitySystemDetected: Function,
    accessibilitySystemDetected: string,
    setRequestDataObj: Function,
    setScreenError: Function,
    showCTCButton: boolean,
    showReportButton: boolean,
    handleShowPDFReportFormPopup: Function,
    setCriticalIssues: Function,
    setPassedAudits: Function,
    setRequiredManualAudits: Function,
    setNotApplicable: Function,
    accessibilityScore: number,
    handleShowManualAuditFormPopup: Function,
    setApiRecall?: Function,
    apiRecall?: boolean,

}

const PageHeaderMain = (prop: PropType) => {

    const { setApiRecall, apiRecall, setViolationsGroupList, setErrorViolationsTotal, setSuccessViolationsTotal, setFixedViolationsTotal, setAccessibilitySystemDetected, setRequestDataObj, setScreenError, showCTCButton, handleShowPDFReportFormPopup, showReportButton, setCriticalIssues, setPassedAudits, setRequiredManualAudits, setNotApplicable, accessibilityScore, handleShowManualAuditFormPopup, accessibilitySystemDetected } = prop;

    const { websiteURL, isWidget, isLoading } = useSelector((store: StoreProptypes) => store);

    const [websiteURLPeram, setWebsiteURLPeram] = useState<string>('');

    useEffect(() => {
        if (websiteURL !== null) {
            setWebsiteURLPeram(`https://ada.skynettechnologies.us/trial-subscription?website=${websiteURL.split('/')[0]}`)
        } else {
            setWebsiteURLPeram(`https://ada.skynettechnologies.us/trial-subscription`)
        }
    }, [websiteURL]);

    return (
        <>
            <Navbar>
                <Container>
                    <div className="navbar-inner">
                        <div className="header-logo">
                            <a href='https://www.skynettechnologies.com/accessibility-checker' className="navbar-brand">
                                <img src={siteLogo} alt="accessibility checker" />
                            </a>
                        </div>
                        <div className="header-right">

                            {websiteURL !== null ? (
                                <>
                                    {(showCTCButton || showReportButton) && !isLoading ? (
                                        <>
                                            <div className="header-btn block-button-wrap">
                                                {(showCTCButton || showReportButton) && (
                                                    <>
                                                        {accessibilityScore > 85 ? (
                                                            <>
                                                                <Button className="download-btn" variant="light" onClick={() => handleShowManualAuditFormPopup()} >
                                                                    <span>Get a Manual Audit Quote</span>
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {accessibilitySystemDetected !== '' && accessibilitySystemDetected !== null && accessibilitySystemDetected.toLowerCase() === 'all in one accessibility™' ? (
                                                                    <>
                                                                        {isWidget ? (
                                                                            <>
                                                                                <Button className="download-btn" variant="light" onClick={() => handleShowManualAuditFormPopup()} >
                                                                                    <span>Get a Manual Audit Quote</span>
                                                                                </Button>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <a href={websiteURLPeram} className="btn btn-light icon-btn">
                                                                                    <span>Start Now</span>
                                                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 9.5 16">
                                                                                        <path d="M1.5,0L0,1.5L6.5,8L0,14.5L1.5,16l8-8L1.5,0z"></path>
                                                                                    </svg>
                                                                                </a>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {isWidget ? (
                                                                            <>
                                                                                <Button className="download-btn" variant="light" onClick={() => handleShowManualAuditFormPopup()} >
                                                                                    <span>Get a Manual Audit Quote</span>
                                                                                </Button>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <a href={websiteURLPeram} className="btn btn-light icon-btn">
                                                                                    <span>Start Now</span>
                                                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 9.5 16">
                                                                                        <path d="M1.5,0L0,1.5L6.5,8L0,14.5L1.5,16l8-8L1.5,0z"></path>
                                                                                    </svg>
                                                                                </a>
                                                                            </>
                                                                        )}

                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                        <Button className="download-btn" variant="light" onClick={() => handleShowPDFReportFormPopup()}>
                                                            <img src={iconDownload} />
                                                            <span>Get Free Accessibility Report</span>
                                                        </Button>

                                                    </>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <ScanURLFormComponents
                                                setViolationsGroupList={setViolationsGroupList}
                                                setErrorViolationsTotal={setErrorViolationsTotal}
                                                setSuccessViolationsTotal={setSuccessViolationsTotal}
                                                setFixedViolationsTotal={setFixedViolationsTotal}
                                                setAccessibilitySystemDetected={setAccessibilitySystemDetected}
                                                setApiRecall={setApiRecall}
                                                apiRecall={apiRecall}
                                                setRequestDataObj={setRequestDataObj}
                                                setScreenError={setScreenError}
                                                setCriticalIssues={setCriticalIssues}
                                                setPassedAudits={setPassedAudits}
                                                setRequiredManualAudits={setRequiredManualAudits}
                                                setNotApplicable={setNotApplicable}
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className="header-btn">
                                        {/* <a href="https://www.skynettechnologies.com/request-free-quote" className="btn btn-light">Request A Quote</a> */}
                                        <a href={websiteURLPeram} className="btn btn-outline-light">Start Free Trial</a>
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                </Container>
            </Navbar>
        </>
    )
}

export default PageHeaderMain;