import { LOADING, WEBSITE_RESCANNING_PROCESS, WEBSITE_SCANNING_PROCESS, WEBSITE_SCANNING_PROCESS_PER, WEBSITE_SCREENSHOT, WEBSITE_URL, WEBSITE_WIDGET } from "../../Actions/Types";
import { getCurrentWindowAPI, quoteFormAPI, ScanningProcessAPI, ScannReportAPI } from "../../Api/ScanningProcessAPI";

const ScanningProcessService = (
    url: string,
    is_first: boolean,
    dispatch: Function,
    setViolationsGroupList: Function,
    setErrorViolationsTotal: Function,
    setSuccessViolationsTotal: Function,
    setFixedViolationsTotal: Function,
    setAccessibilitySystemDetected: Function,
    setRequestDataObj: Function,
    setScreenError: Function,
    setCriticalIssues: Function,
    setPassedAudits: Function, 
    setRequiredManualAudits: Function, 
    setNotApplicable: Function, 
    setApiRecall?: Function,
    apiRecall?: boolean,

) => {

    if (is_first) {
        console.log("Start Time ==>", Date());

        setRequestDataObj(null);

        dispatch({
            type: WEBSITE_SCREENSHOT,
            payload: null,
        });
        dispatch({
            type: WEBSITE_SCANNING_PROCESS,
            payload: true,
        });
        setAccessibilitySystemDetected('');
    }
    dispatch({
        type: LOADING,
        payload: true,
    });
    ScanningProcessAPI(url, is_first).then((response) => {

        //console.log("response.status ==>", response.status, response);

        if (response && response.status !== 1) {

            if (is_first) {
                dispatch({
                    type: WEBSITE_URL,
                    payload: url,
                });
                dispatch({
                    type: WEBSITE_SCANNING_PROCESS,
                    payload: true,
                });
                dispatch({
                    type: WEBSITE_RESCANNING_PROCESS,
                    payload: false,
                });
                dispatch({
                    type: WEBSITE_SCREENSHOT,
                    payload: response.page_image,
                });
                dispatch({
                    type: WEBSITE_WIDGET,
                    payload: false,
                });

                setViolationsGroupList([]);
                setErrorViolationsTotal(0);
                setSuccessViolationsTotal(0);
                setFixedViolationsTotal(0);
                setAccessibilitySystemDetected('');
                setScreenError(false);
                setCriticalIssues(0);
                setPassedAudits(0);
                setRequiredManualAudits(0);
                setNotApplicable(0);
            }

            dispatch({
                type: WEBSITE_SCANNING_PROCESS_PER,
                payload: response.completed_percentage,
            });

            if (response.completed_percentage === 100) {

                dispatch({
                    type: WEBSITE_SCANNING_PROCESS_PER,
                    payload: 100,
                });

                dispatch({
                    type: WEBSITE_WIDGET,
                    payload: response.widget_purchased ? true : false,
                });

                setTimeout(() => {
                    dispatch({
                        type: WEBSITE_SCANNING_PROCESS,
                        payload: false,
                    });

                    dispatch({
                        type: WEBSITE_RESCANNING_PROCESS,
                        payload: true,
                    });

                    dispatch({
                        type: LOADING,
                        payload: false,
                    });
                    console.log("End Time ==>", Date());
                }, 1000);

                setViolationsGroupList(response.group_data);
                setErrorViolationsTotal(response.total_fail);
                setSuccessViolationsTotal(response.total_success);
                setFixedViolationsTotal(response.total_fixed);
                setAccessibilitySystemDetected(response.accessibility_system_detected);
                setRequestDataObj(response.request_data);
                //console.log("Scan Data ==>", response.results_counts.Critical_Issues);
                setCriticalIssues(response.results_counts.Critical_Issues);
                setPassedAudits(response.results_counts.Passed_Audits);
                setRequiredManualAudits(response.results_counts.Required_Manual_Audits);
                setNotApplicable(response.results_counts.Not_Applicable);
            }

            if (response.completed_percentage !== 100) {
                setApiRecall && setApiRecall(true);
            }

        } else {
            setScreenError(true);
            setRequestDataObj(response.request_data);
            dispatch({
                type: WEBSITE_SCANNING_PROCESS,
                payload: false,
            });
            dispatch({
                type: WEBSITE_RESCANNING_PROCESS,
                payload: false,
            });
            dispatch({
                type: WEBSITE_URL,
                payload: null,
            });
            dispatch({
                type: WEBSITE_SCREENSHOT,
                payload: null,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        }

    });
};

const ScanReportService = (
    id: number,
    accessibilityScore: number,
    scannerUserFullName: string,
    scannerUserEmail: string,
    customizeReport: number,
    reportLogoURL: string,
    brandColor: string,
    setLoading: Function,
    setSuccessResponse: Function,
    setCustomizeReport: Function,
    setReportLogoURL: Function,
    setBrandColor: Function
) => {
    setLoading(true);
    ScannReportAPI(id, accessibilityScore, scannerUserFullName, scannerUserEmail, customizeReport, reportLogoURL, brandColor).then((response) => {
        if (response.Data) {
            setSuccessResponse(true);
            setLoading(false);
            setCustomizeReport(0);
            setReportLogoURL('');
            setBrandColor('');
            //window.open(response.data, '_blank');
        }
    })
};


interface htmlFileInputProptype {
    id: string,
    file: any
  }

const quoteFormService = (
    name: string,
    email: string,
    message: string,
    is_audit: number,
    is_email_audit_result: number,
    inquiry_type: string,
    website_urls: string,
    inquiry_files: htmlFileInputProptype[],
    setQuoteLoading: Function,
    setQuoteFormSuccess: Function
) => {
    setQuoteLoading(true);
    quoteFormAPI(name, email, message, is_audit, is_email_audit_result, inquiry_type, website_urls, inquiry_files).then((response) => {
        if (response.Data) {
            
            setQuoteLoading(false);
            setQuoteFormSuccess(true);
        }
    })
};


const getCurrentWindowService = (setMyIp: Function) => {
    getCurrentWindowAPI().then((response) => {
        if(response) {
            setMyIp(response.IPv4);
        }
        //console.info("2 response ==>", response)
    })
}


export { ScanningProcessService, ScanReportService, quoteFormService, getCurrentWindowService }