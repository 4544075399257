import { OverlayTrigger, Tooltip } from "react-bootstrap"

interface PropType {
    showList?: boolean,
    motor: boolean,
    vision: boolean,
    cognitive: boolean
}

const ViolationTagList = (prop: PropType) => {
    const { showList, motor, vision, cognitive } = prop;
    return (
        <>
            {showList ? (
                <>
                    <div className='violations-tag list'>
                        {motor && (
                            <div className='tag-box'>
                                <div className='tag-box-icon border'>
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12.6987" cy="7.51122" r="1.30712" fill="#000000"></circle>
                                        <path d="M19.6704 19.6691L17.0182 15.535H12.5605V10.2528L15.6538 12.6313" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M14.6805 17.1894C14.6805 17.9532 14.4539 18.6999 14.0296 19.3351C13.6052 19.9702 13.002 20.4652 12.2963 20.7576C11.5905 21.0499 10.814 21.1264 10.0648 20.9773C9.31559 20.8283 8.62741 20.4605 8.08727 19.9203C7.54713 19.3802 7.17929 18.692 7.03027 17.9428C6.88124 17.1936 6.95773 16.4171 7.25005 15.7114C7.54237 15.0056 8.0374 14.4024 8.67253 13.978C9.30767 13.5537 10.0544 13.3271 10.8183 13.3271" stroke="#000000" strokeWidth="1.5"></path>
                                    </svg>
                                </div>
                                <span>Motor Impaired</span>
                            </div>
                        )}

                        {vision && (
                            <div className='tag-box'>
                                <div className='tag-box-icon border'>
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14 18.6272C18.4869 18.6272 20.8585 14.3086 21.0508 14.0001C20.8585 13.6917 18.4869 9.37305 14 9.37305" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.11 2.53"></path>
                                        <path d="M14.2204 18.6272C9.59327 18.6272 7.14752 14.3086 6.94922 14.0001C7.14752 13.6917 9.59327 9.37305 14.2204 9.37305" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <circle cx="14" cy="13.7803" r="1.7627" stroke="#000000" strokeWidth="1.5"></circle>
                                    </svg>
                                </div>
                                <span>Vision Impaired</span>
                            </div>
                        )}

                        {cognitive && (
                            <div className='tag-box'>
                                <div className='tag-box-icon border'>
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="14.0004" cy="14.0458" r="5.11173" stroke="#000000" strokeWidth="1.5"></circle>
                                        <circle cx="13.9999" cy="14.0453" r="2.13567" stroke="#000000" strokeWidth="1.5"></circle>
                                        <path d="M14 6.72217V8.7976" stroke="#000000" strokeWidth="1.5" strokeLinecap="round"></path>
                                        <path d="M14 19.2021V21.2775" stroke="#000000" strokeWidth="1.5" strokeLinecap="round"></path>
                                        <path d="M21.2783 14L19.2029 14" stroke="#000000" strokeWidth="1.5" strokeLinecap="round"></path>
                                        <path d="M8.79883 14L6.7234 14" stroke="#000000" strokeWidth="1.5" strokeLinecap="round"></path>
                                    </svg>
                                </div>
                                <span>Cognitive Disability</span>
                            </div>
                        )}

                    </div>
                </>
            ) : (
                <>
                    <div className='violations-tag'>
                        {motor && (
                            <div className='tag-box'>
                                <OverlayTrigger
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="email-tooltip">Motor Impaired</Tooltip>}
                                >
                                    <div className='tag-box-icon border'>
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="12.6987" cy="7.51122" r="1.30712" fill="#000000"></circle>
                                            <path d="M19.6704 19.6691L17.0182 15.535H12.5605V10.2528L15.6538 12.6313" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M14.6805 17.1894C14.6805 17.9532 14.4539 18.6999 14.0296 19.3351C13.6052 19.9702 13.002 20.4652 12.2963 20.7576C11.5905 21.0499 10.814 21.1264 10.0648 20.9773C9.31559 20.8283 8.62741 20.4605 8.08727 19.9203C7.54713 19.3802 7.17929 18.692 7.03027 17.9428C6.88124 17.1936 6.95773 16.4171 7.25005 15.7114C7.54237 15.0056 8.0374 14.4024 8.67253 13.978C9.30767 13.5537 10.0544 13.3271 10.8183 13.3271" stroke="#000000" strokeWidth="1.5"></path>
                                        </svg>
                                    </div>
                                </OverlayTrigger>

                            </div>
                        )}

                        {vision && (
                            <div className='tag-box'>
                                <OverlayTrigger
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="email-tooltip">Vision Impaired</Tooltip>}
                                >
                                    <div className='tag-box-icon border'>
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14 18.6272C18.4869 18.6272 20.8585 14.3086 21.0508 14.0001C20.8585 13.6917 18.4869 9.37305 14 9.37305" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.11 2.53"></path>
                                            <path d="M14.2204 18.6272C9.59327 18.6272 7.14752 14.3086 6.94922 14.0001C7.14752 13.6917 9.59327 9.37305 14.2204 9.37305" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <circle cx="14" cy="13.7803" r="1.7627" stroke="#000000" strokeWidth="1.5"></circle>
                                        </svg>
                                    </div>
                                </OverlayTrigger>

                            </div>
                        )}

                        {cognitive && (
                            <div className='tag-box'>
                                <OverlayTrigger
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="email-tooltip">Cognitive Disability</Tooltip>}
                                >
                                    <div className='tag-box-icon border'>
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="14.0004" cy="14.0458" r="5.11173" stroke="#000000" strokeWidth="1.5"></circle>
                                            <circle cx="13.9999" cy="14.0453" r="2.13567" stroke="#000000" strokeWidth="1.5"></circle>
                                            <path d="M14 6.72217V8.7976" stroke="#000000" strokeWidth="1.5" strokeLinecap="round"></path>
                                            <path d="M14 19.2021V21.2775" stroke="#000000" strokeWidth="1.5" strokeLinecap="round"></path>
                                            <path d="M21.2783 14L19.2029 14" stroke="#000000" strokeWidth="1.5" strokeLinecap="round"></path>
                                            <path d="M8.79883 14L6.7234 14" stroke="#000000" strokeWidth="1.5" strokeLinecap="round"></path>
                                        </svg>
                                    </div>
                                </OverlayTrigger>
                            </div>
                        )}

                    </div>
                </>
            )}
        </>
    )
}

export default ViolationTagList;