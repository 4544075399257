import { Placeholder } from "react-bootstrap"

const ViolationsGroupListPlaceholder = () => {
    return (
        <div className='violations-group-list'>
            <div className='violations-group-box custom-box-shadow border violations-card'>
                <div className='violations-group-title'>
                    <h2 className='h4 mb-0'>Clickables</h2>
                    <div className={`group-score`}>
                        <Placeholder animation="glow">
                            <Placeholder style={{ minWidth: '100px' }} />
                        </Placeholder>
                    </div>
                </div>
                <div className='violations-list'>
                    {[...Array(4)].map((item, i) => {
                        return (
                            <>
                                <div className='violations-list-box placeholder-box' key={i}>
                                    <div className='violations-list-box-icon'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ height: '1.8rem', width: '1.8rem', borderRadius: '100%' }} />
                                        </Placeholder>
                                    </div>
                                    <div className='violations-list-box-title w-100 ms-3'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ maxWidth: `${i == 0 ? `85%` : i === 1 ? `65%` : i === 2 ? `60%` : `75%`}` }} />
                                        </Placeholder>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

            <div className='violations-group-box custom-box-shadow border violations-card'>
                <div className='violations-group-title'>
                    <h2 className='h4 mb-0'>Titles</h2>
                    <div className={`group-score`}>
                        <Placeholder animation="glow">
                            <Placeholder style={{ minWidth: '100px' }} />
                        </Placeholder>
                    </div>
                </div>
                <div className='violations-list'>
                    {[...Array(4)].map((item, i) => {
                        return (
                            <>
                                <div className='violations-list-box placeholder-box' key={i}>
                                    <div className='violations-list-box-icon'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ height: '1.8rem', width: '1.8rem', borderRadius: '100%' }} />
                                        </Placeholder>
                                    </div>
                                    <div className='violations-list-box-title w-100 ms-3'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ maxWidth: `${i == 0 ? `85%` : i === 1 ? `65%` : i === 2 ? `60%` : `75%`}` }} />
                                        </Placeholder>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

            <div className='violations-group-box custom-box-shadow border violations-card'>
                <div className='violations-group-title'>
                    <h2 className='h4 mb-0'>Orientation</h2>
                    <div className={`group-score`}>
                        <Placeholder animation="glow">
                            <Placeholder style={{ minWidth: '100px' }} />
                        </Placeholder>
                    </div>
                </div>
                <div className='violations-list'>
                    {[...Array(4)].map((item, i) => {
                        return (
                            <>
                                <div className='violations-list-box placeholder-box' key={i}>
                                    <div className='violations-list-box-icon'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ height: '1.8rem', width: '1.8rem', borderRadius: '100%' }} />
                                        </Placeholder>
                                    </div>
                                    <div className='violations-list-box-title w-100 ms-3'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ maxWidth: `${i == 0 ? `85%` : i === 1 ? `65%` : i === 2 ? `60%` : `75%`}` }} />
                                        </Placeholder>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

            <div className='violations-group-box custom-box-shadow border violations-card'>
                <div className='violations-group-title'>
                    <h2 className='h4 mb-0'>Graphics</h2>
                    <div className={`group-score`}>
                        <Placeholder animation="glow">
                            <Placeholder style={{ minWidth: '100px' }} />
                        </Placeholder>
                    </div>
                </div>
                <div className='violations-list'>
                    {[...Array(4)].map((item, i) => {
                        return (
                            <>
                                <div className='violations-list-box placeholder-box' key={i}>
                                    <div className='violations-list-box-icon'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ height: '1.8rem', width: '1.8rem', borderRadius: '100%' }} />
                                        </Placeholder>
                                    </div>
                                    <div className='violations-list-box-title w-100 ms-3'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ maxWidth: `${i == 0 ? `85%` : i === 1 ? `65%` : i === 2 ? `60%` : `75%`}` }} />
                                        </Placeholder>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

            <div className='violations-group-box custom-box-shadow border violations-card'>
                <div className='violations-group-title'>
                    <h2 className='h4 mb-0'>Forms</h2>
                    <div className={`group-score`}>
                        <Placeholder animation="glow">
                            <Placeholder style={{ minWidth: '100px' }} />
                        </Placeholder>
                    </div>
                </div>
                <div className='violations-list'>
                    {[...Array(4)].map((item, i) => {
                        return (
                            <>
                                <div className='violations-list-box placeholder-box' key={i}>
                                    <div className='violations-list-box-icon'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ height: '1.8rem', width: '1.8rem', borderRadius: '100%' }} />
                                        </Placeholder>
                                    </div>
                                    <div className='violations-list-box-title w-100 ms-3'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ maxWidth: `${i == 0 ? `85%` : i === 1 ? `65%` : i === 2 ? `60%` : `75%`}` }} />
                                        </Placeholder>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

            <div className='violations-group-box custom-box-shadow border violations-card'>
                <div className='violations-group-title'>
                    <h2 className='h4 mb-0'>Document</h2>
                    <div className={`group-score`}>
                        <Placeholder animation="glow">
                            <Placeholder style={{ minWidth: '100px' }} />
                        </Placeholder>
                    </div>
                </div>
                <div className='violations-list'>
                    {[...Array(4)].map((item, i) => {
                        return (
                            <>
                                <div className='violations-list-box placeholder-box' key={i}>
                                    <div className='violations-list-box-icon'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ height: '1.8rem', width: '1.8rem', borderRadius: '100%' }} />
                                        </Placeholder>
                                    </div>
                                    <div className='violations-list-box-title w-100 ms-3'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ maxWidth: `${i == 0 ? `85%` : i === 1 ? `65%` : i === 2 ? `60%` : `75%`}` }} />
                                        </Placeholder>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

            <div className='violations-group-box custom-box-shadow border violations-card'>
                <div className='violations-group-title'>
                    <h2 className='h4 mb-0'>Readability</h2>
                    <div className={`group-score`}>
                        <Placeholder animation="glow">
                            <Placeholder style={{ minWidth: '100px' }} />
                        </Placeholder>
                    </div>
                </div>
                <div className='violations-list'>
                    {[...Array(4)].map((item, i) => {
                        return (
                            <>
                                <div className='violations-list-box placeholder-box' key={i}>
                                    <div className='violations-list-box-icon'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ height: '1.8rem', width: '1.8rem', borderRadius: '100%' }} />
                                        </Placeholder>
                                    </div>
                                    <div className='violations-list-box-title w-100 ms-3'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ maxWidth: `${i == 0 ? `85%` : i === 1 ? `65%` : i === 2 ? `60%` : `75%`}` }} />
                                        </Placeholder>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

            <div className='violations-group-box custom-box-shadow border violations-card'>
                <div className='violations-group-title'>
                    <h2 className='h4 mb-0'>Tables</h2>
                    <div className={`group-score`}>
                        <Placeholder animation="glow">
                            <Placeholder style={{ minWidth: '100px' }} />
                        </Placeholder>
                    </div>
                </div>
                <div className='violations-list'>
                    {[...Array(4)].map((item, i) => {
                        return (
                            <>
                                <div className='violations-list-box placeholder-box' key={i}>
                                    <div className='violations-list-box-icon'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ height: '1.8rem', width: '1.8rem', borderRadius: '100%' }} />
                                        </Placeholder>
                                    </div>
                                    <div className='violations-list-box-title w-100 ms-3'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ maxWidth: `${i == 0 ? `85%` : i === 1 ? `65%` : i === 2 ? `60%` : `75%`}` }} />
                                        </Placeholder>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

            <div className='violations-group-box custom-box-shadow border violations-card'>
                <div className='violations-group-title'>
                    <h2 className='h4 mb-0'>General</h2>
                    <div className={`group-score`}>
                        <Placeholder animation="glow">
                            <Placeholder style={{ minWidth: '100px' }} />
                        </Placeholder>
                    </div>
                </div>
                <div className='violations-list'>
                    {[...Array(4)].map((item, i) => {
                        return (
                            <>
                                <div className='violations-list-box placeholder-box' key={i}>
                                    <div className='violations-list-box-icon'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ height: '1.8rem', width: '1.8rem', borderRadius: '100%' }} />
                                        </Placeholder>
                                    </div>
                                    <div className='violations-list-box-title w-100 ms-3'>
                                        <Placeholder animation="glow">
                                            <Placeholder style={{ maxWidth: `${i == 0 ? `85%` : i === 1 ? `65%` : i === 2 ? `60%` : `75%`}` }} />
                                        </Placeholder>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ViolationsGroupListPlaceholder;